import { ApolloProvider } from "@apollo/client"
import { CssBaseline, GlobalStyles } from "@mui/material"
import { ErrorBoundary } from "@sentry/react"
import Head from "next/head"
import { SnackbarProvider } from "notistack"
import type { FC, ReactNode } from "react"
import { useContext, Suspense } from "react"
import RootLoading from "app/loading"
import { globalStyles } from "interface/configs/globalStyles"
import { AppContext } from "interface/contexts/appContext"
import { AppThemeProvider } from "interface/providers/appThemeProvider"
import { client } from "interface/utils/client"
import RootError from "pages/error"

// https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override
const inputGlobalStyle = <GlobalStyles styles={globalStyles} />

type Props = {
  children: ReactNode
}

/**
 * https://beta.nextjs.org/docs/routing/pages-and-layouts#root-layout-required
 * @param props
 * @returns
 */
const RootLayout: FC<Props> = (props) => {
  const appContext = useContext(AppContext)

  return (
    <ApolloProvider client={client}>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
        {/** https://github.com/vercel/next.js/discussions/13387#discussioncomment-2387429 */}
        <style>{"nextjs-portal { display: none; }"}</style>
        {/** https://developer.mozilla.org/ja/docs/Web/CSS/overscroll-behavior */}
        <style>{"body { overscroll-behavior: none; }"}</style>
      </Head>
      <AppThemeProvider>
        <CssBaseline />
        {inputGlobalStyle}
        <SnackbarProvider>
          <Suspense fallback={<RootLoading />}>
            <ErrorBoundary fallback={(props) => <RootError {...props} />}>
              {appContext.isLoading ? <RootLoading /> : props.children}
            </ErrorBoundary>
          </Suspense>
        </SnackbarProvider>
      </AppThemeProvider>
    </ApolloProvider>
  )
}

export default RootLayout
