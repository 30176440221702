import { ThemeProvider } from "@mui/material"
import type { FC, ReactNode } from "react"
import { useState } from "react"
import { createAppTheme } from "interface/configs/appTheme"
import { ThemeConfigContext } from "interface/contexts/themeConfigContext"

type Props = {
  children: ReactNode
}

export const AppThemeProvider: FC<Props> = (props) => {
  const [paletteMode, setPaletteMode] = useState<"light" | "dark">("light")

  const value = {
    togglePaletteMode() {
      setPaletteMode((state) => {
        return state === "light" ? "dark" : "light"
      })
    },
  }

  const theme = createAppTheme({ paletteMode })

  return (
    <ThemeConfigContext.Provider value={value}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeConfigContext.Provider>
  )
}
