import type { PaperProps } from "@mui/material"
import { Paper } from "@mui/material"
import type { FC } from "react"
import React from "react"

const SSPaper: FC<PaperProps> = (props) => {
  return <Paper variant="outlined" {...props} />
}

export default SSPaper
