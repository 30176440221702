import "service/errors"
import type { AppProps } from "@blitzjs/next"
import { init } from "@sentry/react"
import * as Sentry from "@sentry/react"

import { Crisp } from "crisp-sdk-web"
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics"
import { getApps, initializeApp } from "firebase/app"
import i18next from "i18next"
import { useRouter } from "next/router"
import type { FC } from "react"
import * as React from "react"
import { useEffect } from "react"
import ReactGA from "react-ga4"
import { AppContextProvider } from "app/components/AppContextProvider"
import i18nJson from "i18n.json"
import { Config } from "interface/config"
import RootLayout from "pages/layout"

export const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout ?? ((page) => page)
  const router = useRouter()

  useEffect(() => {
    if (Config.isNotClient) return
    if (Config.isNotProduction) return
    const routeChangeComplete = (url: string) => {
      ReactGA.set({ page: url })
      try {
        ReactGA.send({ hitType: "pageview", page: url, title: document.title })
      } catch (error) {
        console.error(error)
      }
    }

    // 当初のページロードでのトラッキング
    routeChangeComplete(window.location.pathname)

    router.events.on("routeChangeComplete", routeChangeComplete)

    return () => {
      router.events.off("routeChangeComplete", routeChangeComplete)
    }
  }, [router])

  useEffect(() => {
    Crisp.configure(Config.crispWebsiteId)
  }, [])

  return (
    <AppContextProvider>
      <RootLayout>{getLayout(<Component {...pageProps} />)}</RootLayout>
    </AppContextProvider>
  )
}

/**
 * Sentry
 */
if (typeof window !== "undefined") {
  init({
    dsn: Config.sentryDSN,
    environment: Config.sentryEnvironment,
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    normalizeDepth: 5,
    release: Config.sentryRelease,
    integrations: [new Sentry.Replay()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

/**
 * Firebase
 */
if (Config.isClient && getApps().length === 0) {
  initializeApp(Config.firebaseOptions)
  if (Config.isNotProduction) {
    setAnalyticsCollectionEnabled(getAnalytics(), false)
  }
}

/**
 * Google Analytics
 */
if (Config.isClient) {
  console.log(
    "Config.googleAnalyticsMeasurementId",
    Config.googleAnalyticsMeasurementId,
  )
  ReactGA.initialize(Config.googleAnalyticsMeasurementId)
}

i18next.init({
  lng: Config.isInstanceSushiTrain ? "en" : "ja",
  fallbackLng: "en",
  resources: {
    en: {
      translation: Object.fromEntries(
        Object.keys(i18nJson).map((key) => {
          return [key, key]
        }),
      ),
    },
    ja: {
      translation: Object.fromEntries(
        Object.keys(i18nJson).map((key) => {
          return [key, i18nJson[key as keyof typeof i18nJson]]
        }),
      ),
    },
  },
})

export default MyApp
