import { Button, Link, Stack, Typography } from "@mui/material"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { useSnackbar } from "notistack"
import type { FC } from "react"
import { useState } from "react"
import { HomeAppBar } from "app/components/HomeAppBar"
import { HomeLoginForm } from "app/components/HomeLoginForm"
import SSPaper from "app/components/SSPaper"
import { useLoginWithTokenMutation } from "interface/__generated__/react"
import type { OnLoginWithEmail } from "interface/types/onLoginWithEmail"

type Props = {
  resetError(): void
}

export const HomeLogin: FC<Props> = (props) => {
  const [loginWithToken] = useLoginWithTokenMutation()

  const [isLoading, setLoading] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const onLogin: OnLoginWithEmail = async (form) => {
    try {
      setLoading(true)
      const result = await signInWithEmailAndPassword(
        getAuth(),
        form.email,
        form.password,
      )
      const token = await result.user.getIdToken()
      await loginWithToken({
        variables: {
          input: { token, password: form.password },
        },
      })
      props.resetError()
    } catch (error) {
      setLoading(false)

      if (error instanceof Error) {
        console.log("error.message: ", error.message)

        if (error.message.includes("auth/user-not-found")) {
          enqueueSnackbar("User not found", { variant: "error" })
        } else if (error.message.includes("auth/wrong-password")) {
          enqueueSnackbar("Wrong password", { variant: "error" })
        } else if (error.message.includes("auth/too-many-requests")) {
          enqueueSnackbar(
            "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
            { variant: "error" },
          )
        } else {
          enqueueSnackbar("Failed to login", { variant: "error" })
        }
      } else {
        enqueueSnackbar("Failed to login", { variant: "error" })
      }
    }
  }

  return (
    <Stack>
      <HomeAppBar>
        <Link href="/register">
          <Button>{"Sign up"}</Button>
        </Link>
      </HomeAppBar>
      <Stack sx={{ px: 4, pt: 8 }} direction={"row"} justifyContent={"center"}>
        <Stack spacing={2} sx={{ width: "100%", maxWidth: 360 }}>
          <SSPaper sx={{ borderRadius: 3 }}>
            <Stack
              spacing={4}
              sx={{
                px: 4,
                pt: 4,
                pb: 5,
                width: "100%",
              }}
            >
              <Stack direction={"row"} justifyContent={"center"}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  {"Sign in"}
                </Typography>
              </Stack>
              <HomeLoginForm isLoading={isLoading} onLogin={onLogin} />
            </Stack>
          </SSPaper>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {"© 2023 ShiftSmart"}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
