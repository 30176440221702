import { AppBar, Stack, Toolbar } from "@mui/material"
import { useRouter } from "next/router"
import type { FC, ReactNode } from "react"
import { HomeButton } from "app/components/HomeButton"

type Props = {
  children?: ReactNode
}

export const HomeAppBar: FC<Props> = (props) => {
  const router = useRouter()

  return (
    <AppBar position="sticky" color="default" sx={{ backgroundColor: "#fff" }}>
      <Toolbar sx={{ px: "1rem !important" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          spacing={2}
          sx={{ width: "100%" }}
        >
          <HomeButton
            onClick={() => {
              router.push("/")
            }}
          />
          <Stack direction={"row"} spacing={2}>
            {props.children}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
