import { Button } from "@mui/material"
import type { FC } from "react"

type Props = {
  onClick?(): void
}

export const HomeButton: FC<Props> = (props) => {
  return (
    <Button onClick={props.onClick}>
      <img
        src="/Asset 4@4x-8.png"
        alt="Logo"
        style={{ cursor: "pointer", height: "25px" }}
      />
    </Button>
  )
}
