import type { PaletteMode } from "@mui/material"
import { createTheme } from "@mui/material"
import { orange, pink } from "@mui/material/colors"

type Props = {
  paletteMode: PaletteMode
}

export const createAppThemeDefault = (props: Props) => {
  return createTheme({
    palette: {
      mode: "light",
      primary: orange,
      secondary: pink,
      background: { default: "#FAFBFB" },
    },
    shape: { borderRadius: 8 },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            textTransform: "none",
          },
          containedSizeSmall: {
            // デフォルトだとテキストが中心にならない
            lineHeight: 1,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "2.2rem",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "white",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
          },
        },
      },
    },
  })
}
