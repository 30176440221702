export class Config {
  static get isClient() {
    return typeof window !== "undefined"
  }

  static get isNotClient() {
    return typeof window === "undefined"
  }

  /**
   * ライブ環境かどうか
   */
  static get isProduction() {
    return process.env.NODE_ENV === "production"
  }

  /**
   * テスト環境かどうか
   */
  static get isNotProduction() {
    return process.env.NODE_ENV !== "production"
  }

  /**
   * インスタンス
   */
  static get instance() {
    return process.env.NEXT_PUBLIC_INSTANCE!
  }

  static get isInstanceSushiTrain() {
    return process.env.NEXT_PUBLIC_INSTANCE === "SUSHI_TRAIN"
  }

  /**
   * サイト名
   */
  static get siteName() {
    return "ShiftSmart"
  }

  /**
   * SentryのDSN
   */
  static get sentryDSN() {
    return process.env.NEXT_PUBLIC_SENTRY_DSN!
  }

  static get sentryEnvironment() {
    return process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT!
  }

  static get sentryRelease() {
    return process.env.NEXT_PUBLIC_SENTRY_RELEASE!
  }

  /**
   * crisp
   */
  static get crispWebsiteId() {
    return process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID!
  }

  /**
   * Google AnalyticsのトラッキングID
   */
  static get googleAnalyticsMeasurementId() {
    return process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID!
  }

  /**
   * Firebaseの設定
   */
  static get firebaseOptions() {
    return {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    }
  }

  /**
   * GraphQLのエンドポイント
   */
  static get graphqlEndpoint() {
    return process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT!
  }
}
