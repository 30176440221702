import type { PaletteMode } from "@mui/material"
import { Config } from "interface/config"
import { createAppThemeDark } from "interface/configs/appThemeDark"
import { createAppThemeDefault } from "interface/configs/appThemeDefault"

type Props = {
  paletteMode: PaletteMode
}

export const createAppTheme = (props: Props) => {
  if (Config.isInstanceSushiTrain) {
    return createAppThemeDefault(props)
  }

  return createAppThemeDark(props)
}
