import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AddIntegrationLineInput = {
  login: Scalars['String']['input'];
};

export type AiGeneratedShiftScheduleNode = Node & {
  __typename?: 'AiGeneratedShiftScheduleNode';
  dateText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  memberId: Scalars['ID']['output'];
  shiftRoleId: Scalars['ID']['output'];
  timeSlotId: Scalars['ID']['output'];
};

export type ApproveInviteLinkRequestInput = {
  businessLogin: Scalars['String']['input'];
  inviteLinkRequestId: Scalars['ID']['input'];
};

export type ApproveInviteRequestInput = {
  inviteRequestId: Scalars['ID']['input'];
};

export type AssignableShiftNode = Node & {
  __typename?: 'AssignableShiftNode';
  businessId: Scalars['ID']['output'];
  dateText: Scalars['String']['output'];
  day: Scalars['Int']['output'];
  defaultShiftId: Maybe<Scalars['String']['output']>;
  endTime: Scalars['Int']['output'];
  endTimeHour: Scalars['Int']['output'];
  endTimeMinute: Scalars['Int']['output'];
  endTimeText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAssignable: Scalars['Boolean']['output'];
  isAssigned: Scalars['Boolean']['output'];
  isDefaultEndTime: Scalars['Boolean']['output'];
  isDefaultShift: Scalars['Boolean']['output'];
  isDefaultStartTime: Scalars['Boolean']['output'];
  isDuplicated: Scalars['Boolean']['output'];
  isRequested: Scalars['Boolean']['output'];
  memberId: Scalars['ID']['output'];
  month: Scalars['Int']['output'];
  note: Maybe<Scalars['String']['output']>;
  shiftId: Maybe<Scalars['String']['output']>;
  shiftRequestId: Maybe<Scalars['String']['output']>;
  shiftRoleId: Scalars['ID']['output'];
  startTime: Scalars['Int']['output'];
  startTimeHour: Scalars['Int']['output'];
  startTimeMinute: Scalars['Int']['output'];
  startTimeText: Scalars['String']['output'];
  timeSlotId: Scalars['ID']['output'];
  year: Scalars['Int']['output'];
};

export type AttendanceCsvTextNode = Node & {
  __typename?: 'AttendanceCsvTextNode';
  extractText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  shiftRoleId: Scalars['ID']['output'];
  shiftRoleName: Scalars['String']['output'];
  since: Scalars['String']['output'];
  text: Scalars['String']['output'];
  until: Scalars['String']['output'];
};

export type AttendanceEdge = Edge & {
  __typename?: 'AttendanceEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<AttendanceNode>;
};

export type AttendanceNode = Node & {
  __typename?: 'AttendanceNode';
  breakingTime: Scalars['Int']['output'];
  breakingTimeHour: Scalars['Int']['output'];
  breakingTimeMinute: Scalars['Int']['output'];
  breakingTimeText: Scalars['String']['output'];
  dateText: Scalars['String']['output'];
  day: Scalars['Int']['output'];
  hasError: Scalars['Boolean']['output'];
  hasInternalError: Scalars['Boolean']['output'];
  hasLackingTimeRecordError: Scalars['Boolean']['output'];
  hasTimeRecordOrderError: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  memberId: Scalars['ID']['output'];
  month: Scalars['Int']['output'];
  uiDateText: Scalars['String']['output'];
  workingTime: Scalars['Int']['output'];
  workingTimeHour: Scalars['Int']['output'];
  workingTimeMinute: Scalars['Int']['output'];
  workingTimeText: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type AttendanceSummaryNode = Node & {
  __typename?: 'AttendanceSummaryNode';
  attendances: Array<AttendanceNode>;
  id: Scalars['ID']['output'];
  totalWorkingTime: Scalars['Int']['output'];
  totalWorkingTimePerDay: Array<Maybe<Scalars['Int']['output']>>;
  totalWorkingTimePerDayText: Array<Maybe<Scalars['String']['output']>>;
  totalWorkingTimePerMember: Array<Maybe<Scalars['Int']['output']>>;
  totalWorkingTimePerMemberText: Array<Maybe<Scalars['String']['output']>>;
  totalWorkingTimeText: Scalars['String']['output'];
};

export type AttendancesConnection = Connection & {
  __typename?: 'AttendancesConnection';
  edges: Maybe<Array<AttendanceEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BusinessEdge = Edge & {
  __typename?: 'BusinessEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<BusinessNode>;
};

export type BusinessNode = Node & {
  __typename?: 'BusinessNode';
  aiGeneratedShiftSchedule: Array<Maybe<AiGeneratedShiftScheduleNode>>;
  assignableShifts: Array<AssignableShiftNode>;
  attendanceCsvTexts: Array<AttendanceCsvTextNode>;
  attendanceSummary: AttendanceSummaryNode;
  attendances: AttendanceSummaryNode;
  /** 会社の名前 */
  companyName: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Int']['output'];
  deadlineDayOfWeek: DayOfWeek;
  deadlineDayOfWeekIndex: Scalars['Int']['output'];
  deadlineHour: Scalars['Int']['output'];
  deadlineMinute: Scalars['Int']['output'];
  defaultShifts: Array<DefaultShiftNode>;
  defaultShiftsConnection: Maybe<DefaultShiftsConnection>;
  /** 説明 */
  description: Maybe<Scalars['String']['output']>;
  destEmails: Maybe<Array<DestEmailNode>>;
  id: Scalars['ID']['output'];
  integrations: Array<IntegrationNode>;
  integrationsConnection: Maybe<IntegrationsConnection>;
  inviteLink: Maybe<InviteLinkNode>;
  inviteLinkRequests: Array<InviteLinkRequestNode>;
  isDeleted: Scalars['Boolean']['output'];
  /** URLで使用されるユニークなID */
  login: Scalars['String']['output'];
  /** メンバー */
  members: Array<MemberNode>;
  membersConnection: Maybe<MembersConnection>;
  /** 名前 */
  name: Scalars['String']['output'];
  nextWeekAssignableShifts: Maybe<Array<NextWeekAssignableShiftNode>>;
  scheduleRule: Scalars['String']['output'];
  shiftRequests: Array<ShiftRequestNode>;
  shiftRequestsConnection: Maybe<ShiftRequestsConnection>;
  shiftRevisions: Array<ShiftRevisionNode>;
  shiftRevisionsConnection: Maybe<ShiftRevisionsConnection>;
  shiftRoles: Array<ShiftRoleNode>;
  shiftRolesConnection: Maybe<ShiftRolesConnection>;
  shiftSchedule: Maybe<ShiftScheduleNode>;
  shiftScheduleRequests: Array<ShiftScheduleRequestNode>;
  shiftScheduleRequestsConnection: Maybe<ShiftScheduleRequestsConnection>;
  shiftSchedules: Array<ShiftScheduleNode>;
  shiftSchedulesConnection: Maybe<ShiftSchedulesConnection>;
  shifts: Array<ShiftNode>;
  shiftsConnection: Maybe<ShiftsConnection>;
  skills: Array<SkillNode>;
  skillsConnection: Maybe<SkillsConnection>;
  subscription: SubscriptionNode;
  timeRecordRevisions: Array<TimeRecordRevisionNode>;
  timeRecordRevisionsConnection: Maybe<TimeRecordRevisionsConnection>;
  timeRecords: Array<TimeRecordNode>;
  timeRecordsConnection: Maybe<TimeRecordsConnection>;
  timeSlots: Array<TimeSlotNode>;
  timeSlotsConnection: Maybe<TimeSlotsConnection>;
  /** タイムゾーン */
  timezone: Scalars['String']['output'];
  truncatedRange: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};


export type BusinessNodeAiGeneratedShiftScheduleArgs = {
  scheduleRule: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type BusinessNodeAssignableShiftsArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type BusinessNodeAttendanceCsvTextsArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type BusinessNodeAttendanceSummaryArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type BusinessNodeAttendancesArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type BusinessNodeDefaultShiftsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type BusinessNodeDefaultShiftsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeIntegrationsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type BusinessNodeIntegrationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeMembersArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type BusinessNodeMembersConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeNextWeekAssignableShiftsArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type BusinessNodeShiftRequestsArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type BusinessNodeShiftRequestsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeShiftRevisionsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type BusinessNodeShiftRevisionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeShiftRolesArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  where: InputMaybe<BusinessShiftRolesWhereInput>;
};


export type BusinessNodeShiftRolesConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeShiftScheduleArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type BusinessNodeShiftScheduleRequestsArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type BusinessNodeShiftScheduleRequestsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeShiftSchedulesArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type BusinessNodeShiftSchedulesConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeShiftsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type BusinessNodeShiftsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeSkillsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type BusinessNodeSkillsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeTimeRecordRevisionsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type BusinessNodeTimeRecordRevisionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeTimeRecordsArgs = {
  since: InputMaybe<Scalars['String']['input']>;
  until: InputMaybe<Scalars['String']['input']>;
};


export type BusinessNodeTimeRecordsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessNodeTimeSlotsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type BusinessNodeTimeSlotsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};

export type BusinessShiftRolesWhereInput = {
  isDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type BusinesssConnection = Connection & {
  __typename?: 'BusinesssConnection';
  edges: Maybe<Array<BusinessEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ClockInInput = {
  businessLogin: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  offset: Scalars['Int']['input'];
  type: Scalars['String']['input'];
};

export const ColorMode = {
  Dark: 'DARK',
  Light: 'LIGHT',
  System: 'SYSTEM'
} as const;

export type ColorMode = typeof ColorMode[keyof typeof ColorMode];
export type Connection = {
  edges: Maybe<Array<Edge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CreateBusinessInput = {
  login: Scalars['String']['input'];
  name: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export type CreateInviteLinkInput = {
  businessLogin: Scalars['String']['input'];
};

export type CreateInviteLinkRequestInput = {
  name: Scalars['String']['input'];
};

export type CreateMemberInput = {
  businessLogin: Scalars['String']['input'];
  businessRole: Scalars['String']['input'];
  email: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  note: Scalars['String']['input'];
  password: Scalars['String']['input'];
  shiftRoleIds: Array<Scalars['String']['input']>;
  userId: InputMaybe<Scalars['String']['input']>;
};

export type CreateShiftRoleInput = {
  businessLogin: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateShiftScheduleInput = {
  businessLogin: Scalars['String']['input'];
  note: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type CreateShiftScheduleRequestInput = {
  businessLogin: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};

export type CreateTimeRecordInput = {
  businessLogin: Scalars['String']['input'];
  dateTextAs: Scalars['String']['input'];
  day: Scalars['Int']['input'];
  hour: Scalars['Int']['input'];
  memberId: Scalars['ID']['input'];
  minute: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  type: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type CreateTimeSlotInput = {
  businessLogin: Scalars['String']['input'];
  endTimeHour: Scalars['Int']['input'];
  endTimeMinute: Scalars['Int']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  startTimeHour: Scalars['Int']['input'];
  startTimeMinute: Scalars['Int']['input'];
};

export type CreateUserWithEmailInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CreateUserWithGoogleInput = {
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export const DayOfWeek = {
  Friday: 'FRIDAY',
  Monday: 'MONDAY',
  Saturday: 'SATURDAY',
  Sunday: 'SUNDAY',
  Thursday: 'THURSDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY'
} as const;

export type DayOfWeek = typeof DayOfWeek[keyof typeof DayOfWeek];
export type DefaultShiftEdge = Edge & {
  __typename?: 'DefaultShiftEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<DefaultShiftNode>;
};

export type DefaultShiftNode = Node & {
  __typename?: 'DefaultShiftNode';
  business: BusinessNode;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  dayOfWeek: DayOfWeek;
  dayOfWeekIndex: Scalars['Int']['output'];
  endTime: Scalars['Int']['output'];
  endTimeHour: Scalars['Int']['output'];
  endTimeMinute: Scalars['Int']['output'];
  endTimeText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  member: MemberNode;
  memberId: Scalars['ID']['output'];
  startTime: Scalars['Int']['output'];
  startTimeHour: Scalars['Int']['output'];
  startTimeMinute: Scalars['Int']['output'];
  startTimeText: Scalars['String']['output'];
  timeSlot: TimeSlotNode;
  timeSlotId: Scalars['ID']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type DefaultShiftsConnection = Connection & {
  __typename?: 'DefaultShiftsConnection';
  edges: Maybe<Array<DefaultShiftEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DeleteDefaultShiftInput = {
  businessLogin: Scalars['String']['input'];
  dayOfWeek: Scalars['Int']['input'];
  memberId: Scalars['ID']['input'];
  timeSlotId: Scalars['ID']['input'];
};

export type DeleteIntegrationInput = {
  businessId: Scalars['ID']['input'];
  integrationId: Scalars['ID']['input'];
};

export type DeleteMemberInput = {
  businessLogin: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
};

export type DeleteShiftInput = {
  businessLogin: Scalars['String']['input'];
  shiftId: Scalars['ID']['input'];
};

export type DeleteShiftRequestInput = {
  businessLogin: Scalars['String']['input'];
  shiftRequestId: Scalars['ID']['input'];
};

export type DeleteShiftRoleInput = {
  businessLogin: Scalars['String']['input'];
  shiftRoleId: Scalars['ID']['input'];
};

export type DeleteTimeRecordInput = {
  businessLogin: Scalars['String']['input'];
  timeRecordId: Scalars['ID']['input'];
};

export type DeleteTimeSlotInput = {
  businessLogin: Scalars['String']['input'];
  timeSlotId: Scalars['ID']['input'];
};

export type DestEmail = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type DestEmailNode = Node & {
  __typename?: 'DestEmailNode';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node: Maybe<Node>;
};

export type IntegrationEdge = Edge & {
  __typename?: 'IntegrationEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<IntegrationNode>;
};

export type IntegrationNode = Node & {
  __typename?: 'IntegrationNode';
  applicationId: Scalars['ID']['output'];
  business: BusinessNode;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

export type IntegrationsConnection = Connection & {
  __typename?: 'IntegrationsConnection';
  edges: Maybe<Array<IntegrationEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IntegrationsWhereInput = {
  businessLogin: InputMaybe<Scalars['String']['input']>;
};

export type InviteLinkNode = Node & {
  __typename?: 'InviteLinkNode';
  business: BusinessNode;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  link: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type InviteLinkRequestNode = Node & {
  __typename?: 'InviteLinkRequestNode';
  business: BusinessNode;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  inviteLink: InviteLinkNode;
  inviteLinkRequestState: InviteLinkRequestState;
  name: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
};

export const InviteLinkRequestState = {
  Approved: 'APPROVED',
  Declined: 'DECLINED',
  Requested: 'REQUESTED'
} as const;

export type InviteLinkRequestState = typeof InviteLinkRequestState[keyof typeof InviteLinkRequestState];
export type InviteRequestNode = Node & {
  __typename?: 'InviteRequestNode';
  business: BusinessNode;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inviteRequestState: InviteRequestState;
  member: MemberNode;
  memberId: Scalars['ID']['output'];
  updatedAt: Scalars['Int']['output'];
  userExists: Scalars['Boolean']['output'];
};

export const InviteRequestState = {
  Approved: 'APPROVED',
  Declined: 'DECLINED',
  Requested: 'REQUESTED'
} as const;

export type InviteRequestState = typeof InviteRequestState[keyof typeof InviteRequestState];
export type JoinByInviteLinkInput = {
  inviteLinkCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type JoinByInviteLinkWithCreateUserInput = {
  email: Scalars['String']['input'];
  inviteLinkCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: InputMaybe<Scalars['String']['input']>;
};

export type JoinByInviteRequestWithCreateUserInput = {
  email: Scalars['String']['input'];
  inviteRequestId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginWithTokenInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MemberEdge = Edge & {
  __typename?: 'MemberEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<MemberNode>;
};

export type MemberNode = Node & {
  __typename?: 'MemberNode';
  business: Maybe<BusinessNode>;
  checkinCode: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  defaultShifts: Array<DefaultShiftNode>;
  defaultShiftsConnection: Maybe<DefaultShiftsConnection>;
  id: Scalars['ID']['output'];
  inviteRequest: Maybe<InviteRequestNode>;
  isArchived: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  note: Scalars['String']['output'];
  role: MemberRole;
  shiftRequests: Array<ShiftRequestNode>;
  shiftRevisions: Array<ShiftRevisionNode>;
  shiftRevisionsConnection: Maybe<ShiftRevisionsConnection>;
  shiftRoles: Array<ShiftRoleNode>;
  shiftRolesConnection: Maybe<ShiftRolesConnection>;
  shiftScheduleRequest: ShiftScheduleRequestNode;
  shiftScheduleRequests: Array<ShiftScheduleRequestNode>;
  shiftScheduleRequestsConnection: Maybe<ShiftScheduleRequestsConnection>;
  shifts: Array<ShiftNode>;
  shiftsConnection: Maybe<ShiftsConnection>;
  skills: Array<SkillNode>;
  skillsConnection: Maybe<SkillsConnection>;
  sort: Scalars['Int']['output'];
  timeRecordRevisions: Array<TimeRecordRevisionNode>;
  timeRecordRevisionsConnection: Maybe<TimeRecordRevisionsConnection>;
  timeRecords: Array<TimeRecordNode>;
  timeRecordsConnection: Maybe<TimeRecordsConnection>;
  updatedAt: Scalars['Int']['output'];
  user: Maybe<UserNode>;
};


export type MemberNodeDefaultShiftsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type MemberNodeDefaultShiftsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type MemberNodeShiftRevisionsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type MemberNodeShiftRevisionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type MemberNodeShiftRolesArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type MemberNodeShiftRolesConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type MemberNodeShiftScheduleRequestArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type MemberNodeShiftScheduleRequestsArgs = {
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type MemberNodeShiftScheduleRequestsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type MemberNodeShiftsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type MemberNodeShiftsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type MemberNodeSkillsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type MemberNodeSkillsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type MemberNodeTimeRecordRevisionsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type MemberNodeTimeRecordRevisionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type MemberNodeTimeRecordsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};

export const MemberRole = {
  Admin: 'ADMIN',
  Employee: 'EMPLOYEE',
  Manager: 'MANAGER',
  Owner: 'OWNER'
} as const;

export type MemberRole = typeof MemberRole[keyof typeof MemberRole];
export type MembersConnection = Connection & {
  __typename?: 'MembersConnection';
  edges: Maybe<Array<MemberEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** インテグレーションを追加する */
  addIntegrationLine: BusinessNode;
  /** 招待リンクリクエストの承認を行う */
  approveInviteLinkRequest: Maybe<InviteLinkRequestNode>;
  /** 招待のリクエストの承認を行う */
  approveInviteRequest: Maybe<InviteRequestNode>;
  /** 打刻する */
  clockIn: Maybe<Scalars['Boolean']['output']>;
  /** プロジェクトを作成する */
  createBusiness: BusinessNode;
  /** 招待リンクを生成する */
  createInviteLink: InviteLinkNode;
  /** 招待リンクのリクエストを作成する */
  createInviteLinkRequest: InviteLinkRequestNode;
  /** メンバーを作成する */
  createMember: MemberNode;
  /** シフトロールを作成する */
  createShiftRole: BusinessNode;
  /** シフトスケジュールを作成する */
  createShiftSchedule: ShiftScheduleNode;
  /** シフトスケジュールのリクエストを作成する */
  createShiftScheduleRequest: ShiftScheduleRequestNode;
  /** 打刻を作成する */
  createTimeRecord: TimeRecordNode;
  /** タイムスロットを作成する */
  createTimeSlot: TimeSlotNode;
  /** メールアドレスからユーザを作成する */
  createUserWithEmail: UserNode;
  /** Googleのログイントークンからユーザを作成する */
  createUserWithGoogle: UserNode;
  /** デフォルトシフトを削除する */
  deleteDefaultShift: Maybe<DefaultShiftNode>;
  /** インテグレーションを削除する */
  deleteIntegration: Maybe<BusinessNode>;
  /** メンバーを削除する */
  deleteMember: MemberNode;
  /** シフトを削除する */
  deleteShift: ShiftNode;
  /** シフトのリクエストを削除する */
  deleteShiftRequest: Maybe<ShiftRequestNode>;
  /** シフトロールを削除する Businessのキャッシュを更新させる */
  deleteShiftRole: BusinessNode;
  /** 打刻を削除する */
  deleteTimeRecord: TimeRecordNode;
  /** タイムスロットを削除する */
  deleteTimeSlot: TimeSlotNode;
  /**
   * 招待から参加する
   * ユーザを生成し、参加リクエストを送る
   */
  joinByInviteLink: UserNode;
  /**
   * 招待から参加する
   * ユーザを生成し、参加リクエストを送る
   */
  joinByInviteLinkWithCreateUser: UserNode;
  /**
   * 招待リクエストから参加する
   * ユーザを生成する
   */
  joinByInviteRequestWithCreateUser: UserNode;
  /** トークンでログインする */
  loginWithToken: MemberNode;
  /** ログアウトする */
  logout: Maybe<Scalars['Boolean']['output']>;
  /** 勤務のメールの送信先を追加する */
  registerDestEmailsForAttendance: BusinessNode;
  /** メンバーを復元する */
  restoreMember: MemberNode;
  /** シフトロールを復元する */
  restoreShiftRole: BusinessNode;
  /** タイムスロットを復元する */
  restoreTimeSlot: TimeSlotNode;
  /** Business を更新する */
  updateBusiness: BusinessNode;
  /** Update business schedule rule. */
  updateBusinessScheduleRule: BusinessNode;
  /** デフォルトシフトを更新する */
  updateDefaultShift: DefaultShiftNode;
  /** メンバーを更新する */
  updateMember: MemberNode;
  /** メンバーロールを更新する */
  updateMemberRole: MemberNode;
  /** 来週のシフトを更新する */
  updateNextWeekShift: Scalars['Boolean']['output'];
  /** シフトを更新する */
  updateShift: ShiftNode;
  /** シフトのリクエストを更新する */
  updateShiftRequest: ShiftRequestNode;
  /** シフトロールを更新する */
  updateShiftRole: BusinessNode;
  /** 打刻を更新する */
  updateTimeRecord: TimeRecordNode;
  /** タイムスロットを更新する */
  updateTimeSlot: TimeSlotNode;
  /** ユーザを更新する */
  updateViewerMember: MemberNode;
  /** ユーザを更新する */
  updateViewerUser: UserNode;
  /** ユーザのメールアドレスを更新する */
  updateViewerUserEmail: UserNode;
  /** ユーザのパスワードを更新する */
  updateViewerUserPassword: UserNode;
};


export type MutationAddIntegrationLineArgs = {
  input: AddIntegrationLineInput;
};


export type MutationApproveInviteLinkRequestArgs = {
  input: ApproveInviteLinkRequestInput;
};


export type MutationApproveInviteRequestArgs = {
  input: ApproveInviteRequestInput;
};


export type MutationClockInArgs = {
  input: ClockInInput;
};


export type MutationCreateBusinessArgs = {
  input: CreateBusinessInput;
};


export type MutationCreateInviteLinkArgs = {
  input: CreateInviteLinkInput;
};


export type MutationCreateInviteLinkRequestArgs = {
  input: CreateInviteLinkRequestInput;
};


export type MutationCreateMemberArgs = {
  input: CreateMemberInput;
};


export type MutationCreateShiftRoleArgs = {
  input: CreateShiftRoleInput;
};


export type MutationCreateShiftScheduleArgs = {
  input: CreateShiftScheduleInput;
};


export type MutationCreateShiftScheduleRequestArgs = {
  input: CreateShiftScheduleRequestInput;
};


export type MutationCreateTimeRecordArgs = {
  input: CreateTimeRecordInput;
};


export type MutationCreateTimeSlotArgs = {
  input: CreateTimeSlotInput;
};


export type MutationCreateUserWithEmailArgs = {
  input: CreateUserWithEmailInput;
};


export type MutationCreateUserWithGoogleArgs = {
  input: CreateUserWithGoogleInput;
};


export type MutationDeleteDefaultShiftArgs = {
  input: DeleteDefaultShiftInput;
};


export type MutationDeleteIntegrationArgs = {
  input: DeleteIntegrationInput;
};


export type MutationDeleteMemberArgs = {
  input: DeleteMemberInput;
};


export type MutationDeleteShiftArgs = {
  input: DeleteShiftInput;
};


export type MutationDeleteShiftRequestArgs = {
  input: DeleteShiftRequestInput;
};


export type MutationDeleteShiftRoleArgs = {
  input: DeleteShiftRoleInput;
};


export type MutationDeleteTimeRecordArgs = {
  input: DeleteTimeRecordInput;
};


export type MutationDeleteTimeSlotArgs = {
  input: DeleteTimeSlotInput;
};


export type MutationJoinByInviteLinkArgs = {
  input: JoinByInviteLinkInput;
};


export type MutationJoinByInviteLinkWithCreateUserArgs = {
  input: JoinByInviteLinkWithCreateUserInput;
};


export type MutationJoinByInviteRequestWithCreateUserArgs = {
  input: JoinByInviteRequestWithCreateUserInput;
};


export type MutationLoginWithTokenArgs = {
  input: LoginWithTokenInput;
};


export type MutationRegisterDestEmailsForAttendanceArgs = {
  input: RegisterDestEmailsForAttendanceInput;
};


export type MutationRestoreMemberArgs = {
  input: RestoreMemberInput;
};


export type MutationRestoreShiftRoleArgs = {
  input: RestoreShiftRoleInput;
};


export type MutationRestoreTimeSlotArgs = {
  input: RestoreTimeSlotInput;
};


export type MutationUpdateBusinessArgs = {
  input: UpdateBusinessInput;
};


export type MutationUpdateBusinessScheduleRuleArgs = {
  input: InputMaybe<UpdateBusinessScheduleRuleInput>;
};


export type MutationUpdateDefaultShiftArgs = {
  input: UpdateDefaultShiftInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationUpdateMemberRoleArgs = {
  input: UpdateMemberRoleInput;
};


export type MutationUpdateNextWeekShiftArgs = {
  input: UpdateNextWeekShiftInput;
};


export type MutationUpdateShiftArgs = {
  input: UpdateShiftInput;
};


export type MutationUpdateShiftRequestArgs = {
  input: UpdateShiftRequestInput;
};


export type MutationUpdateShiftRoleArgs = {
  input: UpdateShiftRoleInput;
};


export type MutationUpdateTimeRecordArgs = {
  input: UpdateTimeRecordInput;
};


export type MutationUpdateTimeSlotArgs = {
  input: UpdateTimeSlotInput;
};


export type MutationUpdateViewerMemberArgs = {
  input: UpdateViewerMemberInput;
};


export type MutationUpdateViewerUserArgs = {
  input: UpdateViewerUserInput;
};


export type MutationUpdateViewerUserEmailArgs = {
  input: UpdateViewerUserEmailInput;
};


export type MutationUpdateViewerUserPasswordArgs = {
  input: UpdateViewerUserPasswordInput;
};

export type NextWeekAssignableShiftNode = Node & {
  __typename?: 'NextWeekAssignableShiftNode';
  businessId: Scalars['ID']['output'];
  dateText: Scalars['String']['output'];
  day: Scalars['Int']['output'];
  defaultShiftId: Maybe<Scalars['String']['output']>;
  endTime: Scalars['Int']['output'];
  endTimeHour: Scalars['Int']['output'];
  endTimeMinute: Scalars['Int']['output'];
  endTimeText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAssignable: Scalars['Boolean']['output'];
  isAssigned: Scalars['Boolean']['output'];
  isDefaultEndTime: Scalars['Boolean']['output'];
  isDefaultShift: Scalars['Boolean']['output'];
  isDefaultStartTime: Scalars['Boolean']['output'];
  isDuplicated: Scalars['Boolean']['output'];
  isRequested: Scalars['Boolean']['output'];
  memberId: Scalars['ID']['output'];
  month: Scalars['Int']['output'];
  note: Maybe<Scalars['String']['output']>;
  shiftId: Maybe<Scalars['String']['output']>;
  shiftRequestId: Maybe<Scalars['String']['output']>;
  shiftRoleId: Scalars['ID']['output'];
  startTime: Scalars['Int']['output'];
  startTimeHour: Scalars['Int']['output'];
  startTimeMinute: Scalars['Int']['output'];
  startTimeText: Scalars['String']['output'];
  timeSlotId: Scalars['ID']['output'];
  year: Scalars['Int']['output'];
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  attendance: AttendanceNode;
  business: Maybe<BusinessNode>;
  integration: IntegrationNode;
  inviteLink: InviteLinkNode;
  inviteRequest: Maybe<InviteRequestNode>;
  member: Maybe<MemberNode>;
  shift: ShiftNode;
  shiftRequest: ShiftRequestNode;
  shiftRevision: ShiftRevisionNode;
  tableConfigs: Array<TableConfig>;
  tableDateText: TableDateText;
  tableDates: Array<TableDate>;
  tableWeeks: Array<TableWeek>;
  timeRecord: TimeRecordNode;
  timeSlot: TimeSlotNode;
  userExists: Maybe<UserNode>;
  /** ログインしているユーザ */
  viewer: Maybe<Viewer>;
};


export type QueryAttendanceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBusinessArgs = {
  login: Scalars['String']['input'];
};


export type QueryIntegrationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInviteLinkArgs = {
  code: Scalars['String']['input'];
};


export type QueryInviteRequestArgs = {
  inviteRequestId: Scalars['String']['input'];
};


export type QueryMemberArgs = {
  businessLogin: Scalars['String']['input'];
  checkinCode: Scalars['String']['input'];
};


export type QueryShiftArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShiftRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShiftRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTableDateTextArgs = {
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
};


export type QueryTableDatesArgs = {
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
};


export type QueryTimeRecordArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTimeSlotArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserExistsArgs = {
  email: Scalars['String']['input'];
};

export type RegisterDestEmailsForAttendanceInput = {
  businessLogin: Scalars['String']['input'];
  destEmails: InputMaybe<Array<DestEmail>>;
};

export type RestoreMemberInput = {
  businessLogin: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
};

export type RestoreShiftRoleInput = {
  businessLogin: Scalars['String']['input'];
  shiftRoleId: Scalars['ID']['input'];
};

export type RestoreTimeSlotInput = {
  businessLogin: Scalars['String']['input'];
  timeSlotId: Scalars['ID']['input'];
};

export type ShiftEdge = Edge & {
  __typename?: 'ShiftEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<ShiftNode>;
};

export type ShiftNode = Node & {
  __typename?: 'ShiftNode';
  business: Maybe<BusinessNode>;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  dateText: Scalars['String']['output'];
  dateTime: Scalars['Int']['output'];
  day: Scalars['Int']['output'];
  endTime: Scalars['Int']['output'];
  endTimeHour: Scalars['Int']['output'];
  endTimeMinute: Scalars['Int']['output'];
  endTimeText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  member: Maybe<MemberNode>;
  memberId: Scalars['ID']['output'];
  month: Scalars['Int']['output'];
  revisions: Array<ShiftRevisionNode>;
  revisionsConnection: Maybe<ShiftRevisionsConnection>;
  shiftRole: Maybe<ShiftRoleNode>;
  startTime: Scalars['Int']['output'];
  startTimeHour: Scalars['Int']['output'];
  startTimeMinute: Scalars['Int']['output'];
  startTimeText: Scalars['String']['output'];
  timeSlot: Maybe<TimeSlotNode>;
  timeSlotId: Scalars['ID']['output'];
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};


export type ShiftNodeRevisionsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type ShiftNodeRevisionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};

export type ShiftRequestEdge = Edge & {
  __typename?: 'ShiftRequestEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<ShiftRequestNode>;
};

export type ShiftRequestNode = Node & {
  __typename?: 'ShiftRequestNode';
  business: Maybe<BusinessNode>;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  dateText: Scalars['String']['output'];
  day: Scalars['Int']['output'];
  endTime: Scalars['Int']['output'];
  endTimeHour: Scalars['Int']['output'];
  endTimeMinute: Scalars['Int']['output'];
  endTimeText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  member: Maybe<MemberNode>;
  memberId: Scalars['ID']['output'];
  month: Scalars['Int']['output'];
  note: Scalars['String']['output'];
  startTime: Scalars['Int']['output'];
  startTimeHour: Scalars['Int']['output'];
  startTimeMinute: Scalars['Int']['output'];
  startTimeText: Scalars['String']['output'];
  timeSlot: Maybe<TimeSlotNode>;
  timeSlotId: Scalars['ID']['output'];
  updatedAt: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type ShiftRequestsConnection = Connection & {
  __typename?: 'ShiftRequestsConnection';
  edges: Maybe<Array<ShiftRequestEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShiftRevisionEdge = Edge & {
  __typename?: 'ShiftRevisionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<ShiftRevisionNode>;
};

export type ShiftRevisionNode = Node & {
  __typename?: 'ShiftRevisionNode';
  business: BusinessNode;
  businessId: Scalars['ID']['output'];
  endTime: Scalars['Int']['output'];
  endTimeHour: Scalars['Int']['output'];
  endTimeMinute: Scalars['Int']['output'];
  endTimeText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  member: MemberNode;
  memberId: Scalars['ID']['output'];
  shift: ShiftNode;
  shiftId: Scalars['ID']['output'];
  startTime: Scalars['Int']['output'];
  startTimeHour: Scalars['Int']['output'];
  startTimeMinute: Scalars['Int']['output'];
  startTimeText: Scalars['String']['output'];
};

export type ShiftRevisionsConnection = Connection & {
  __typename?: 'ShiftRevisionsConnection';
  edges: Maybe<Array<ShiftRevisionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShiftRoleEdge = Edge & {
  __typename?: 'ShiftRoleEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<ShiftRoleNode>;
};

export type ShiftRoleNode = Node & {
  __typename?: 'ShiftRoleNode';
  business: BusinessNode;
  businessId: Scalars['ID']['output'];
  color: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  members: Array<MemberNode>;
  membersConnection: Maybe<MembersConnection>;
  name: Scalars['String']['output'];
  shifts: Array<ShiftNode>;
  shiftsConnection: Maybe<ShiftsConnection>;
  sort: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};


export type ShiftRoleNodeMembersArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type ShiftRoleNodeMembersConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


export type ShiftRoleNodeShiftsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type ShiftRoleNodeShiftsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};

export type ShiftRolesConnection = Connection & {
  __typename?: 'ShiftRolesConnection';
  edges: Maybe<Array<ShiftRoleEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShiftScheduleEdge = Edge & {
  __typename?: 'ShiftScheduleEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<ShiftScheduleNode>;
};

export type ShiftScheduleNode = Node & {
  __typename?: 'ShiftScheduleNode';
  business: BusinessNode;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  endDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  note: Scalars['String']['output'];
  since: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  until: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ShiftScheduleRequestEdge = Edge & {
  __typename?: 'ShiftScheduleRequestEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<ShiftScheduleRequestNode>;
};

export type ShiftScheduleRequestNode = Node & {
  __typename?: 'ShiftScheduleRequestNode';
  business: Maybe<BusinessNode>;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  endDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  member: Maybe<MemberNode>;
  memberId: Scalars['ID']['output'];
  note: Scalars['String']['output'];
  since: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  until: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ShiftScheduleRequestsConnection = Connection & {
  __typename?: 'ShiftScheduleRequestsConnection';
  edges: Maybe<Array<ShiftScheduleRequestEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShiftSchedulesConnection = Connection & {
  __typename?: 'ShiftSchedulesConnection';
  edges: Maybe<Array<ShiftScheduleEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShiftsConnection = Connection & {
  __typename?: 'ShiftsConnection';
  edges: Maybe<Array<ShiftEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SkillEdge = Edge & {
  __typename?: 'SkillEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<SkillNode>;
};

export type SkillNode = Node & {
  __typename?: 'SkillNode';
  business: BusinessNode;
  businessId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  members: Maybe<MembersConnection>;
  name: Scalars['String']['output'];
};


export type SkillNodeMembersArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};

export type SkillsConnection = Connection & {
  __typename?: 'SkillsConnection';
  edges: Maybe<Array<ShiftScheduleRequestEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubscriptionNode = {
  __typename?: 'SubscriptionNode';
  expired: Scalars['Boolean']['output'];
  trialDays: Maybe<Scalars['Int']['output']>;
};

export type TableConfig = {
  __typename?: 'TableConfig';
  offset: Scalars['Int']['output'];
  since: Scalars['String']['output'];
  until: Scalars['String']['output'];
  weekText: Scalars['String']['output'];
};

export type TableDate = {
  __typename?: 'TableDate';
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  tableText: Scalars['String']['output'];
  text: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  uiText: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type TableDateText = {
  __typename?: 'TableDateText';
  dateTexts: Array<Scalars['String']['output']>;
  dateTextsWithAroundDay: Array<Scalars['String']['output']>;
  uiDateTexts: Array<Scalars['String']['output']>;
  uiDateTextsWithAroundDay: Array<Scalars['String']['output']>;
};

export type TableWeek = {
  __typename?: 'TableWeek';
  offset: Scalars['String']['output'];
  since: Scalars['String']['output'];
  until: Scalars['String']['output'];
  weekText: Scalars['String']['output'];
};

export type TimeRecordEdge = Edge & {
  __typename?: 'TimeRecordEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<TimeRecordNode>;
};

/** 打刻記録 */
export type TimeRecordNode = Node & {
  __typename?: 'TimeRecordNode';
  business: Maybe<BusinessNode>;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  /** 打刻した日付（文字列） */
  dateText: Scalars['String']['output'];
  /** 打刻した日時 */
  dateTime: Scalars['Int']['output'];
  /** 打刻をいつの日として扱うか */
  dateTimeAs: Scalars['Int']['output'];
  dateTimeAsText: Scalars['String']['output'];
  /** 打刻した日時（文字列） */
  dateTimeText: Scalars['String']['output'];
  day: Scalars['Int']['output'];
  hour: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isModified: Maybe<Scalars['Boolean']['output']>;
  isStartType: Scalars['Boolean']['output'];
  member: Maybe<MemberNode>;
  memberId: Scalars['ID']['output'];
  minute: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  revisions: Array<TimeRecordRevisionNode>;
  revisionsConnection: Maybe<TimeRecordRevisionsConnection>;
  second: Scalars['Int']['output'];
  timeText: Scalars['String']['output'];
  timestampText: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  type: TimeRecordType;
  updatedAt: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};


/** 打刻記録 */
export type TimeRecordNodeRevisionsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


/** 打刻記録 */
export type TimeRecordNodeRevisionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};

export type TimeRecordRevisionEdge = Edge & {
  __typename?: 'TimeRecordRevisionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<TimeRecordRevisionNode>;
};

/** 打刻記録の修正履歴 */
export type TimeRecordRevisionNode = Node & {
  __typename?: 'TimeRecordRevisionNode';
  business: Maybe<BusinessNode>;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  dateTime: Scalars['Int']['output'];
  dateTimeText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  member: Maybe<MemberNode>;
  memberId: Scalars['ID']['output'];
  timeRecord: Maybe<TimeRecordNode>;
  timeRecordId: Scalars['ID']['output'];
  updatedMember: Maybe<MemberNode>;
};

export type TimeRecordRevisionsConnection = Connection & {
  __typename?: 'TimeRecordRevisionsConnection';
  edges: Maybe<Array<TimeRecordRevisionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export const TimeRecordType = {
  BreakEnd: 'BREAK_END',
  BreakStart: 'BREAK_START',
  ShiftEnd: 'SHIFT_END',
  ShiftStart: 'SHIFT_START'
} as const;

export type TimeRecordType = typeof TimeRecordType[keyof typeof TimeRecordType];
export type TimeRecordsConnection = Connection & {
  __typename?: 'TimeRecordsConnection';
  edges: Maybe<Array<TimeRecordEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TimeSlotEdge = Edge & {
  __typename?: 'TimeSlotEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<TimeSlotNode>;
};

/** タイムスロット */
export type TimeSlotNode = Node & {
  __typename?: 'TimeSlotNode';
  business: BusinessNode;
  businessId: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  defaultShifts: Array<DefaultShiftNode>;
  defaultShiftsConnection: Maybe<DefaultShiftsConnection>;
  description: Scalars['String']['output'];
  endTime: Scalars['Int']['output'];
  endTimeHour: Scalars['Int']['output'];
  endTimeMinute: Scalars['Int']['output'];
  endTimeText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shiftRequests: Array<ShiftRequestNode>;
  shiftRequestsConnection: Maybe<ShiftRequestsConnection>;
  shifts: Array<ShiftNode>;
  shiftsConnection: Maybe<ShiftsConnection>;
  startTime: Scalars['Int']['output'];
  startTimeHour: Scalars['Int']['output'];
  startTimeMinute: Scalars['Int']['output'];
  startTimeText: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
};


/** タイムスロット */
export type TimeSlotNodeDefaultShiftsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


/** タイムスロット */
export type TimeSlotNodeDefaultShiftsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


/** タイムスロット */
export type TimeSlotNodeShiftRequestsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


/** タイムスロット */
export type TimeSlotNodeShiftRequestsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};


/** タイムスロット */
export type TimeSlotNodeShiftsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


/** タイムスロット */
export type TimeSlotNodeShiftsConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};

export type TimeSlotsConnection = Connection & {
  __typename?: 'TimeSlotsConnection';
  edges: Maybe<Array<TimeSlotEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UpdateBusinessInput = {
  businessId: Scalars['ID']['input'];
  companyName: Scalars['String']['input'];
  deadlineDayOfWeek: Scalars['Int']['input'];
  deadlineHour: Scalars['Int']['input'];
  deadlineMinute: Scalars['Int']['input'];
  login: Scalars['String']['input'];
  name: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  truncatedRange: Scalars['Int']['input'];
};

export type UpdateBusinessScheduleRuleInput = {
  businessLogin: Scalars['String']['input'];
  scheduleRule: Scalars['String']['input'];
};

export type UpdateDefaultShiftInput = {
  businessLogin: Scalars['String']['input'];
  dayOfWeek: Scalars['Int']['input'];
  endTimeHour: Scalars['Int']['input'];
  endTimeMinute: Scalars['Int']['input'];
  memberId: Scalars['ID']['input'];
  startTimeHour: Scalars['Int']['input'];
  startTimeMinute: Scalars['Int']['input'];
  timeSlotId: Scalars['ID']['input'];
};

export type UpdateMemberInput = {
  businessLogin: Scalars['String']['input'];
  checkinCode: Scalars['String']['input'];
  email: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  note: Scalars['String']['input'];
  shiftRoleIds: Array<Scalars['String']['input']>;
  sort: Scalars['Int']['input'];
};

export type UpdateMemberRoleInput = {
  businessLogin: Scalars['String']['input'];
  businessRole: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
};

export type UpdateNextWeekShiftInput = {
  businessLogin: Scalars['String']['input'];
  shifts: Array<UpdateShiftInput>;
};

export type UpdateShiftInput = {
  businessLogin: Scalars['String']['input'];
  day: Scalars['Int']['input'];
  endTimeHour: Scalars['Int']['input'];
  endTimeMinute: Scalars['Int']['input'];
  memberId: Scalars['ID']['input'];
  month: Scalars['Int']['input'];
  note: Scalars['String']['input'];
  shiftRoleId: Scalars['ID']['input'];
  startTimeHour: Scalars['Int']['input'];
  startTimeMinute: Scalars['Int']['input'];
  timeSlotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type UpdateShiftRequestInput = {
  businessLogin: Scalars['String']['input'];
  day: Scalars['Int']['input'];
  endTimeHour: Scalars['Int']['input'];
  endTimeMinute: Scalars['Int']['input'];
  memberId: Scalars['ID']['input'];
  month: Scalars['Int']['input'];
  startTimeHour: Scalars['Int']['input'];
  startTimeMinute: Scalars['Int']['input'];
  timeSlotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type UpdateShiftRoleInput = {
  businessLogin: Scalars['String']['input'];
  name: Scalars['String']['input'];
  shiftRoleId: Scalars['ID']['input'];
  sort: Scalars['Int']['input'];
};

export type UpdateTimeRecordInput = {
  businessLogin: Scalars['String']['input'];
  dateTextAs: Scalars['String']['input'];
  day: Scalars['Int']['input'];
  hour: Scalars['Int']['input'];
  memberId: Scalars['ID']['input'];
  minute: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  timeRecordId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type UpdateTimeSlotInput = {
  businessLogin: Scalars['String']['input'];
  endTimeHour: Scalars['Int']['input'];
  endTimeMinute: Scalars['Int']['input'];
  startTimeHour: Scalars['Int']['input'];
  startTimeMinute: Scalars['Int']['input'];
  timeSlotId: Scalars['ID']['input'];
  timeSlotName: InputMaybe<Scalars['String']['input']>;
};

export type UpdateViewerMember = {
  memberId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateViewerMemberInput = {
  businessLogin: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateViewerUserEmailInput = {
  email: Scalars['String']['input'];
};

export type UpdateViewerUserInput = {
  avatarFileId: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateViewerUserPasswordInput = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type UserEdge = Edge & {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<UserNode>;
};

/** ユーザ */
export type UserNode = Node & {
  __typename?: 'UserNode';
  avatarFileId: Maybe<Scalars['String']['output']>;
  avatarURL: Maybe<Scalars['String']['output']>;
  configColorMode: ColorMode;
  configLanguage: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  email: Maybe<Scalars['String']['output']>;
  hasPaymentMethod: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  members: Array<MemberNode>;
  membersConnection: Maybe<MembersConnection>;
  name: Scalars['String']['output'];
  role: UserRole;
  stripeCustomerId: Maybe<Scalars['String']['output']>;
  tel: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Int']['output'];
};


/** ユーザ */
export type UserNodeMembersArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


/** ユーザ */
export type UserNodeMembersConnectionArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
};

export const UserRole = {
  Admin: 'ADMIN',
  System: 'SYSTEM',
  User: 'USER'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];
export type UsersConnection = Connection & {
  __typename?: 'UsersConnection';
  edges: Maybe<Array<UserEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Viewer = {
  __typename?: 'Viewer';
  attendanceSummary: AttendanceSummaryNode;
  businessAttendances: Array<AttendanceNode>;
  businessDefaultShifts: Array<DefaultShiftNode>;
  businessShiftRequests: Array<ShiftRequestNode>;
  businessShiftScheduleRequest: Maybe<ShiftScheduleRequestNode>;
  businessTimeRecords: Array<TimeRecordNode>;
  businesses: Array<BusinessNode>;
  inviteLinkRequests: Array<InviteLinkRequestNode>;
  member: MemberNode;
  user: UserNode;
};


export type ViewerAttendanceSummaryArgs = {
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type ViewerBusinessAttendancesArgs = {
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type ViewerBusinessDefaultShiftsArgs = {
  businessLogin: Scalars['String']['input'];
};


export type ViewerBusinessShiftRequestsArgs = {
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type ViewerBusinessShiftScheduleRequestArgs = {
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type ViewerBusinessTimeRecordsArgs = {
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
};


export type ViewerBusinessesArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  withDetails: InputMaybe<Scalars['Boolean']['input']>;
};


export type ViewerMemberArgs = {
  businessLogin: Scalars['String']['input'];
};

export type BusinessAttendanceCsvTextsQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type BusinessAttendanceCsvTextsQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, attendanceCsvTexts: Array<{ __typename?: 'AttendanceCsvTextNode', id: string, shiftRoleId: string, shiftRoleName: string, since: string, until: string, text: string, extractText: string }> } | null };

export type BusinessAttendanceSummaryQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type BusinessAttendanceSummaryQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, attendanceSummary: { __typename?: 'AttendanceSummaryNode', id: string, totalWorkingTime: number, totalWorkingTimePerMember: Array<number | null>, totalWorkingTimePerDay: Array<number | null>, totalWorkingTimeText: string, totalWorkingTimePerMemberText: Array<string | null>, totalWorkingTimePerDayText: Array<string | null>, attendances: Array<{ __typename?: 'AttendanceNode', id: string, dateText: string, year: number, month: number, day: number, uiDateText: string, memberId: string, workingTime: number, workingTimeHour: number, workingTimeMinute: number, workingTimeText: string, breakingTime: number, breakingTimeHour: number, breakingTimeMinute: number, breakingTimeText: string, hasError: boolean, hasTimeRecordOrderError: boolean, hasLackingTimeRecordError: boolean, hasInternalError: boolean }> } } | null };

export type RegisterDestEmailsForAttendanceMutationVariables = Exact<{
  input: RegisterDestEmailsForAttendanceInput;
}>;


export type RegisterDestEmailsForAttendanceMutation = { __typename?: 'Mutation', registerDestEmailsForAttendance: { __typename?: 'BusinessNode', id: string } };

export type BusinessQueryVariables = Exact<{
  login: Scalars['String']['input'];
}>;


export type BusinessQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, login: string, name: string, companyName: string | null, description: string | null, timezone: string, deadlineDayOfWeekIndex: number, deadlineDayOfWeek: DayOfWeek, deadlineHour: number, deadlineMinute: number, truncatedRange: number, createdAt: number, scheduleRule: string, shiftRoles: Array<{ __typename?: 'ShiftRoleNode', id: string, name: string, description: string, color: string, sort: number, isDeleted: boolean }>, timeSlots: Array<{ __typename?: 'TimeSlotNode', id: string, name: string, description: string, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean }>, integrations: Array<{ __typename?: 'IntegrationNode', id: string, applicationId: string }>, subscription: { __typename?: 'SubscriptionNode', expired: boolean, trialDays: number | null } } | null };

export type BusinessAiGeneratedShiftScheduleQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  shiftScheduleRule: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type BusinessAiGeneratedShiftScheduleQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, aiGeneratedShiftSchedule: Array<{ __typename?: 'AiGeneratedShiftScheduleNode', id: string, memberId: string, shiftRoleId: string, dateText: string, timeSlotId: string } | null> } | null };

export type BusinessAssignableShiftsQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type BusinessAssignableShiftsQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, assignableShifts: Array<{ __typename?: 'AssignableShiftNode', id: string, shiftId: string | null, shiftRequestId: string | null, defaultShiftId: string | null, businessId: string, memberId: string, timeSlotId: string, shiftRoleId: string, dateText: string, year: number, month: number, day: number, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, note: string | null, isAssigned: boolean, isAssignable: boolean, isRequested: boolean, isDuplicated: boolean, isDefaultShift: boolean, isDefaultStartTime: boolean, isDefaultEndTime: boolean }> } | null };

export type BusinessDestEmailsQueryVariables = Exact<{
  login: Scalars['String']['input'];
}>;


export type BusinessDestEmailsQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, destEmails: Array<{ __typename?: 'DestEmailNode', id: string, email: string }> | null } | null };

export type BusinessIntegrationsQueryVariables = Exact<{
  login: Scalars['String']['input'];
}>;


export type BusinessIntegrationsQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, integrations: Array<{ __typename?: 'IntegrationNode', id: string, applicationId: string }> } | null };

export type BusinessMembersQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
}>;


export type BusinessMembersQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, inviteLink: { __typename?: 'InviteLinkNode', link: string } | null, members: Array<{ __typename?: 'MemberNode', id: string, name: string, checkinCode: string, role: MemberRole, sort: number, note: string, label: string, isArchived: boolean, isDeleted: boolean, shiftRoles: Array<{ __typename?: 'ShiftRoleNode', id: string, name: string, description: string, color: string, sort: number, isDeleted: boolean }>, user: { __typename?: 'UserNode', id: string, email: string | null, tel: string | null, name: string, avatarFileId: string | null, stripeCustomerId: string | null, hasPaymentMethod: boolean } | null, defaultShifts: Array<{ __typename?: 'DefaultShiftNode', id: string, dayOfWeekIndex: number, dayOfWeek: DayOfWeek, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, memberId: string, timeSlotId: string, member: { __typename?: 'MemberNode', id: string }, timeSlot: { __typename?: 'TimeSlotNode', id: string } }>, inviteRequest: { __typename?: 'InviteRequestNode', id: string, email: string } | null }>, inviteLinkRequests: Array<{ __typename?: 'InviteLinkRequestNode', id: string, name: string }> } | null };

export type BusinessShiftRequestsQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type BusinessShiftRequestsQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, shiftRequests: Array<{ __typename?: 'ShiftRequestNode', id: string, dateText: string, year: number, month: number, day: number, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, businessId: string, timeSlotId: string, note: string, memberId: string, member: { __typename?: 'MemberNode', id: string } | null }> } | null };

export type BusinessShiftRolesQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
}>;


export type BusinessShiftRolesQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, shiftRoles: Array<{ __typename?: 'ShiftRoleNode', id: string, name: string, description: string, color: string, sort: number, isDeleted: boolean }> } | null };

export type BusinessShiftScheduleQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type BusinessShiftScheduleQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, shiftSchedule: { __typename?: 'ShiftScheduleNode', id: string, startDate: string, endDate: string, note: string } | null } | null };

export type BusinessShiftScheduleRequestsQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type BusinessShiftScheduleRequestsQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, shiftScheduleRequests: Array<{ __typename?: 'ShiftScheduleRequestNode', id: string, since: string, until: string, startDate: string, endDate: string, memberId: string, note: string }> } | null };

export type BusinessShiftsQueryVariables = Exact<{
  login: Scalars['String']['input'];
}>;


export type BusinessShiftsQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, shifts: Array<{ __typename?: 'ShiftNode', id: string, dateTime: number, dateText: string, timezone: string, year: number, month: number, day: number, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean }> } | null };

export type BusinessTimeRecordsQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type BusinessTimeRecordsQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, truncatedRange: number, timeRecords: Array<{ __typename?: 'TimeRecordNode', id: string, dateTime: number, timezone: string, dateTimeAs: number, dateTimeAsText: string, dateTimeText: string, dateText: string, timeText: string, type: TimeRecordType, year: number, month: number, day: number, hour: number, minute: number, second: number, isDeleted: boolean, isModified: boolean | null, isStartType: boolean, timestampText: string, member: { __typename?: 'MemberNode', id: string } | null, revisions: Array<{ __typename?: 'TimeRecordRevisionNode', id: string, dateTime: number, dateTimeText: string, createdAt: number, isDeleted: boolean, updatedMember: { __typename?: 'MemberNode', id: string, name: string } | null }> }> } | null };

export type BusinessTimeSlotsQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
}>;


export type BusinessTimeSlotsQuery = { __typename?: 'Query', business: { __typename?: 'BusinessNode', id: string, timeSlots: Array<{ __typename?: 'TimeSlotNode', id: string, name: string, description: string, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean }> } | null };

export type CreateBusinessMutationVariables = Exact<{
  input: CreateBusinessInput;
}>;


export type CreateBusinessMutation = { __typename?: 'Mutation', createBusiness: { __typename?: 'BusinessNode', id: string } };

export type UpdateBusinessMutationVariables = Exact<{
  input: UpdateBusinessInput;
}>;


export type UpdateBusinessMutation = { __typename?: 'Mutation', updateBusiness: { __typename?: 'BusinessNode', id: string } };

export type UpdateBusinessScheduleRuleMutationVariables = Exact<{
  input: UpdateBusinessScheduleRuleInput;
}>;


export type UpdateBusinessScheduleRuleMutation = { __typename?: 'Mutation', updateBusinessScheduleRule: { __typename?: 'BusinessNode', id: string } };

export type DeleteDefaultShiftMutationVariables = Exact<{
  input: DeleteDefaultShiftInput;
}>;


export type DeleteDefaultShiftMutation = { __typename?: 'Mutation', deleteDefaultShift: { __typename?: 'DefaultShiftNode', id: string } | null };

export type UpdateDefaultShiftMutationVariables = Exact<{
  input: UpdateDefaultShiftInput;
}>;


export type UpdateDefaultShiftMutation = { __typename?: 'Mutation', updateDefaultShift: { __typename?: 'DefaultShiftNode', id: string } };

export type AssignableShiftFieldFragment = { __typename?: 'AssignableShiftNode', id: string, shiftId: string | null, shiftRequestId: string | null, defaultShiftId: string | null, businessId: string, memberId: string, timeSlotId: string, shiftRoleId: string, dateText: string, year: number, month: number, day: number, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, note: string | null, isAssigned: boolean, isAssignable: boolean, isRequested: boolean, isDuplicated: boolean, isDefaultShift: boolean, isDefaultStartTime: boolean, isDefaultEndTime: boolean };

export type AttendanceFieldsFragment = { __typename?: 'AttendanceNode', id: string, dateText: string, year: number, month: number, day: number, uiDateText: string, memberId: string, workingTime: number, workingTimeHour: number, workingTimeMinute: number, workingTimeText: string, breakingTime: number, breakingTimeHour: number, breakingTimeMinute: number, breakingTimeText: string, hasError: boolean, hasTimeRecordOrderError: boolean, hasLackingTimeRecordError: boolean, hasInternalError: boolean };

export type BusinessFieldsFragment = { __typename?: 'BusinessNode', id: string, login: string, name: string, companyName: string | null, description: string | null, timezone: string, deadlineDayOfWeekIndex: number, deadlineDayOfWeek: DayOfWeek, deadlineHour: number, deadlineMinute: number, truncatedRange: number, createdAt: number, scheduleRule: string, timeSlots: Array<{ __typename?: 'TimeSlotNode', id: string, name: string, description: string, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean }>, integrations: Array<{ __typename?: 'IntegrationNode', id: string, applicationId: string }>, subscription: { __typename?: 'SubscriptionNode', expired: boolean, trialDays: number | null } };

export type DefaultShiftFieldsFragment = { __typename?: 'DefaultShiftNode', id: string, dayOfWeekIndex: number, dayOfWeek: DayOfWeek, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, memberId: string, timeSlotId: string, member: { __typename?: 'MemberNode', id: string }, timeSlot: { __typename?: 'TimeSlotNode', id: string } };

export type IntegrationFieldsFragment = { __typename?: 'IntegrationNode', id: string, applicationId: string };

export type MemberFieldsFragment = { __typename?: 'MemberNode', id: string, name: string, checkinCode: string, role: MemberRole, sort: number, note: string, label: string, isArchived: boolean, isDeleted: boolean, shiftRoles: Array<{ __typename?: 'ShiftRoleNode', id: string, name: string, description: string, color: string, sort: number, isDeleted: boolean }>, defaultShifts: Array<{ __typename?: 'DefaultShiftNode', id: string, dayOfWeekIndex: number, dayOfWeek: DayOfWeek, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, memberId: string, timeSlotId: string, member: { __typename?: 'MemberNode', id: string }, timeSlot: { __typename?: 'TimeSlotNode', id: string } }>, user: { __typename?: 'UserNode', id: string, email: string | null, tel: string | null } | null, inviteRequest: { __typename?: 'InviteRequestNode', id: string, email: string } | null };

export type MemberShiftRequestFieldsFragment = { __typename?: 'ShiftRequestNode', id: string, dateText: string, year: number, month: number, day: number, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, note: string };

export type ShiftFieldsFragment = { __typename?: 'ShiftNode', id: string, dateTime: number, dateText: string, timezone: string, year: number, month: number, day: number, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean };

export type ShiftRequestFieldsFragment = { __typename?: 'ShiftRequestNode', id: string, dateText: string, year: number, month: number, day: number, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, businessId: string, timeSlotId: string, note: string, memberId: string, member: { __typename?: 'MemberNode', id: string } | null };

export type ShiftRoleFieldsFragment = { __typename?: 'ShiftRoleNode', id: string, name: string, description: string, color: string, sort: number, isDeleted: boolean };

export type ShiftScheduleFieldsFragment = { __typename?: 'ShiftScheduleNode', id: string, startDate: string, endDate: string, note: string };

export type ShiftScheduleRequestFieldsFragment = { __typename?: 'ShiftScheduleRequestNode', id: string, since: string, until: string, startDate: string, endDate: string, memberId: string, note: string };

export type SubscriptionFieldsFragment = { __typename?: 'SubscriptionNode', expired: boolean, trialDays: number | null };

export type TimeRecordFieldsFragment = { __typename?: 'TimeRecordNode', id: string, dateTime: number, timezone: string, dateTimeAs: number, dateTimeAsText: string, dateTimeText: string, dateText: string, timeText: string, type: TimeRecordType, year: number, month: number, day: number, hour: number, minute: number, second: number, isDeleted: boolean, isModified: boolean | null, isStartType: boolean, timestampText: string, member: { __typename?: 'MemberNode', id: string } | null, revisions: Array<{ __typename?: 'TimeRecordRevisionNode', id: string, dateTime: number, dateTimeText: string, createdAt: number, isDeleted: boolean, updatedMember: { __typename?: 'MemberNode', id: string, name: string } | null }> };

export type TimeSlotFieldsFragment = { __typename?: 'TimeSlotNode', id: string, name: string, description: string, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean };

export type UserFieldsFragment = { __typename?: 'UserNode', id: string, name: string, email: string | null, tel: string | null, avatarFileId: string | null, stripeCustomerId: string | null, hasPaymentMethod: boolean };

export type AddIntegrationLineMutationVariables = Exact<{
  input: AddIntegrationLineInput;
}>;


export type AddIntegrationLineMutation = { __typename?: 'Mutation', addIntegrationLine: { __typename?: 'BusinessNode', id: string } };

export type DeleteIntegrationMutationVariables = Exact<{
  input: DeleteIntegrationInput;
}>;


export type DeleteIntegrationMutation = { __typename?: 'Mutation', deleteIntegration: { __typename?: 'BusinessNode', id: string } | null };

export type ApproveInviteLinkRequestMutationVariables = Exact<{
  input: ApproveInviteLinkRequestInput;
}>;


export type ApproveInviteLinkRequestMutation = { __typename?: 'Mutation', approveInviteLinkRequest: { __typename?: 'InviteLinkRequestNode', id: string } | null };

export type ApproveInviteRequestMutationVariables = Exact<{
  input: ApproveInviteRequestInput;
}>;


export type ApproveInviteRequestMutation = { __typename?: 'Mutation', approveInviteRequest: { __typename?: 'InviteRequestNode', id: string } | null };

export type CreateInviteLinkMutationVariables = Exact<{
  input: CreateInviteLinkInput;
}>;


export type CreateInviteLinkMutation = { __typename?: 'Mutation', createInviteLink: { __typename?: 'InviteLinkNode', id: string } };

export type CreateInviteLinkRequestMutationVariables = Exact<{
  input: CreateInviteLinkRequestInput;
}>;


export type CreateInviteLinkRequestMutation = { __typename?: 'Mutation', createInviteLinkRequest: { __typename?: 'InviteLinkRequestNode', id: string } };

export type InviteLinkQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type InviteLinkQuery = { __typename?: 'Query', inviteLink: { __typename?: 'InviteLinkNode', id: string, business: { __typename?: 'BusinessNode', name: string } } };

export type InviteRequestQueryVariables = Exact<{
  inviteRequestId: Scalars['String']['input'];
}>;


export type InviteRequestQuery = { __typename?: 'Query', inviteRequest: { __typename?: 'InviteRequestNode', id: string, email: string, userExists: boolean, business: { __typename?: 'BusinessNode', id: string, name: string } } | null };

export type JoinByInviteLinkMutationVariables = Exact<{
  input: JoinByInviteLinkInput;
}>;


export type JoinByInviteLinkMutation = { __typename?: 'Mutation', joinByInviteLink: { __typename?: 'UserNode', id: string } };

export type JoinByInviteLinkWithCreateUserMutationVariables = Exact<{
  input: JoinByInviteLinkWithCreateUserInput;
}>;


export type JoinByInviteLinkWithCreateUserMutation = { __typename?: 'Mutation', joinByInviteLinkWithCreateUser: { __typename?: 'UserNode', id: string } };

export type JoinByInviteRequestWithCreateUserMutationVariables = Exact<{
  input: JoinByInviteRequestWithCreateUserInput;
}>;


export type JoinByInviteRequestWithCreateUserMutation = { __typename?: 'Mutation', joinByInviteRequestWithCreateUser: { __typename?: 'UserNode', id: string } };

export type CreateMemberMutationVariables = Exact<{
  input: CreateMemberInput;
}>;


export type CreateMemberMutation = { __typename?: 'Mutation', createMember: { __typename?: 'MemberNode', id: string } };

export type DeleteMemberMutationVariables = Exact<{
  input: DeleteMemberInput;
}>;


export type DeleteMemberMutation = { __typename?: 'Mutation', deleteMember: { __typename?: 'MemberNode', id: string } };

export type MemberQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  checkinCode: Scalars['String']['input'];
}>;


export type MemberQuery = { __typename?: 'Query', member: { __typename?: 'MemberNode', id: string, name: string, checkinCode: string, role: MemberRole, sort: number, note: string, label: string, isArchived: boolean, isDeleted: boolean, timeRecords: Array<{ __typename?: 'TimeRecordNode', id: string, dateTime: number, timezone: string, dateTimeAs: number, dateTimeAsText: string, dateTimeText: string, dateText: string, timeText: string, type: TimeRecordType, year: number, month: number, day: number, hour: number, minute: number, second: number, isDeleted: boolean, isModified: boolean | null, isStartType: boolean, timestampText: string, member: { __typename?: 'MemberNode', id: string } | null, revisions: Array<{ __typename?: 'TimeRecordRevisionNode', id: string, dateTime: number, dateTimeText: string, createdAt: number, isDeleted: boolean, updatedMember: { __typename?: 'MemberNode', id: string, name: string } | null }> }> } | null };

export type RestoreMemberMutationVariables = Exact<{
  input: RestoreMemberInput;
}>;


export type RestoreMemberMutation = { __typename?: 'Mutation', restoreMember: { __typename?: 'MemberNode', id: string } };

export type UpdateMemberMutationVariables = Exact<{
  input: UpdateMemberInput;
}>;


export type UpdateMemberMutation = { __typename?: 'Mutation', updateMember: { __typename?: 'MemberNode', id: string } };

export type UpdateMemberRoleMutationVariables = Exact<{
  input: UpdateMemberRoleInput;
}>;


export type UpdateMemberRoleMutation = { __typename?: 'Mutation', updateMemberRole: { __typename?: 'MemberNode', id: string } };

export type UpdateNextWeekShiftMutationVariables = Exact<{
  input: UpdateNextWeekShiftInput;
}>;


export type UpdateNextWeekShiftMutation = { __typename?: 'Mutation', updateNextWeekShift: boolean };

export type DeleteShiftMutationVariables = Exact<{
  input: DeleteShiftInput;
}>;


export type DeleteShiftMutation = { __typename?: 'Mutation', deleteShift: { __typename?: 'ShiftNode', id: string } };

export type UpdateShiftMutationVariables = Exact<{
  input: UpdateShiftInput;
}>;


export type UpdateShiftMutation = { __typename?: 'Mutation', updateShift: { __typename?: 'ShiftNode', id: string } };

export type DeleteShiftRequestMutationVariables = Exact<{
  input: DeleteShiftRequestInput;
}>;


export type DeleteShiftRequestMutation = { __typename?: 'Mutation', deleteShiftRequest: { __typename?: 'ShiftRequestNode', id: string } | null };

export type UpdateShiftRequestMutationVariables = Exact<{
  input: UpdateShiftRequestInput;
}>;


export type UpdateShiftRequestMutation = { __typename?: 'Mutation', updateShiftRequest: { __typename?: 'ShiftRequestNode', id: string } };

export type CreateShiftRoleMutationVariables = Exact<{
  input: CreateShiftRoleInput;
}>;


export type CreateShiftRoleMutation = { __typename?: 'Mutation', createShiftRole: { __typename?: 'BusinessNode', id: string, login: string, name: string, companyName: string | null, description: string | null, timezone: string, deadlineDayOfWeekIndex: number, deadlineDayOfWeek: DayOfWeek, deadlineHour: number, deadlineMinute: number, truncatedRange: number, createdAt: number, scheduleRule: string, shiftRoles: Array<{ __typename?: 'ShiftRoleNode', id: string, name: string, description: string, color: string, sort: number, isDeleted: boolean }>, timeSlots: Array<{ __typename?: 'TimeSlotNode', id: string, name: string, description: string, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean }>, integrations: Array<{ __typename?: 'IntegrationNode', id: string, applicationId: string }>, subscription: { __typename?: 'SubscriptionNode', expired: boolean, trialDays: number | null } } };

export type DeleteShiftRoleMutationVariables = Exact<{
  input: DeleteShiftRoleInput;
}>;


export type DeleteShiftRoleMutation = { __typename?: 'Mutation', deleteShiftRole: { __typename?: 'BusinessNode', id: string, login: string, name: string, companyName: string | null, description: string | null, timezone: string, deadlineDayOfWeekIndex: number, deadlineDayOfWeek: DayOfWeek, deadlineHour: number, deadlineMinute: number, truncatedRange: number, createdAt: number, scheduleRule: string, shiftRoles: Array<{ __typename?: 'ShiftRoleNode', id: string, name: string, description: string, color: string, sort: number, isDeleted: boolean }>, timeSlots: Array<{ __typename?: 'TimeSlotNode', id: string, name: string, description: string, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean }>, integrations: Array<{ __typename?: 'IntegrationNode', id: string, applicationId: string }>, subscription: { __typename?: 'SubscriptionNode', expired: boolean, trialDays: number | null } } };

export type RestoreShiftRoleMutationVariables = Exact<{
  input: RestoreShiftRoleInput;
}>;


export type RestoreShiftRoleMutation = { __typename?: 'Mutation', restoreShiftRole: { __typename?: 'BusinessNode', id: string, login: string, name: string, companyName: string | null, description: string | null, timezone: string, deadlineDayOfWeekIndex: number, deadlineDayOfWeek: DayOfWeek, deadlineHour: number, deadlineMinute: number, truncatedRange: number, createdAt: number, scheduleRule: string, shiftRoles: Array<{ __typename?: 'ShiftRoleNode', id: string, name: string, description: string, color: string, sort: number, isDeleted: boolean }>, timeSlots: Array<{ __typename?: 'TimeSlotNode', id: string, name: string, description: string, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean }>, integrations: Array<{ __typename?: 'IntegrationNode', id: string, applicationId: string }>, subscription: { __typename?: 'SubscriptionNode', expired: boolean, trialDays: number | null } } };

export type UpdateShiftRoleMutationVariables = Exact<{
  input: UpdateShiftRoleInput;
}>;


export type UpdateShiftRoleMutation = { __typename?: 'Mutation', updateShiftRole: { __typename?: 'BusinessNode', id: string, login: string, name: string, companyName: string | null, description: string | null, timezone: string, deadlineDayOfWeekIndex: number, deadlineDayOfWeek: DayOfWeek, deadlineHour: number, deadlineMinute: number, truncatedRange: number, createdAt: number, scheduleRule: string, shiftRoles: Array<{ __typename?: 'ShiftRoleNode', id: string, name: string, description: string, color: string, sort: number, isDeleted: boolean }>, timeSlots: Array<{ __typename?: 'TimeSlotNode', id: string, name: string, description: string, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean }>, integrations: Array<{ __typename?: 'IntegrationNode', id: string, applicationId: string }>, subscription: { __typename?: 'SubscriptionNode', expired: boolean, trialDays: number | null } } };

export type CreateShiftScheduleMutationVariables = Exact<{
  input: CreateShiftScheduleInput;
}>;


export type CreateShiftScheduleMutation = { __typename?: 'Mutation', createShiftSchedule: { __typename?: 'ShiftScheduleNode', id: string } };

export type CreateShiftScheduleRequestMutationVariables = Exact<{
  input: CreateShiftScheduleRequestInput;
}>;


export type CreateShiftScheduleRequestMutation = { __typename?: 'Mutation', createShiftScheduleRequest: { __typename?: 'ShiftScheduleRequestNode', id: string } };

export type TableConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type TableConfigsQuery = { __typename?: 'Query', tableConfigs: Array<{ __typename?: 'TableConfig', offset: number, since: string, until: string, weekText: string }> };

export type TableDateTextQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
}>;


export type TableDateTextQuery = { __typename?: 'Query', tableDateText: { __typename?: 'TableDateText', dateTexts: Array<string>, uiDateTexts: Array<string>, dateTextsWithAroundDay: Array<string>, uiDateTextsWithAroundDay: Array<string> } };

export type TableDatesQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
}>;


export type TableDatesQuery = { __typename?: 'Query', tableDates: Array<{ __typename?: 'TableDate', text: string, uiText: string, tableText: string, year: number, month: number, day: number, timezone: string }> };

export type TableWeeksQueryVariables = Exact<{ [key: string]: never; }>;


export type TableWeeksQuery = { __typename?: 'Query', tableWeeks: Array<{ __typename?: 'TableWeek', offset: string, since: string, until: string, weekText: string }> };

export type ClockInMutationVariables = Exact<{
  input: ClockInInput;
}>;


export type ClockInMutation = { __typename?: 'Mutation', clockIn: boolean | null };

export type CreateTimeRecordMutationVariables = Exact<{
  input: CreateTimeRecordInput;
}>;


export type CreateTimeRecordMutation = { __typename?: 'Mutation', createTimeRecord: { __typename?: 'TimeRecordNode', id: string } };

export type DeleteTimeRecordMutationVariables = Exact<{
  input: DeleteTimeRecordInput;
}>;


export type DeleteTimeRecordMutation = { __typename?: 'Mutation', deleteTimeRecord: { __typename?: 'TimeRecordNode', id: string } };

export type UpdateTimeRecordMutationVariables = Exact<{
  input: UpdateTimeRecordInput;
}>;


export type UpdateTimeRecordMutation = { __typename?: 'Mutation', updateTimeRecord: { __typename?: 'TimeRecordNode', id: string } };

export type CreateTimeSlotMutationVariables = Exact<{
  input: CreateTimeSlotInput;
}>;


export type CreateTimeSlotMutation = { __typename?: 'Mutation', createTimeSlot: { __typename?: 'TimeSlotNode', id: string } };

export type DeleteTimeSlotMutationVariables = Exact<{
  input: DeleteTimeSlotInput;
}>;


export type DeleteTimeSlotMutation = { __typename?: 'Mutation', deleteTimeSlot: { __typename?: 'TimeSlotNode', id: string } };

export type RestoreTimeSlotMutationVariables = Exact<{
  input: RestoreTimeSlotInput;
}>;


export type RestoreTimeSlotMutation = { __typename?: 'Mutation', restoreTimeSlot: { __typename?: 'TimeSlotNode', id: string } };

export type UpdateTimeSlotMutationVariables = Exact<{
  input: UpdateTimeSlotInput;
}>;


export type UpdateTimeSlotMutation = { __typename?: 'Mutation', updateTimeSlot: { __typename?: 'TimeSlotNode', id: string } };

export type CreateUserWithEmailMutationVariables = Exact<{
  input: CreateUserWithEmailInput;
}>;


export type CreateUserWithEmailMutation = { __typename?: 'Mutation', createUserWithEmail: { __typename?: 'UserNode', id: string } };

export type CreateUserWithGoogleMutationVariables = Exact<{
  input: CreateUserWithGoogleInput;
}>;


export type CreateUserWithGoogleMutation = { __typename?: 'Mutation', createUserWithGoogle: { __typename?: 'UserNode', id: string } };

export type UserExistsQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type UserExistsQuery = { __typename?: 'Query', userExists: { __typename?: 'UserNode', email: string | null } | null };

export type LoginWithTokenMutationVariables = Exact<{
  input: LoginWithTokenInput;
}>;


export type LoginWithTokenMutation = { __typename?: 'Mutation', loginWithToken: { __typename?: 'MemberNode', id: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean | null };

export type UpdateViewerMemberMutationVariables = Exact<{
  input: UpdateViewerMemberInput;
}>;


export type UpdateViewerMemberMutation = { __typename?: 'Mutation', updateViewerMember: { __typename?: 'MemberNode', id: string } };

export type UpdateViewerUserMutationVariables = Exact<{
  input: UpdateViewerUserInput;
}>;


export type UpdateViewerUserMutation = { __typename?: 'Mutation', updateViewerUser: { __typename?: 'UserNode', id: string } };

export type UpdateViewerUserEmailMutationVariables = Exact<{
  input: UpdateViewerUserEmailInput;
}>;


export type UpdateViewerUserEmailMutation = { __typename?: 'Mutation', updateViewerUserEmail: { __typename?: 'UserNode', id: string } };

export type UpdateViewerUserPasswordMutationVariables = Exact<{
  input: UpdateViewerUserPasswordInput;
}>;


export type UpdateViewerUserPasswordMutation = { __typename?: 'Mutation', updateViewerUserPassword: { __typename?: 'UserNode', id: string } };

export type ViewerBusinessAttendancesQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type ViewerBusinessAttendancesQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', attendanceSummary: { __typename?: 'AttendanceSummaryNode', id: string, totalWorkingTime: number, totalWorkingTimePerMember: Array<number | null>, totalWorkingTimePerDay: Array<number | null>, totalWorkingTimeText: string, totalWorkingTimePerMemberText: Array<string | null>, totalWorkingTimePerDayText: Array<string | null>, attendances: Array<{ __typename?: 'AttendanceNode', id: string, dateText: string, year: number, month: number, day: number, uiDateText: string, memberId: string, workingTime: number, workingTimeHour: number, workingTimeMinute: number, workingTimeText: string, breakingTime: number, breakingTimeHour: number, breakingTimeMinute: number, breakingTimeText: string, hasError: boolean, hasTimeRecordOrderError: boolean, hasLackingTimeRecordError: boolean, hasInternalError: boolean }> } } | null };

export type ViewerBusinessDefaultShiftsQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
}>;


export type ViewerBusinessDefaultShiftsQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', businessDefaultShifts: Array<{ __typename?: 'DefaultShiftNode', id: string, dayOfWeekIndex: number, dayOfWeek: DayOfWeek, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, memberId: string, timeSlotId: string, member: { __typename?: 'MemberNode', id: string }, timeSlot: { __typename?: 'TimeSlotNode', id: string } }> } | null };

export type ViewerBusinessShiftRequestsQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type ViewerBusinessShiftRequestsQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', businessShiftRequests: Array<{ __typename?: 'ShiftRequestNode', id: string, dateText: string, year: number, month: number, day: number, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, businessId: string, timeSlotId: string, note: string, memberId: string, member: { __typename?: 'MemberNode', id: string } | null }> } | null };

export type ViewerBusinessTimeRecordsQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type ViewerBusinessTimeRecordsQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', businessTimeRecords: Array<{ __typename?: 'TimeRecordNode', id: string, dateTime: number, timezone: string, dateTimeAs: number, dateTimeAsText: string, dateTimeText: string, dateText: string, timeText: string, type: TimeRecordType, year: number, month: number, day: number, hour: number, minute: number, second: number, isDeleted: boolean, isModified: boolean | null, isStartType: boolean, timestampText: string, member: { __typename?: 'MemberNode', id: string } | null, revisions: Array<{ __typename?: 'TimeRecordRevisionNode', id: string, dateTime: number, dateTimeText: string, createdAt: number, isDeleted: boolean, updatedMember: { __typename?: 'MemberNode', id: string, name: string } | null }> }> } | null };

export type ViewerBusinessesQueryVariables = Exact<{
  withDetails?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ViewerBusinessesQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', businesses?: Array<{ __typename?: 'BusinessNode', id: string, login: string, name: string, companyName: string | null, description: string | null, timezone: string, deadlineDayOfWeekIndex: number, deadlineDayOfWeek: DayOfWeek, deadlineHour: number, deadlineMinute: number, truncatedRange: number, createdAt: number, scheduleRule: string, timeSlots: Array<{ __typename?: 'TimeSlotNode', id: string, name: string, description: string, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, isDeleted: boolean }>, integrations: Array<{ __typename?: 'IntegrationNode', id: string, applicationId: string }>, subscription: { __typename?: 'SubscriptionNode', expired: boolean, trialDays: number | null } }>, inviteLinkRequests: Array<{ __typename?: 'InviteLinkRequestNode', id: string, business: { __typename?: 'BusinessNode', id: string, name: string, companyName: string | null, login: string } }> } | null };

export type ViewerMemberQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
}>;


export type ViewerMemberQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', member: { __typename?: 'MemberNode', id: string, name: string, checkinCode: string, role: MemberRole, sort: number, note: string, label: string, isArchived: boolean, isDeleted: boolean, timeRecords: Array<{ __typename?: 'TimeRecordNode', id: string, dateTime: number, timezone: string, dateTimeAs: number, dateTimeAsText: string, dateTimeText: string, dateText: string, timeText: string, type: TimeRecordType, year: number, month: number, day: number, hour: number, minute: number, second: number, isDeleted: boolean, isModified: boolean | null, isStartType: boolean, timestampText: string, member: { __typename?: 'MemberNode', id: string } | null, revisions: Array<{ __typename?: 'TimeRecordRevisionNode', id: string, dateTime: number, dateTimeText: string, createdAt: number, isDeleted: boolean, updatedMember: { __typename?: 'MemberNode', id: string, name: string } | null }> }>, shiftRoles: Array<{ __typename?: 'ShiftRoleNode', id: string, name: string, description: string, color: string, sort: number, isDeleted: boolean }>, user: { __typename?: 'UserNode', id: string, email: string | null, tel: string | null } | null, defaultShifts: Array<{ __typename?: 'DefaultShiftNode', id: string, dayOfWeekIndex: number, dayOfWeek: DayOfWeek, startTime: number, startTimeHour: number, startTimeMinute: number, startTimeText: string, endTime: number, endTimeHour: number, endTimeMinute: number, endTimeText: string, memberId: string, timeSlotId: string, member: { __typename?: 'MemberNode', id: string }, timeSlot: { __typename?: 'TimeSlotNode', id: string } }>, inviteRequest: { __typename?: 'InviteRequestNode', id: string, email: string } | null } } | null };

export type ViewerShiftScheduleRequestQueryVariables = Exact<{
  businessLogin: Scalars['String']['input'];
  since: Scalars['String']['input'];
  until: Scalars['String']['input'];
}>;


export type ViewerShiftScheduleRequestQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', businessShiftScheduleRequest: { __typename?: 'ShiftScheduleRequestNode', id: string, since: string, until: string, startDate: string, endDate: string, memberId: string, note: string } | null } | null };

export type ViewerUserQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerUserQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', user: { __typename?: 'UserNode', id: string, name: string, email: string | null, tel: string | null, avatarFileId: string | null, stripeCustomerId: string | null, hasPaymentMethod: boolean } } | null };

export const AssignableShiftFieldFragmentDoc = gql`
    fragment AssignableShiftField on AssignableShiftNode {
  id
  shiftId
  shiftRequestId
  defaultShiftId
  businessId
  memberId
  timeSlotId
  shiftRoleId
  dateText
  year
  month
  day
  startTime
  startTimeHour
  startTimeMinute
  startTimeText
  endTime
  endTimeHour
  endTimeMinute
  endTimeText
  note
  isAssigned
  isAssignable
  isRequested
  isDuplicated
  isDefaultShift
  isDefaultStartTime
  isDefaultEndTime
}
    `;
export const AttendanceFieldsFragmentDoc = gql`
    fragment AttendanceFields on AttendanceNode {
  id
  dateText
  year
  month
  day
  uiDateText
  memberId
  workingTime
  workingTimeHour
  workingTimeMinute
  workingTimeText
  breakingTime
  breakingTimeHour
  breakingTimeMinute
  breakingTimeText
  hasError
  hasTimeRecordOrderError
  hasLackingTimeRecordError
  hasInternalError
}
    `;
export const TimeSlotFieldsFragmentDoc = gql`
    fragment TimeSlotFields on TimeSlotNode {
  id
  name
  description
  startTime
  startTimeHour
  startTimeMinute
  startTimeText
  endTime
  endTimeHour
  endTimeMinute
  endTimeText
  isDeleted
}
    `;
export const IntegrationFieldsFragmentDoc = gql`
    fragment IntegrationFields on IntegrationNode {
  id
  applicationId
}
    `;
export const SubscriptionFieldsFragmentDoc = gql`
    fragment SubscriptionFields on SubscriptionNode {
  expired
  trialDays
}
    `;
export const BusinessFieldsFragmentDoc = gql`
    fragment BusinessFields on BusinessNode {
  id
  login
  name
  companyName
  description
  timezone
  deadlineDayOfWeekIndex
  deadlineDayOfWeek
  deadlineHour
  deadlineMinute
  truncatedRange
  timeSlots {
    ...TimeSlotFields
  }
  integrations {
    ...IntegrationFields
  }
  createdAt
  subscription {
    ...SubscriptionFields
  }
  scheduleRule
}
    ${TimeSlotFieldsFragmentDoc}
${IntegrationFieldsFragmentDoc}
${SubscriptionFieldsFragmentDoc}`;
export const ShiftRoleFieldsFragmentDoc = gql`
    fragment ShiftRoleFields on ShiftRoleNode {
  id
  name
  description
  color
  sort
  isDeleted
}
    `;
export const DefaultShiftFieldsFragmentDoc = gql`
    fragment DefaultShiftFields on DefaultShiftNode {
  id
  dayOfWeekIndex
  dayOfWeek
  startTime
  startTimeHour
  startTimeMinute
  startTimeText
  endTime
  endTimeHour
  endTimeMinute
  endTimeText
  memberId
  member {
    id
  }
  timeSlotId
  timeSlot {
    id
  }
}
    `;
export const MemberFieldsFragmentDoc = gql`
    fragment MemberFields on MemberNode {
  id
  name
  checkinCode
  role
  sort
  note
  label
  isArchived
  isDeleted
  shiftRoles {
    ...ShiftRoleFields
  }
  defaultShifts {
    ...DefaultShiftFields
  }
  user {
    id
    email
    tel
  }
  inviteRequest {
    id
    email
  }
}
    ${ShiftRoleFieldsFragmentDoc}
${DefaultShiftFieldsFragmentDoc}`;
export const MemberShiftRequestFieldsFragmentDoc = gql`
    fragment MemberShiftRequestFields on ShiftRequestNode {
  id
  dateText
  year
  month
  day
  startTime
  startTimeHour
  startTimeMinute
  startTimeText
  endTime
  endTimeHour
  endTimeMinute
  endTimeText
  note
}
    `;
export const ShiftFieldsFragmentDoc = gql`
    fragment ShiftFields on ShiftNode {
  id
  dateTime
  dateText
  timezone
  year
  month
  day
  startTime
  startTimeHour
  startTimeMinute
  startTimeText
  endTime
  endTimeHour
  endTimeMinute
  endTimeText
  isDeleted
}
    `;
export const ShiftRequestFieldsFragmentDoc = gql`
    fragment ShiftRequestFields on ShiftRequestNode {
  id
  dateText
  year
  month
  day
  startTime
  startTimeHour
  startTimeMinute
  startTimeText
  endTime
  endTimeHour
  endTimeMinute
  endTimeText
  businessId
  timeSlotId
  note
  memberId
  member {
    id
  }
}
    `;
export const ShiftScheduleFieldsFragmentDoc = gql`
    fragment ShiftScheduleFields on ShiftScheduleNode {
  id
  startDate
  endDate
  note
}
    `;
export const ShiftScheduleRequestFieldsFragmentDoc = gql`
    fragment ShiftScheduleRequestFields on ShiftScheduleRequestNode {
  id
  since
  until
  startDate
  endDate
  memberId
  note
}
    `;
export const TimeRecordFieldsFragmentDoc = gql`
    fragment TimeRecordFields on TimeRecordNode {
  id
  dateTime
  timezone
  dateTimeAs
  dateTimeAsText
  dateTimeText
  dateText
  timeText
  type
  year
  month
  day
  hour
  minute
  second
  isDeleted
  isModified
  isStartType
  timestampText
  member {
    id
  }
  revisions {
    id
    dateTime
    dateTimeText
    updatedMember {
      id
      name
    }
    createdAt
    isDeleted
  }
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on UserNode {
  id
  name
  email
  tel
  avatarFileId
  stripeCustomerId
  hasPaymentMethod
}
    `;
export const BusinessAttendanceCsvTextsDocument = gql`
    query BusinessAttendanceCsvTexts($businessLogin: String!, $since: String!, $until: String!) {
  business(login: $businessLogin) {
    id
    attendanceCsvTexts(since: $since, until: $until) {
      id
      shiftRoleId
      shiftRoleName
      since
      until
      text
      extractText
    }
  }
}
    `;

/**
 * __useBusinessAttendanceCsvTextsQuery__
 *
 * To run a query within a React component, call `useBusinessAttendanceCsvTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAttendanceCsvTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAttendanceCsvTextsQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useBusinessAttendanceCsvTextsQuery(baseOptions: Apollo.QueryHookOptions<BusinessAttendanceCsvTextsQuery, BusinessAttendanceCsvTextsQueryVariables> & ({ variables: BusinessAttendanceCsvTextsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessAttendanceCsvTextsQuery, BusinessAttendanceCsvTextsQueryVariables>(BusinessAttendanceCsvTextsDocument, options);
      }
export function useBusinessAttendanceCsvTextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessAttendanceCsvTextsQuery, BusinessAttendanceCsvTextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessAttendanceCsvTextsQuery, BusinessAttendanceCsvTextsQueryVariables>(BusinessAttendanceCsvTextsDocument, options);
        }
export function useBusinessAttendanceCsvTextsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessAttendanceCsvTextsQuery, BusinessAttendanceCsvTextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessAttendanceCsvTextsQuery, BusinessAttendanceCsvTextsQueryVariables>(BusinessAttendanceCsvTextsDocument, options);
        }
export type BusinessAttendanceCsvTextsQueryHookResult = ReturnType<typeof useBusinessAttendanceCsvTextsQuery>;
export type BusinessAttendanceCsvTextsLazyQueryHookResult = ReturnType<typeof useBusinessAttendanceCsvTextsLazyQuery>;
export type BusinessAttendanceCsvTextsSuspenseQueryHookResult = ReturnType<typeof useBusinessAttendanceCsvTextsSuspenseQuery>;
export type BusinessAttendanceCsvTextsQueryResult = Apollo.QueryResult<BusinessAttendanceCsvTextsQuery, BusinessAttendanceCsvTextsQueryVariables>;
export const BusinessAttendanceSummaryDocument = gql`
    query BusinessAttendanceSummary($businessLogin: String!, $since: String!, $until: String!) {
  business(login: $businessLogin) {
    id
    attendanceSummary(since: $since, until: $until) {
      id
      totalWorkingTime
      totalWorkingTimePerMember
      totalWorkingTimePerDay
      totalWorkingTimeText
      totalWorkingTimePerMemberText
      totalWorkingTimePerDayText
      attendances {
        ...AttendanceFields
      }
    }
  }
}
    ${AttendanceFieldsFragmentDoc}`;

/**
 * __useBusinessAttendanceSummaryQuery__
 *
 * To run a query within a React component, call `useBusinessAttendanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAttendanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAttendanceSummaryQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useBusinessAttendanceSummaryQuery(baseOptions: Apollo.QueryHookOptions<BusinessAttendanceSummaryQuery, BusinessAttendanceSummaryQueryVariables> & ({ variables: BusinessAttendanceSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessAttendanceSummaryQuery, BusinessAttendanceSummaryQueryVariables>(BusinessAttendanceSummaryDocument, options);
      }
export function useBusinessAttendanceSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessAttendanceSummaryQuery, BusinessAttendanceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessAttendanceSummaryQuery, BusinessAttendanceSummaryQueryVariables>(BusinessAttendanceSummaryDocument, options);
        }
export function useBusinessAttendanceSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessAttendanceSummaryQuery, BusinessAttendanceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessAttendanceSummaryQuery, BusinessAttendanceSummaryQueryVariables>(BusinessAttendanceSummaryDocument, options);
        }
export type BusinessAttendanceSummaryQueryHookResult = ReturnType<typeof useBusinessAttendanceSummaryQuery>;
export type BusinessAttendanceSummaryLazyQueryHookResult = ReturnType<typeof useBusinessAttendanceSummaryLazyQuery>;
export type BusinessAttendanceSummarySuspenseQueryHookResult = ReturnType<typeof useBusinessAttendanceSummarySuspenseQuery>;
export type BusinessAttendanceSummaryQueryResult = Apollo.QueryResult<BusinessAttendanceSummaryQuery, BusinessAttendanceSummaryQueryVariables>;
export const RegisterDestEmailsForAttendanceDocument = gql`
    mutation RegisterDestEmailsForAttendance($input: RegisterDestEmailsForAttendanceInput!) {
  registerDestEmailsForAttendance(input: $input) {
    id
  }
}
    `;
export type RegisterDestEmailsForAttendanceMutationFn = Apollo.MutationFunction<RegisterDestEmailsForAttendanceMutation, RegisterDestEmailsForAttendanceMutationVariables>;

/**
 * __useRegisterDestEmailsForAttendanceMutation__
 *
 * To run a mutation, you first call `useRegisterDestEmailsForAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDestEmailsForAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDestEmailsForAttendanceMutation, { data, loading, error }] = useRegisterDestEmailsForAttendanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterDestEmailsForAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<RegisterDestEmailsForAttendanceMutation, RegisterDestEmailsForAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterDestEmailsForAttendanceMutation, RegisterDestEmailsForAttendanceMutationVariables>(RegisterDestEmailsForAttendanceDocument, options);
      }
export type RegisterDestEmailsForAttendanceMutationHookResult = ReturnType<typeof useRegisterDestEmailsForAttendanceMutation>;
export type RegisterDestEmailsForAttendanceMutationResult = Apollo.MutationResult<RegisterDestEmailsForAttendanceMutation>;
export type RegisterDestEmailsForAttendanceMutationOptions = Apollo.BaseMutationOptions<RegisterDestEmailsForAttendanceMutation, RegisterDestEmailsForAttendanceMutationVariables>;
export const BusinessDocument = gql`
    query Business($login: String!) {
  business(login: $login) {
    ...BusinessFields
    shiftRoles(where: {isDeleted: false}) {
      ...ShiftRoleFields
    }
  }
}
    ${BusinessFieldsFragmentDoc}
${ShiftRoleFieldsFragmentDoc}`;

/**
 * __useBusinessQuery__
 *
 * To run a query within a React component, call `useBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useBusinessQuery(baseOptions: Apollo.QueryHookOptions<BusinessQuery, BusinessQueryVariables> & ({ variables: BusinessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
      }
export function useBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
        }
export function useBusinessSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
        }
export type BusinessQueryHookResult = ReturnType<typeof useBusinessQuery>;
export type BusinessLazyQueryHookResult = ReturnType<typeof useBusinessLazyQuery>;
export type BusinessSuspenseQueryHookResult = ReturnType<typeof useBusinessSuspenseQuery>;
export type BusinessQueryResult = Apollo.QueryResult<BusinessQuery, BusinessQueryVariables>;
export const BusinessAiGeneratedShiftScheduleDocument = gql`
    query BusinessAIGeneratedShiftSchedule($businessLogin: String!, $shiftScheduleRule: String!, $since: String!, $until: String!) {
  business(login: $businessLogin) {
    id
    aiGeneratedShiftSchedule(
      scheduleRule: $shiftScheduleRule
      since: $since
      until: $until
    ) {
      id
      memberId
      shiftRoleId
      dateText
      timeSlotId
    }
  }
}
    `;

/**
 * __useBusinessAiGeneratedShiftScheduleQuery__
 *
 * To run a query within a React component, call `useBusinessAiGeneratedShiftScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAiGeneratedShiftScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAiGeneratedShiftScheduleQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      shiftScheduleRule: // value for 'shiftScheduleRule'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useBusinessAiGeneratedShiftScheduleQuery(baseOptions: Apollo.QueryHookOptions<BusinessAiGeneratedShiftScheduleQuery, BusinessAiGeneratedShiftScheduleQueryVariables> & ({ variables: BusinessAiGeneratedShiftScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessAiGeneratedShiftScheduleQuery, BusinessAiGeneratedShiftScheduleQueryVariables>(BusinessAiGeneratedShiftScheduleDocument, options);
      }
export function useBusinessAiGeneratedShiftScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessAiGeneratedShiftScheduleQuery, BusinessAiGeneratedShiftScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessAiGeneratedShiftScheduleQuery, BusinessAiGeneratedShiftScheduleQueryVariables>(BusinessAiGeneratedShiftScheduleDocument, options);
        }
export function useBusinessAiGeneratedShiftScheduleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessAiGeneratedShiftScheduleQuery, BusinessAiGeneratedShiftScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessAiGeneratedShiftScheduleQuery, BusinessAiGeneratedShiftScheduleQueryVariables>(BusinessAiGeneratedShiftScheduleDocument, options);
        }
export type BusinessAiGeneratedShiftScheduleQueryHookResult = ReturnType<typeof useBusinessAiGeneratedShiftScheduleQuery>;
export type BusinessAiGeneratedShiftScheduleLazyQueryHookResult = ReturnType<typeof useBusinessAiGeneratedShiftScheduleLazyQuery>;
export type BusinessAiGeneratedShiftScheduleSuspenseQueryHookResult = ReturnType<typeof useBusinessAiGeneratedShiftScheduleSuspenseQuery>;
export type BusinessAiGeneratedShiftScheduleQueryResult = Apollo.QueryResult<BusinessAiGeneratedShiftScheduleQuery, BusinessAiGeneratedShiftScheduleQueryVariables>;
export const BusinessAssignableShiftsDocument = gql`
    query BusinessAssignableShifts($businessLogin: String!, $since: String!, $until: String!) {
  business(login: $businessLogin) {
    id
    assignableShifts(since: $since, until: $until) {
      ...AssignableShiftField
    }
  }
}
    ${AssignableShiftFieldFragmentDoc}`;

/**
 * __useBusinessAssignableShiftsQuery__
 *
 * To run a query within a React component, call `useBusinessAssignableShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAssignableShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAssignableShiftsQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useBusinessAssignableShiftsQuery(baseOptions: Apollo.QueryHookOptions<BusinessAssignableShiftsQuery, BusinessAssignableShiftsQueryVariables> & ({ variables: BusinessAssignableShiftsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessAssignableShiftsQuery, BusinessAssignableShiftsQueryVariables>(BusinessAssignableShiftsDocument, options);
      }
export function useBusinessAssignableShiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessAssignableShiftsQuery, BusinessAssignableShiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessAssignableShiftsQuery, BusinessAssignableShiftsQueryVariables>(BusinessAssignableShiftsDocument, options);
        }
export function useBusinessAssignableShiftsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessAssignableShiftsQuery, BusinessAssignableShiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessAssignableShiftsQuery, BusinessAssignableShiftsQueryVariables>(BusinessAssignableShiftsDocument, options);
        }
export type BusinessAssignableShiftsQueryHookResult = ReturnType<typeof useBusinessAssignableShiftsQuery>;
export type BusinessAssignableShiftsLazyQueryHookResult = ReturnType<typeof useBusinessAssignableShiftsLazyQuery>;
export type BusinessAssignableShiftsSuspenseQueryHookResult = ReturnType<typeof useBusinessAssignableShiftsSuspenseQuery>;
export type BusinessAssignableShiftsQueryResult = Apollo.QueryResult<BusinessAssignableShiftsQuery, BusinessAssignableShiftsQueryVariables>;
export const BusinessDestEmailsDocument = gql`
    query BusinessDestEmails($login: String!) {
  business(login: $login) {
    id
    destEmails {
      id
      email
    }
  }
}
    `;

/**
 * __useBusinessDestEmailsQuery__
 *
 * To run a query within a React component, call `useBusinessDestEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessDestEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessDestEmailsQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useBusinessDestEmailsQuery(baseOptions: Apollo.QueryHookOptions<BusinessDestEmailsQuery, BusinessDestEmailsQueryVariables> & ({ variables: BusinessDestEmailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessDestEmailsQuery, BusinessDestEmailsQueryVariables>(BusinessDestEmailsDocument, options);
      }
export function useBusinessDestEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessDestEmailsQuery, BusinessDestEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessDestEmailsQuery, BusinessDestEmailsQueryVariables>(BusinessDestEmailsDocument, options);
        }
export function useBusinessDestEmailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessDestEmailsQuery, BusinessDestEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessDestEmailsQuery, BusinessDestEmailsQueryVariables>(BusinessDestEmailsDocument, options);
        }
export type BusinessDestEmailsQueryHookResult = ReturnType<typeof useBusinessDestEmailsQuery>;
export type BusinessDestEmailsLazyQueryHookResult = ReturnType<typeof useBusinessDestEmailsLazyQuery>;
export type BusinessDestEmailsSuspenseQueryHookResult = ReturnType<typeof useBusinessDestEmailsSuspenseQuery>;
export type BusinessDestEmailsQueryResult = Apollo.QueryResult<BusinessDestEmailsQuery, BusinessDestEmailsQueryVariables>;
export const BusinessIntegrationsDocument = gql`
    query BusinessIntegrations($login: String!) {
  business(login: $login) {
    id
    integrations {
      ...IntegrationFields
    }
  }
}
    ${IntegrationFieldsFragmentDoc}`;

/**
 * __useBusinessIntegrationsQuery__
 *
 * To run a query within a React component, call `useBusinessIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessIntegrationsQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useBusinessIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<BusinessIntegrationsQuery, BusinessIntegrationsQueryVariables> & ({ variables: BusinessIntegrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessIntegrationsQuery, BusinessIntegrationsQueryVariables>(BusinessIntegrationsDocument, options);
      }
export function useBusinessIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessIntegrationsQuery, BusinessIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessIntegrationsQuery, BusinessIntegrationsQueryVariables>(BusinessIntegrationsDocument, options);
        }
export function useBusinessIntegrationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessIntegrationsQuery, BusinessIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessIntegrationsQuery, BusinessIntegrationsQueryVariables>(BusinessIntegrationsDocument, options);
        }
export type BusinessIntegrationsQueryHookResult = ReturnType<typeof useBusinessIntegrationsQuery>;
export type BusinessIntegrationsLazyQueryHookResult = ReturnType<typeof useBusinessIntegrationsLazyQuery>;
export type BusinessIntegrationsSuspenseQueryHookResult = ReturnType<typeof useBusinessIntegrationsSuspenseQuery>;
export type BusinessIntegrationsQueryResult = Apollo.QueryResult<BusinessIntegrationsQuery, BusinessIntegrationsQueryVariables>;
export const BusinessMembersDocument = gql`
    query BusinessMembers($businessLogin: String!) {
  business(login: $businessLogin) {
    id
    inviteLink {
      link
    }
    members {
      ...MemberFields
      shiftRoles {
        ...ShiftRoleFields
      }
      user {
        ...UserFields
      }
    }
    inviteLinkRequests {
      id
      name
    }
  }
}
    ${MemberFieldsFragmentDoc}
${ShiftRoleFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

/**
 * __useBusinessMembersQuery__
 *
 * To run a query within a React component, call `useBusinessMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessMembersQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *   },
 * });
 */
export function useBusinessMembersQuery(baseOptions: Apollo.QueryHookOptions<BusinessMembersQuery, BusinessMembersQueryVariables> & ({ variables: BusinessMembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessMembersQuery, BusinessMembersQueryVariables>(BusinessMembersDocument, options);
      }
export function useBusinessMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessMembersQuery, BusinessMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessMembersQuery, BusinessMembersQueryVariables>(BusinessMembersDocument, options);
        }
export function useBusinessMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessMembersQuery, BusinessMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessMembersQuery, BusinessMembersQueryVariables>(BusinessMembersDocument, options);
        }
export type BusinessMembersQueryHookResult = ReturnType<typeof useBusinessMembersQuery>;
export type BusinessMembersLazyQueryHookResult = ReturnType<typeof useBusinessMembersLazyQuery>;
export type BusinessMembersSuspenseQueryHookResult = ReturnType<typeof useBusinessMembersSuspenseQuery>;
export type BusinessMembersQueryResult = Apollo.QueryResult<BusinessMembersQuery, BusinessMembersQueryVariables>;
export const BusinessShiftRequestsDocument = gql`
    query BusinessShiftRequests($businessLogin: String!, $since: String!, $until: String!) {
  business(login: $businessLogin) {
    id
    shiftRequests(since: $since, until: $until) {
      ...ShiftRequestFields
    }
  }
}
    ${ShiftRequestFieldsFragmentDoc}`;

/**
 * __useBusinessShiftRequestsQuery__
 *
 * To run a query within a React component, call `useBusinessShiftRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessShiftRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessShiftRequestsQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useBusinessShiftRequestsQuery(baseOptions: Apollo.QueryHookOptions<BusinessShiftRequestsQuery, BusinessShiftRequestsQueryVariables> & ({ variables: BusinessShiftRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessShiftRequestsQuery, BusinessShiftRequestsQueryVariables>(BusinessShiftRequestsDocument, options);
      }
export function useBusinessShiftRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessShiftRequestsQuery, BusinessShiftRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessShiftRequestsQuery, BusinessShiftRequestsQueryVariables>(BusinessShiftRequestsDocument, options);
        }
export function useBusinessShiftRequestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessShiftRequestsQuery, BusinessShiftRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessShiftRequestsQuery, BusinessShiftRequestsQueryVariables>(BusinessShiftRequestsDocument, options);
        }
export type BusinessShiftRequestsQueryHookResult = ReturnType<typeof useBusinessShiftRequestsQuery>;
export type BusinessShiftRequestsLazyQueryHookResult = ReturnType<typeof useBusinessShiftRequestsLazyQuery>;
export type BusinessShiftRequestsSuspenseQueryHookResult = ReturnType<typeof useBusinessShiftRequestsSuspenseQuery>;
export type BusinessShiftRequestsQueryResult = Apollo.QueryResult<BusinessShiftRequestsQuery, BusinessShiftRequestsQueryVariables>;
export const BusinessShiftRolesDocument = gql`
    query BusinessShiftRoles($businessLogin: String!) {
  business(login: $businessLogin) {
    id
    shiftRoles {
      ...ShiftRoleFields
    }
  }
}
    ${ShiftRoleFieldsFragmentDoc}`;

/**
 * __useBusinessShiftRolesQuery__
 *
 * To run a query within a React component, call `useBusinessShiftRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessShiftRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessShiftRolesQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *   },
 * });
 */
export function useBusinessShiftRolesQuery(baseOptions: Apollo.QueryHookOptions<BusinessShiftRolesQuery, BusinessShiftRolesQueryVariables> & ({ variables: BusinessShiftRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessShiftRolesQuery, BusinessShiftRolesQueryVariables>(BusinessShiftRolesDocument, options);
      }
export function useBusinessShiftRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessShiftRolesQuery, BusinessShiftRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessShiftRolesQuery, BusinessShiftRolesQueryVariables>(BusinessShiftRolesDocument, options);
        }
export function useBusinessShiftRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessShiftRolesQuery, BusinessShiftRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessShiftRolesQuery, BusinessShiftRolesQueryVariables>(BusinessShiftRolesDocument, options);
        }
export type BusinessShiftRolesQueryHookResult = ReturnType<typeof useBusinessShiftRolesQuery>;
export type BusinessShiftRolesLazyQueryHookResult = ReturnType<typeof useBusinessShiftRolesLazyQuery>;
export type BusinessShiftRolesSuspenseQueryHookResult = ReturnType<typeof useBusinessShiftRolesSuspenseQuery>;
export type BusinessShiftRolesQueryResult = Apollo.QueryResult<BusinessShiftRolesQuery, BusinessShiftRolesQueryVariables>;
export const BusinessShiftScheduleDocument = gql`
    query BusinessShiftSchedule($businessLogin: String!, $since: String!, $until: String!) {
  business(login: $businessLogin) {
    id
    shiftSchedule(since: $since, until: $until) {
      ...ShiftScheduleFields
    }
  }
}
    ${ShiftScheduleFieldsFragmentDoc}`;

/**
 * __useBusinessShiftScheduleQuery__
 *
 * To run a query within a React component, call `useBusinessShiftScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessShiftScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessShiftScheduleQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useBusinessShiftScheduleQuery(baseOptions: Apollo.QueryHookOptions<BusinessShiftScheduleQuery, BusinessShiftScheduleQueryVariables> & ({ variables: BusinessShiftScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessShiftScheduleQuery, BusinessShiftScheduleQueryVariables>(BusinessShiftScheduleDocument, options);
      }
export function useBusinessShiftScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessShiftScheduleQuery, BusinessShiftScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessShiftScheduleQuery, BusinessShiftScheduleQueryVariables>(BusinessShiftScheduleDocument, options);
        }
export function useBusinessShiftScheduleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessShiftScheduleQuery, BusinessShiftScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessShiftScheduleQuery, BusinessShiftScheduleQueryVariables>(BusinessShiftScheduleDocument, options);
        }
export type BusinessShiftScheduleQueryHookResult = ReturnType<typeof useBusinessShiftScheduleQuery>;
export type BusinessShiftScheduleLazyQueryHookResult = ReturnType<typeof useBusinessShiftScheduleLazyQuery>;
export type BusinessShiftScheduleSuspenseQueryHookResult = ReturnType<typeof useBusinessShiftScheduleSuspenseQuery>;
export type BusinessShiftScheduleQueryResult = Apollo.QueryResult<BusinessShiftScheduleQuery, BusinessShiftScheduleQueryVariables>;
export const BusinessShiftScheduleRequestsDocument = gql`
    query BusinessShiftScheduleRequests($businessLogin: String!, $since: String!, $until: String!) {
  business(login: $businessLogin) {
    id
    shiftScheduleRequests(since: $since, until: $until) {
      ...ShiftScheduleRequestFields
    }
  }
}
    ${ShiftScheduleRequestFieldsFragmentDoc}`;

/**
 * __useBusinessShiftScheduleRequestsQuery__
 *
 * To run a query within a React component, call `useBusinessShiftScheduleRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessShiftScheduleRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessShiftScheduleRequestsQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useBusinessShiftScheduleRequestsQuery(baseOptions: Apollo.QueryHookOptions<BusinessShiftScheduleRequestsQuery, BusinessShiftScheduleRequestsQueryVariables> & ({ variables: BusinessShiftScheduleRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessShiftScheduleRequestsQuery, BusinessShiftScheduleRequestsQueryVariables>(BusinessShiftScheduleRequestsDocument, options);
      }
export function useBusinessShiftScheduleRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessShiftScheduleRequestsQuery, BusinessShiftScheduleRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessShiftScheduleRequestsQuery, BusinessShiftScheduleRequestsQueryVariables>(BusinessShiftScheduleRequestsDocument, options);
        }
export function useBusinessShiftScheduleRequestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessShiftScheduleRequestsQuery, BusinessShiftScheduleRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessShiftScheduleRequestsQuery, BusinessShiftScheduleRequestsQueryVariables>(BusinessShiftScheduleRequestsDocument, options);
        }
export type BusinessShiftScheduleRequestsQueryHookResult = ReturnType<typeof useBusinessShiftScheduleRequestsQuery>;
export type BusinessShiftScheduleRequestsLazyQueryHookResult = ReturnType<typeof useBusinessShiftScheduleRequestsLazyQuery>;
export type BusinessShiftScheduleRequestsSuspenseQueryHookResult = ReturnType<typeof useBusinessShiftScheduleRequestsSuspenseQuery>;
export type BusinessShiftScheduleRequestsQueryResult = Apollo.QueryResult<BusinessShiftScheduleRequestsQuery, BusinessShiftScheduleRequestsQueryVariables>;
export const BusinessShiftsDocument = gql`
    query BusinessShifts($login: String!) {
  business(login: $login) {
    id
    shifts {
      ...ShiftFields
    }
  }
}
    ${ShiftFieldsFragmentDoc}`;

/**
 * __useBusinessShiftsQuery__
 *
 * To run a query within a React component, call `useBusinessShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessShiftsQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useBusinessShiftsQuery(baseOptions: Apollo.QueryHookOptions<BusinessShiftsQuery, BusinessShiftsQueryVariables> & ({ variables: BusinessShiftsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessShiftsQuery, BusinessShiftsQueryVariables>(BusinessShiftsDocument, options);
      }
export function useBusinessShiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessShiftsQuery, BusinessShiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessShiftsQuery, BusinessShiftsQueryVariables>(BusinessShiftsDocument, options);
        }
export function useBusinessShiftsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessShiftsQuery, BusinessShiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessShiftsQuery, BusinessShiftsQueryVariables>(BusinessShiftsDocument, options);
        }
export type BusinessShiftsQueryHookResult = ReturnType<typeof useBusinessShiftsQuery>;
export type BusinessShiftsLazyQueryHookResult = ReturnType<typeof useBusinessShiftsLazyQuery>;
export type BusinessShiftsSuspenseQueryHookResult = ReturnType<typeof useBusinessShiftsSuspenseQuery>;
export type BusinessShiftsQueryResult = Apollo.QueryResult<BusinessShiftsQuery, BusinessShiftsQueryVariables>;
export const BusinessTimeRecordsDocument = gql`
    query BusinessTimeRecords($businessLogin: String!, $since: String!, $until: String!) {
  business(login: $businessLogin) {
    id
    truncatedRange
    timeRecords(since: $since, until: $until) {
      ...TimeRecordFields
    }
  }
}
    ${TimeRecordFieldsFragmentDoc}`;

/**
 * __useBusinessTimeRecordsQuery__
 *
 * To run a query within a React component, call `useBusinessTimeRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessTimeRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessTimeRecordsQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useBusinessTimeRecordsQuery(baseOptions: Apollo.QueryHookOptions<BusinessTimeRecordsQuery, BusinessTimeRecordsQueryVariables> & ({ variables: BusinessTimeRecordsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessTimeRecordsQuery, BusinessTimeRecordsQueryVariables>(BusinessTimeRecordsDocument, options);
      }
export function useBusinessTimeRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessTimeRecordsQuery, BusinessTimeRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessTimeRecordsQuery, BusinessTimeRecordsQueryVariables>(BusinessTimeRecordsDocument, options);
        }
export function useBusinessTimeRecordsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessTimeRecordsQuery, BusinessTimeRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessTimeRecordsQuery, BusinessTimeRecordsQueryVariables>(BusinessTimeRecordsDocument, options);
        }
export type BusinessTimeRecordsQueryHookResult = ReturnType<typeof useBusinessTimeRecordsQuery>;
export type BusinessTimeRecordsLazyQueryHookResult = ReturnType<typeof useBusinessTimeRecordsLazyQuery>;
export type BusinessTimeRecordsSuspenseQueryHookResult = ReturnType<typeof useBusinessTimeRecordsSuspenseQuery>;
export type BusinessTimeRecordsQueryResult = Apollo.QueryResult<BusinessTimeRecordsQuery, BusinessTimeRecordsQueryVariables>;
export const BusinessTimeSlotsDocument = gql`
    query BusinessTimeSlots($businessLogin: String!) {
  business(login: $businessLogin) {
    id
    timeSlots {
      ...TimeSlotFields
    }
  }
}
    ${TimeSlotFieldsFragmentDoc}`;

/**
 * __useBusinessTimeSlotsQuery__
 *
 * To run a query within a React component, call `useBusinessTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessTimeSlotsQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *   },
 * });
 */
export function useBusinessTimeSlotsQuery(baseOptions: Apollo.QueryHookOptions<BusinessTimeSlotsQuery, BusinessTimeSlotsQueryVariables> & ({ variables: BusinessTimeSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessTimeSlotsQuery, BusinessTimeSlotsQueryVariables>(BusinessTimeSlotsDocument, options);
      }
export function useBusinessTimeSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessTimeSlotsQuery, BusinessTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessTimeSlotsQuery, BusinessTimeSlotsQueryVariables>(BusinessTimeSlotsDocument, options);
        }
export function useBusinessTimeSlotsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessTimeSlotsQuery, BusinessTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessTimeSlotsQuery, BusinessTimeSlotsQueryVariables>(BusinessTimeSlotsDocument, options);
        }
export type BusinessTimeSlotsQueryHookResult = ReturnType<typeof useBusinessTimeSlotsQuery>;
export type BusinessTimeSlotsLazyQueryHookResult = ReturnType<typeof useBusinessTimeSlotsLazyQuery>;
export type BusinessTimeSlotsSuspenseQueryHookResult = ReturnType<typeof useBusinessTimeSlotsSuspenseQuery>;
export type BusinessTimeSlotsQueryResult = Apollo.QueryResult<BusinessTimeSlotsQuery, BusinessTimeSlotsQueryVariables>;
export const CreateBusinessDocument = gql`
    mutation CreateBusiness($input: CreateBusinessInput!) {
  createBusiness(input: $input) {
    id
  }
}
    `;
export type CreateBusinessMutationFn = Apollo.MutationFunction<CreateBusinessMutation, CreateBusinessMutationVariables>;

/**
 * __useCreateBusinessMutation__
 *
 * To run a mutation, you first call `useCreateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessMutation, { data, loading, error }] = useCreateBusinessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBusinessMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessMutation, CreateBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessMutation, CreateBusinessMutationVariables>(CreateBusinessDocument, options);
      }
export type CreateBusinessMutationHookResult = ReturnType<typeof useCreateBusinessMutation>;
export type CreateBusinessMutationResult = Apollo.MutationResult<CreateBusinessMutation>;
export type CreateBusinessMutationOptions = Apollo.BaseMutationOptions<CreateBusinessMutation, CreateBusinessMutationVariables>;
export const UpdateBusinessDocument = gql`
    mutation UpdateBusiness($input: UpdateBusinessInput!) {
  updateBusiness(input: $input) {
    id
  }
}
    `;
export type UpdateBusinessMutationFn = Apollo.MutationFunction<UpdateBusinessMutation, UpdateBusinessMutationVariables>;

/**
 * __useUpdateBusinessMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessMutation, { data, loading, error }] = useUpdateBusinessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessMutation, UpdateBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessMutation, UpdateBusinessMutationVariables>(UpdateBusinessDocument, options);
      }
export type UpdateBusinessMutationHookResult = ReturnType<typeof useUpdateBusinessMutation>;
export type UpdateBusinessMutationResult = Apollo.MutationResult<UpdateBusinessMutation>;
export type UpdateBusinessMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessMutation, UpdateBusinessMutationVariables>;
export const UpdateBusinessScheduleRuleDocument = gql`
    mutation UpdateBusinessScheduleRule($input: UpdateBusinessScheduleRuleInput!) {
  updateBusinessScheduleRule(input: $input) {
    id
  }
}
    `;
export type UpdateBusinessScheduleRuleMutationFn = Apollo.MutationFunction<UpdateBusinessScheduleRuleMutation, UpdateBusinessScheduleRuleMutationVariables>;

/**
 * __useUpdateBusinessScheduleRuleMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessScheduleRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessScheduleRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessScheduleRuleMutation, { data, loading, error }] = useUpdateBusinessScheduleRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessScheduleRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessScheduleRuleMutation, UpdateBusinessScheduleRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessScheduleRuleMutation, UpdateBusinessScheduleRuleMutationVariables>(UpdateBusinessScheduleRuleDocument, options);
      }
export type UpdateBusinessScheduleRuleMutationHookResult = ReturnType<typeof useUpdateBusinessScheduleRuleMutation>;
export type UpdateBusinessScheduleRuleMutationResult = Apollo.MutationResult<UpdateBusinessScheduleRuleMutation>;
export type UpdateBusinessScheduleRuleMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessScheduleRuleMutation, UpdateBusinessScheduleRuleMutationVariables>;
export const DeleteDefaultShiftDocument = gql`
    mutation DeleteDefaultShift($input: DeleteDefaultShiftInput!) {
  deleteDefaultShift(input: $input) {
    id
  }
}
    `;
export type DeleteDefaultShiftMutationFn = Apollo.MutationFunction<DeleteDefaultShiftMutation, DeleteDefaultShiftMutationVariables>;

/**
 * __useDeleteDefaultShiftMutation__
 *
 * To run a mutation, you first call `useDeleteDefaultShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDefaultShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDefaultShiftMutation, { data, loading, error }] = useDeleteDefaultShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDefaultShiftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDefaultShiftMutation, DeleteDefaultShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDefaultShiftMutation, DeleteDefaultShiftMutationVariables>(DeleteDefaultShiftDocument, options);
      }
export type DeleteDefaultShiftMutationHookResult = ReturnType<typeof useDeleteDefaultShiftMutation>;
export type DeleteDefaultShiftMutationResult = Apollo.MutationResult<DeleteDefaultShiftMutation>;
export type DeleteDefaultShiftMutationOptions = Apollo.BaseMutationOptions<DeleteDefaultShiftMutation, DeleteDefaultShiftMutationVariables>;
export const UpdateDefaultShiftDocument = gql`
    mutation UpdateDefaultShift($input: UpdateDefaultShiftInput!) {
  updateDefaultShift(input: $input) {
    id
  }
}
    `;
export type UpdateDefaultShiftMutationFn = Apollo.MutationFunction<UpdateDefaultShiftMutation, UpdateDefaultShiftMutationVariables>;

/**
 * __useUpdateDefaultShiftMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultShiftMutation, { data, loading, error }] = useUpdateDefaultShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefaultShiftMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultShiftMutation, UpdateDefaultShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultShiftMutation, UpdateDefaultShiftMutationVariables>(UpdateDefaultShiftDocument, options);
      }
export type UpdateDefaultShiftMutationHookResult = ReturnType<typeof useUpdateDefaultShiftMutation>;
export type UpdateDefaultShiftMutationResult = Apollo.MutationResult<UpdateDefaultShiftMutation>;
export type UpdateDefaultShiftMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultShiftMutation, UpdateDefaultShiftMutationVariables>;
export const AddIntegrationLineDocument = gql`
    mutation AddIntegrationLine($input: AddIntegrationLineInput!) {
  addIntegrationLine(input: $input) {
    id
  }
}
    `;
export type AddIntegrationLineMutationFn = Apollo.MutationFunction<AddIntegrationLineMutation, AddIntegrationLineMutationVariables>;

/**
 * __useAddIntegrationLineMutation__
 *
 * To run a mutation, you first call `useAddIntegrationLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationLineMutation, { data, loading, error }] = useAddIntegrationLineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIntegrationLineMutation(baseOptions?: Apollo.MutationHookOptions<AddIntegrationLineMutation, AddIntegrationLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddIntegrationLineMutation, AddIntegrationLineMutationVariables>(AddIntegrationLineDocument, options);
      }
export type AddIntegrationLineMutationHookResult = ReturnType<typeof useAddIntegrationLineMutation>;
export type AddIntegrationLineMutationResult = Apollo.MutationResult<AddIntegrationLineMutation>;
export type AddIntegrationLineMutationOptions = Apollo.BaseMutationOptions<AddIntegrationLineMutation, AddIntegrationLineMutationVariables>;
export const DeleteIntegrationDocument = gql`
    mutation DeleteIntegration($input: DeleteIntegrationInput!) {
  deleteIntegration(input: $input) {
    id
  }
}
    `;
export type DeleteIntegrationMutationFn = Apollo.MutationFunction<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>(DeleteIntegrationDocument, options);
      }
export type DeleteIntegrationMutationHookResult = ReturnType<typeof useDeleteIntegrationMutation>;
export type DeleteIntegrationMutationResult = Apollo.MutationResult<DeleteIntegrationMutation>;
export type DeleteIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;
export const ApproveInviteLinkRequestDocument = gql`
    mutation approveInviteLinkRequest($input: ApproveInviteLinkRequestInput!) {
  approveInviteLinkRequest(input: $input) {
    id
  }
}
    `;
export type ApproveInviteLinkRequestMutationFn = Apollo.MutationFunction<ApproveInviteLinkRequestMutation, ApproveInviteLinkRequestMutationVariables>;

/**
 * __useApproveInviteLinkRequestMutation__
 *
 * To run a mutation, you first call `useApproveInviteLinkRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveInviteLinkRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveInviteLinkRequestMutation, { data, loading, error }] = useApproveInviteLinkRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveInviteLinkRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveInviteLinkRequestMutation, ApproveInviteLinkRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveInviteLinkRequestMutation, ApproveInviteLinkRequestMutationVariables>(ApproveInviteLinkRequestDocument, options);
      }
export type ApproveInviteLinkRequestMutationHookResult = ReturnType<typeof useApproveInviteLinkRequestMutation>;
export type ApproveInviteLinkRequestMutationResult = Apollo.MutationResult<ApproveInviteLinkRequestMutation>;
export type ApproveInviteLinkRequestMutationOptions = Apollo.BaseMutationOptions<ApproveInviteLinkRequestMutation, ApproveInviteLinkRequestMutationVariables>;
export const ApproveInviteRequestDocument = gql`
    mutation approveInviteRequest($input: ApproveInviteRequestInput!) {
  approveInviteRequest(input: $input) {
    id
  }
}
    `;
export type ApproveInviteRequestMutationFn = Apollo.MutationFunction<ApproveInviteRequestMutation, ApproveInviteRequestMutationVariables>;

/**
 * __useApproveInviteRequestMutation__
 *
 * To run a mutation, you first call `useApproveInviteRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveInviteRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveInviteRequestMutation, { data, loading, error }] = useApproveInviteRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveInviteRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveInviteRequestMutation, ApproveInviteRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveInviteRequestMutation, ApproveInviteRequestMutationVariables>(ApproveInviteRequestDocument, options);
      }
export type ApproveInviteRequestMutationHookResult = ReturnType<typeof useApproveInviteRequestMutation>;
export type ApproveInviteRequestMutationResult = Apollo.MutationResult<ApproveInviteRequestMutation>;
export type ApproveInviteRequestMutationOptions = Apollo.BaseMutationOptions<ApproveInviteRequestMutation, ApproveInviteRequestMutationVariables>;
export const CreateInviteLinkDocument = gql`
    mutation createInviteLink($input: CreateInviteLinkInput!) {
  createInviteLink(input: $input) {
    id
  }
}
    `;
export type CreateInviteLinkMutationFn = Apollo.MutationFunction<CreateInviteLinkMutation, CreateInviteLinkMutationVariables>;

/**
 * __useCreateInviteLinkMutation__
 *
 * To run a mutation, you first call `useCreateInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteLinkMutation, { data, loading, error }] = useCreateInviteLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateInviteLinkMutation, CreateInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInviteLinkMutation, CreateInviteLinkMutationVariables>(CreateInviteLinkDocument, options);
      }
export type CreateInviteLinkMutationHookResult = ReturnType<typeof useCreateInviteLinkMutation>;
export type CreateInviteLinkMutationResult = Apollo.MutationResult<CreateInviteLinkMutation>;
export type CreateInviteLinkMutationOptions = Apollo.BaseMutationOptions<CreateInviteLinkMutation, CreateInviteLinkMutationVariables>;
export const CreateInviteLinkRequestDocument = gql`
    mutation createInviteLinkRequest($input: CreateInviteLinkRequestInput!) {
  createInviteLinkRequest(input: $input) {
    id
  }
}
    `;
export type CreateInviteLinkRequestMutationFn = Apollo.MutationFunction<CreateInviteLinkRequestMutation, CreateInviteLinkRequestMutationVariables>;

/**
 * __useCreateInviteLinkRequestMutation__
 *
 * To run a mutation, you first call `useCreateInviteLinkRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteLinkRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteLinkRequestMutation, { data, loading, error }] = useCreateInviteLinkRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteLinkRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateInviteLinkRequestMutation, CreateInviteLinkRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInviteLinkRequestMutation, CreateInviteLinkRequestMutationVariables>(CreateInviteLinkRequestDocument, options);
      }
export type CreateInviteLinkRequestMutationHookResult = ReturnType<typeof useCreateInviteLinkRequestMutation>;
export type CreateInviteLinkRequestMutationResult = Apollo.MutationResult<CreateInviteLinkRequestMutation>;
export type CreateInviteLinkRequestMutationOptions = Apollo.BaseMutationOptions<CreateInviteLinkRequestMutation, CreateInviteLinkRequestMutationVariables>;
export const InviteLinkDocument = gql`
    query InviteLink($code: String!) {
  inviteLink(code: $code) {
    id
    business {
      name
    }
  }
}
    `;

/**
 * __useInviteLinkQuery__
 *
 * To run a query within a React component, call `useInviteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteLinkQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useInviteLinkQuery(baseOptions: Apollo.QueryHookOptions<InviteLinkQuery, InviteLinkQueryVariables> & ({ variables: InviteLinkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteLinkQuery, InviteLinkQueryVariables>(InviteLinkDocument, options);
      }
export function useInviteLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteLinkQuery, InviteLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteLinkQuery, InviteLinkQueryVariables>(InviteLinkDocument, options);
        }
export function useInviteLinkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InviteLinkQuery, InviteLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InviteLinkQuery, InviteLinkQueryVariables>(InviteLinkDocument, options);
        }
export type InviteLinkQueryHookResult = ReturnType<typeof useInviteLinkQuery>;
export type InviteLinkLazyQueryHookResult = ReturnType<typeof useInviteLinkLazyQuery>;
export type InviteLinkSuspenseQueryHookResult = ReturnType<typeof useInviteLinkSuspenseQuery>;
export type InviteLinkQueryResult = Apollo.QueryResult<InviteLinkQuery, InviteLinkQueryVariables>;
export const InviteRequestDocument = gql`
    query InviteRequest($inviteRequestId: String!) {
  inviteRequest(inviteRequestId: $inviteRequestId) {
    id
    email
    business {
      id
      name
    }
    userExists
  }
}
    `;

/**
 * __useInviteRequestQuery__
 *
 * To run a query within a React component, call `useInviteRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteRequestQuery({
 *   variables: {
 *      inviteRequestId: // value for 'inviteRequestId'
 *   },
 * });
 */
export function useInviteRequestQuery(baseOptions: Apollo.QueryHookOptions<InviteRequestQuery, InviteRequestQueryVariables> & ({ variables: InviteRequestQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteRequestQuery, InviteRequestQueryVariables>(InviteRequestDocument, options);
      }
export function useInviteRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteRequestQuery, InviteRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteRequestQuery, InviteRequestQueryVariables>(InviteRequestDocument, options);
        }
export function useInviteRequestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InviteRequestQuery, InviteRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InviteRequestQuery, InviteRequestQueryVariables>(InviteRequestDocument, options);
        }
export type InviteRequestQueryHookResult = ReturnType<typeof useInviteRequestQuery>;
export type InviteRequestLazyQueryHookResult = ReturnType<typeof useInviteRequestLazyQuery>;
export type InviteRequestSuspenseQueryHookResult = ReturnType<typeof useInviteRequestSuspenseQuery>;
export type InviteRequestQueryResult = Apollo.QueryResult<InviteRequestQuery, InviteRequestQueryVariables>;
export const JoinByInviteLinkDocument = gql`
    mutation joinByInviteLink($input: JoinByInviteLinkInput!) {
  joinByInviteLink(input: $input) {
    id
  }
}
    `;
export type JoinByInviteLinkMutationFn = Apollo.MutationFunction<JoinByInviteLinkMutation, JoinByInviteLinkMutationVariables>;

/**
 * __useJoinByInviteLinkMutation__
 *
 * To run a mutation, you first call `useJoinByInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinByInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinByInviteLinkMutation, { data, loading, error }] = useJoinByInviteLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinByInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<JoinByInviteLinkMutation, JoinByInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinByInviteLinkMutation, JoinByInviteLinkMutationVariables>(JoinByInviteLinkDocument, options);
      }
export type JoinByInviteLinkMutationHookResult = ReturnType<typeof useJoinByInviteLinkMutation>;
export type JoinByInviteLinkMutationResult = Apollo.MutationResult<JoinByInviteLinkMutation>;
export type JoinByInviteLinkMutationOptions = Apollo.BaseMutationOptions<JoinByInviteLinkMutation, JoinByInviteLinkMutationVariables>;
export const JoinByInviteLinkWithCreateUserDocument = gql`
    mutation joinByInviteLinkWithCreateUser($input: JoinByInviteLinkWithCreateUserInput!) {
  joinByInviteLinkWithCreateUser(input: $input) {
    id
  }
}
    `;
export type JoinByInviteLinkWithCreateUserMutationFn = Apollo.MutationFunction<JoinByInviteLinkWithCreateUserMutation, JoinByInviteLinkWithCreateUserMutationVariables>;

/**
 * __useJoinByInviteLinkWithCreateUserMutation__
 *
 * To run a mutation, you first call `useJoinByInviteLinkWithCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinByInviteLinkWithCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinByInviteLinkWithCreateUserMutation, { data, loading, error }] = useJoinByInviteLinkWithCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinByInviteLinkWithCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<JoinByInviteLinkWithCreateUserMutation, JoinByInviteLinkWithCreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinByInviteLinkWithCreateUserMutation, JoinByInviteLinkWithCreateUserMutationVariables>(JoinByInviteLinkWithCreateUserDocument, options);
      }
export type JoinByInviteLinkWithCreateUserMutationHookResult = ReturnType<typeof useJoinByInviteLinkWithCreateUserMutation>;
export type JoinByInviteLinkWithCreateUserMutationResult = Apollo.MutationResult<JoinByInviteLinkWithCreateUserMutation>;
export type JoinByInviteLinkWithCreateUserMutationOptions = Apollo.BaseMutationOptions<JoinByInviteLinkWithCreateUserMutation, JoinByInviteLinkWithCreateUserMutationVariables>;
export const JoinByInviteRequestWithCreateUserDocument = gql`
    mutation joinByInviteRequestWithCreateUser($input: JoinByInviteRequestWithCreateUserInput!) {
  joinByInviteRequestWithCreateUser(input: $input) {
    id
  }
}
    `;
export type JoinByInviteRequestWithCreateUserMutationFn = Apollo.MutationFunction<JoinByInviteRequestWithCreateUserMutation, JoinByInviteRequestWithCreateUserMutationVariables>;

/**
 * __useJoinByInviteRequestWithCreateUserMutation__
 *
 * To run a mutation, you first call `useJoinByInviteRequestWithCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinByInviteRequestWithCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinByInviteRequestWithCreateUserMutation, { data, loading, error }] = useJoinByInviteRequestWithCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinByInviteRequestWithCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<JoinByInviteRequestWithCreateUserMutation, JoinByInviteRequestWithCreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinByInviteRequestWithCreateUserMutation, JoinByInviteRequestWithCreateUserMutationVariables>(JoinByInviteRequestWithCreateUserDocument, options);
      }
export type JoinByInviteRequestWithCreateUserMutationHookResult = ReturnType<typeof useJoinByInviteRequestWithCreateUserMutation>;
export type JoinByInviteRequestWithCreateUserMutationResult = Apollo.MutationResult<JoinByInviteRequestWithCreateUserMutation>;
export type JoinByInviteRequestWithCreateUserMutationOptions = Apollo.BaseMutationOptions<JoinByInviteRequestWithCreateUserMutation, JoinByInviteRequestWithCreateUserMutationVariables>;
export const CreateMemberDocument = gql`
    mutation CreateMember($input: CreateMemberInput!) {
  createMember(input: $input) {
    id
  }
}
    `;
export type CreateMemberMutationFn = Apollo.MutationFunction<CreateMemberMutation, CreateMemberMutationVariables>;

/**
 * __useCreateMemberMutation__
 *
 * To run a mutation, you first call `useCreateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberMutation, { data, loading, error }] = useCreateMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberMutation, CreateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMemberMutation, CreateMemberMutationVariables>(CreateMemberDocument, options);
      }
export type CreateMemberMutationHookResult = ReturnType<typeof useCreateMemberMutation>;
export type CreateMemberMutationResult = Apollo.MutationResult<CreateMemberMutation>;
export type CreateMemberMutationOptions = Apollo.BaseMutationOptions<CreateMemberMutation, CreateMemberMutationVariables>;
export const DeleteMemberDocument = gql`
    mutation DeleteMember($input: DeleteMemberInput!) {
  deleteMember(input: $input) {
    id
  }
}
    `;
export type DeleteMemberMutationFn = Apollo.MutationFunction<DeleteMemberMutation, DeleteMemberMutationVariables>;

/**
 * __useDeleteMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberMutation, { data, loading, error }] = useDeleteMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMemberMutation, DeleteMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, options);
      }
export type DeleteMemberMutationHookResult = ReturnType<typeof useDeleteMemberMutation>;
export type DeleteMemberMutationResult = Apollo.MutationResult<DeleteMemberMutation>;
export type DeleteMemberMutationOptions = Apollo.BaseMutationOptions<DeleteMemberMutation, DeleteMemberMutationVariables>;
export const MemberDocument = gql`
    query Member($businessLogin: String!, $checkinCode: String!) {
  member(businessLogin: $businessLogin, checkinCode: $checkinCode) {
    id
    name
    checkinCode
    role
    sort
    note
    label
    isArchived
    isDeleted
    timeRecords {
      ...TimeRecordFields
    }
  }
}
    ${TimeRecordFieldsFragmentDoc}`;

/**
 * __useMemberQuery__
 *
 * To run a query within a React component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      checkinCode: // value for 'checkinCode'
 *   },
 * });
 */
export function useMemberQuery(baseOptions: Apollo.QueryHookOptions<MemberQuery, MemberQueryVariables> & ({ variables: MemberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
      }
export function useMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberQuery, MemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
        }
export function useMemberSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MemberQuery, MemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
        }
export type MemberQueryHookResult = ReturnType<typeof useMemberQuery>;
export type MemberLazyQueryHookResult = ReturnType<typeof useMemberLazyQuery>;
export type MemberSuspenseQueryHookResult = ReturnType<typeof useMemberSuspenseQuery>;
export type MemberQueryResult = Apollo.QueryResult<MemberQuery, MemberQueryVariables>;
export const RestoreMemberDocument = gql`
    mutation RestoreMember($input: RestoreMemberInput!) {
  restoreMember(input: $input) {
    id
  }
}
    `;
export type RestoreMemberMutationFn = Apollo.MutationFunction<RestoreMemberMutation, RestoreMemberMutationVariables>;

/**
 * __useRestoreMemberMutation__
 *
 * To run a mutation, you first call `useRestoreMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMemberMutation, { data, loading, error }] = useRestoreMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreMemberMutation(baseOptions?: Apollo.MutationHookOptions<RestoreMemberMutation, RestoreMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreMemberMutation, RestoreMemberMutationVariables>(RestoreMemberDocument, options);
      }
export type RestoreMemberMutationHookResult = ReturnType<typeof useRestoreMemberMutation>;
export type RestoreMemberMutationResult = Apollo.MutationResult<RestoreMemberMutation>;
export type RestoreMemberMutationOptions = Apollo.BaseMutationOptions<RestoreMemberMutation, RestoreMemberMutationVariables>;
export const UpdateMemberDocument = gql`
    mutation UpdateMember($input: UpdateMemberInput!) {
  updateMember(input: $input) {
    id
  }
}
    `;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, options);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;
export const UpdateMemberRoleDocument = gql`
    mutation UpdateMemberRole($input: UpdateMemberRoleInput!) {
  updateMemberRole(input: $input) {
    id
  }
}
    `;
export type UpdateMemberRoleMutationFn = Apollo.MutationFunction<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>;

/**
 * __useUpdateMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberRoleMutation, { data, loading, error }] = useUpdateMemberRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>(UpdateMemberRoleDocument, options);
      }
export type UpdateMemberRoleMutationHookResult = ReturnType<typeof useUpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationResult = Apollo.MutationResult<UpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationOptions = Apollo.BaseMutationOptions<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>;
export const UpdateNextWeekShiftDocument = gql`
    mutation UpdateNextWeekShift($input: UpdateNextWeekShiftInput!) {
  updateNextWeekShift(input: $input)
}
    `;
export type UpdateNextWeekShiftMutationFn = Apollo.MutationFunction<UpdateNextWeekShiftMutation, UpdateNextWeekShiftMutationVariables>;

/**
 * __useUpdateNextWeekShiftMutation__
 *
 * To run a mutation, you first call `useUpdateNextWeekShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNextWeekShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNextWeekShiftMutation, { data, loading, error }] = useUpdateNextWeekShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNextWeekShiftMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNextWeekShiftMutation, UpdateNextWeekShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNextWeekShiftMutation, UpdateNextWeekShiftMutationVariables>(UpdateNextWeekShiftDocument, options);
      }
export type UpdateNextWeekShiftMutationHookResult = ReturnType<typeof useUpdateNextWeekShiftMutation>;
export type UpdateNextWeekShiftMutationResult = Apollo.MutationResult<UpdateNextWeekShiftMutation>;
export type UpdateNextWeekShiftMutationOptions = Apollo.BaseMutationOptions<UpdateNextWeekShiftMutation, UpdateNextWeekShiftMutationVariables>;
export const DeleteShiftDocument = gql`
    mutation DeleteShift($input: DeleteShiftInput!) {
  deleteShift(input: $input) {
    id
  }
}
    `;
export type DeleteShiftMutationFn = Apollo.MutationFunction<DeleteShiftMutation, DeleteShiftMutationVariables>;

/**
 * __useDeleteShiftMutation__
 *
 * To run a mutation, you first call `useDeleteShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShiftMutation, { data, loading, error }] = useDeleteShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteShiftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShiftMutation, DeleteShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShiftMutation, DeleteShiftMutationVariables>(DeleteShiftDocument, options);
      }
export type DeleteShiftMutationHookResult = ReturnType<typeof useDeleteShiftMutation>;
export type DeleteShiftMutationResult = Apollo.MutationResult<DeleteShiftMutation>;
export type DeleteShiftMutationOptions = Apollo.BaseMutationOptions<DeleteShiftMutation, DeleteShiftMutationVariables>;
export const UpdateShiftDocument = gql`
    mutation UpdateShift($input: UpdateShiftInput!) {
  updateShift(input: $input) {
    id
  }
}
    `;
export type UpdateShiftMutationFn = Apollo.MutationFunction<UpdateShiftMutation, UpdateShiftMutationVariables>;

/**
 * __useUpdateShiftMutation__
 *
 * To run a mutation, you first call `useUpdateShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftMutation, { data, loading, error }] = useUpdateShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShiftMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShiftMutation, UpdateShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShiftMutation, UpdateShiftMutationVariables>(UpdateShiftDocument, options);
      }
export type UpdateShiftMutationHookResult = ReturnType<typeof useUpdateShiftMutation>;
export type UpdateShiftMutationResult = Apollo.MutationResult<UpdateShiftMutation>;
export type UpdateShiftMutationOptions = Apollo.BaseMutationOptions<UpdateShiftMutation, UpdateShiftMutationVariables>;
export const DeleteShiftRequestDocument = gql`
    mutation DeleteShiftRequest($input: DeleteShiftRequestInput!) {
  deleteShiftRequest(input: $input) {
    id
  }
}
    `;
export type DeleteShiftRequestMutationFn = Apollo.MutationFunction<DeleteShiftRequestMutation, DeleteShiftRequestMutationVariables>;

/**
 * __useDeleteShiftRequestMutation__
 *
 * To run a mutation, you first call `useDeleteShiftRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShiftRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShiftRequestMutation, { data, loading, error }] = useDeleteShiftRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteShiftRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShiftRequestMutation, DeleteShiftRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShiftRequestMutation, DeleteShiftRequestMutationVariables>(DeleteShiftRequestDocument, options);
      }
export type DeleteShiftRequestMutationHookResult = ReturnType<typeof useDeleteShiftRequestMutation>;
export type DeleteShiftRequestMutationResult = Apollo.MutationResult<DeleteShiftRequestMutation>;
export type DeleteShiftRequestMutationOptions = Apollo.BaseMutationOptions<DeleteShiftRequestMutation, DeleteShiftRequestMutationVariables>;
export const UpdateShiftRequestDocument = gql`
    mutation UpdateShiftRequest($input: UpdateShiftRequestInput!) {
  updateShiftRequest(input: $input) {
    id
  }
}
    `;
export type UpdateShiftRequestMutationFn = Apollo.MutationFunction<UpdateShiftRequestMutation, UpdateShiftRequestMutationVariables>;

/**
 * __useUpdateShiftRequestMutation__
 *
 * To run a mutation, you first call `useUpdateShiftRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftRequestMutation, { data, loading, error }] = useUpdateShiftRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShiftRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShiftRequestMutation, UpdateShiftRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShiftRequestMutation, UpdateShiftRequestMutationVariables>(UpdateShiftRequestDocument, options);
      }
export type UpdateShiftRequestMutationHookResult = ReturnType<typeof useUpdateShiftRequestMutation>;
export type UpdateShiftRequestMutationResult = Apollo.MutationResult<UpdateShiftRequestMutation>;
export type UpdateShiftRequestMutationOptions = Apollo.BaseMutationOptions<UpdateShiftRequestMutation, UpdateShiftRequestMutationVariables>;
export const CreateShiftRoleDocument = gql`
    mutation CreateShiftRole($input: CreateShiftRoleInput!) {
  createShiftRole(input: $input) {
    id
    ...BusinessFields
    shiftRoles(where: {isDeleted: false}) {
      ...ShiftRoleFields
    }
  }
}
    ${BusinessFieldsFragmentDoc}
${ShiftRoleFieldsFragmentDoc}`;
export type CreateShiftRoleMutationFn = Apollo.MutationFunction<CreateShiftRoleMutation, CreateShiftRoleMutationVariables>;

/**
 * __useCreateShiftRoleMutation__
 *
 * To run a mutation, you first call `useCreateShiftRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftRoleMutation, { data, loading, error }] = useCreateShiftRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShiftRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateShiftRoleMutation, CreateShiftRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShiftRoleMutation, CreateShiftRoleMutationVariables>(CreateShiftRoleDocument, options);
      }
export type CreateShiftRoleMutationHookResult = ReturnType<typeof useCreateShiftRoleMutation>;
export type CreateShiftRoleMutationResult = Apollo.MutationResult<CreateShiftRoleMutation>;
export type CreateShiftRoleMutationOptions = Apollo.BaseMutationOptions<CreateShiftRoleMutation, CreateShiftRoleMutationVariables>;
export const DeleteShiftRoleDocument = gql`
    mutation DeleteShiftRole($input: DeleteShiftRoleInput!) {
  deleteShiftRole(input: $input) {
    id
    ...BusinessFields
    shiftRoles(where: {isDeleted: false}) {
      ...ShiftRoleFields
    }
  }
}
    ${BusinessFieldsFragmentDoc}
${ShiftRoleFieldsFragmentDoc}`;
export type DeleteShiftRoleMutationFn = Apollo.MutationFunction<DeleteShiftRoleMutation, DeleteShiftRoleMutationVariables>;

/**
 * __useDeleteShiftRoleMutation__
 *
 * To run a mutation, you first call `useDeleteShiftRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShiftRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShiftRoleMutation, { data, loading, error }] = useDeleteShiftRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteShiftRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShiftRoleMutation, DeleteShiftRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShiftRoleMutation, DeleteShiftRoleMutationVariables>(DeleteShiftRoleDocument, options);
      }
export type DeleteShiftRoleMutationHookResult = ReturnType<typeof useDeleteShiftRoleMutation>;
export type DeleteShiftRoleMutationResult = Apollo.MutationResult<DeleteShiftRoleMutation>;
export type DeleteShiftRoleMutationOptions = Apollo.BaseMutationOptions<DeleteShiftRoleMutation, DeleteShiftRoleMutationVariables>;
export const RestoreShiftRoleDocument = gql`
    mutation RestoreShiftRole($input: RestoreShiftRoleInput!) {
  restoreShiftRole(input: $input) {
    id
    ...BusinessFields
    shiftRoles(where: {isDeleted: false}) {
      ...ShiftRoleFields
    }
  }
}
    ${BusinessFieldsFragmentDoc}
${ShiftRoleFieldsFragmentDoc}`;
export type RestoreShiftRoleMutationFn = Apollo.MutationFunction<RestoreShiftRoleMutation, RestoreShiftRoleMutationVariables>;

/**
 * __useRestoreShiftRoleMutation__
 *
 * To run a mutation, you first call `useRestoreShiftRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreShiftRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreShiftRoleMutation, { data, loading, error }] = useRestoreShiftRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreShiftRoleMutation(baseOptions?: Apollo.MutationHookOptions<RestoreShiftRoleMutation, RestoreShiftRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreShiftRoleMutation, RestoreShiftRoleMutationVariables>(RestoreShiftRoleDocument, options);
      }
export type RestoreShiftRoleMutationHookResult = ReturnType<typeof useRestoreShiftRoleMutation>;
export type RestoreShiftRoleMutationResult = Apollo.MutationResult<RestoreShiftRoleMutation>;
export type RestoreShiftRoleMutationOptions = Apollo.BaseMutationOptions<RestoreShiftRoleMutation, RestoreShiftRoleMutationVariables>;
export const UpdateShiftRoleDocument = gql`
    mutation UpdateShiftRole($input: UpdateShiftRoleInput!) {
  updateShiftRole(input: $input) {
    id
    ...BusinessFields
    shiftRoles(where: {isDeleted: false}) {
      ...ShiftRoleFields
    }
  }
}
    ${BusinessFieldsFragmentDoc}
${ShiftRoleFieldsFragmentDoc}`;
export type UpdateShiftRoleMutationFn = Apollo.MutationFunction<UpdateShiftRoleMutation, UpdateShiftRoleMutationVariables>;

/**
 * __useUpdateShiftRoleMutation__
 *
 * To run a mutation, you first call `useUpdateShiftRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftRoleMutation, { data, loading, error }] = useUpdateShiftRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShiftRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShiftRoleMutation, UpdateShiftRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShiftRoleMutation, UpdateShiftRoleMutationVariables>(UpdateShiftRoleDocument, options);
      }
export type UpdateShiftRoleMutationHookResult = ReturnType<typeof useUpdateShiftRoleMutation>;
export type UpdateShiftRoleMutationResult = Apollo.MutationResult<UpdateShiftRoleMutation>;
export type UpdateShiftRoleMutationOptions = Apollo.BaseMutationOptions<UpdateShiftRoleMutation, UpdateShiftRoleMutationVariables>;
export const CreateShiftScheduleDocument = gql`
    mutation CreateShiftSchedule($input: CreateShiftScheduleInput!) {
  createShiftSchedule(input: $input) {
    id
  }
}
    `;
export type CreateShiftScheduleMutationFn = Apollo.MutationFunction<CreateShiftScheduleMutation, CreateShiftScheduleMutationVariables>;

/**
 * __useCreateShiftScheduleMutation__
 *
 * To run a mutation, you first call `useCreateShiftScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftScheduleMutation, { data, loading, error }] = useCreateShiftScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShiftScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreateShiftScheduleMutation, CreateShiftScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShiftScheduleMutation, CreateShiftScheduleMutationVariables>(CreateShiftScheduleDocument, options);
      }
export type CreateShiftScheduleMutationHookResult = ReturnType<typeof useCreateShiftScheduleMutation>;
export type CreateShiftScheduleMutationResult = Apollo.MutationResult<CreateShiftScheduleMutation>;
export type CreateShiftScheduleMutationOptions = Apollo.BaseMutationOptions<CreateShiftScheduleMutation, CreateShiftScheduleMutationVariables>;
export const CreateShiftScheduleRequestDocument = gql`
    mutation CreateShiftScheduleRequest($input: CreateShiftScheduleRequestInput!) {
  createShiftScheduleRequest(input: $input) {
    id
  }
}
    `;
export type CreateShiftScheduleRequestMutationFn = Apollo.MutationFunction<CreateShiftScheduleRequestMutation, CreateShiftScheduleRequestMutationVariables>;

/**
 * __useCreateShiftScheduleRequestMutation__
 *
 * To run a mutation, you first call `useCreateShiftScheduleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftScheduleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftScheduleRequestMutation, { data, loading, error }] = useCreateShiftScheduleRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShiftScheduleRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateShiftScheduleRequestMutation, CreateShiftScheduleRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShiftScheduleRequestMutation, CreateShiftScheduleRequestMutationVariables>(CreateShiftScheduleRequestDocument, options);
      }
export type CreateShiftScheduleRequestMutationHookResult = ReturnType<typeof useCreateShiftScheduleRequestMutation>;
export type CreateShiftScheduleRequestMutationResult = Apollo.MutationResult<CreateShiftScheduleRequestMutation>;
export type CreateShiftScheduleRequestMutationOptions = Apollo.BaseMutationOptions<CreateShiftScheduleRequestMutation, CreateShiftScheduleRequestMutationVariables>;
export const TableConfigsDocument = gql`
    query TableConfigs {
  tableConfigs {
    offset
    since
    until
    weekText
  }
}
    `;

/**
 * __useTableConfigsQuery__
 *
 * To run a query within a React component, call `useTableConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTableConfigsQuery(baseOptions?: Apollo.QueryHookOptions<TableConfigsQuery, TableConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableConfigsQuery, TableConfigsQueryVariables>(TableConfigsDocument, options);
      }
export function useTableConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableConfigsQuery, TableConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableConfigsQuery, TableConfigsQueryVariables>(TableConfigsDocument, options);
        }
export function useTableConfigsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TableConfigsQuery, TableConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TableConfigsQuery, TableConfigsQueryVariables>(TableConfigsDocument, options);
        }
export type TableConfigsQueryHookResult = ReturnType<typeof useTableConfigsQuery>;
export type TableConfigsLazyQueryHookResult = ReturnType<typeof useTableConfigsLazyQuery>;
export type TableConfigsSuspenseQueryHookResult = ReturnType<typeof useTableConfigsSuspenseQuery>;
export type TableConfigsQueryResult = Apollo.QueryResult<TableConfigsQuery, TableConfigsQueryVariables>;
export const TableDateTextDocument = gql`
    query TableDateText($businessLogin: String!, $since: String!) {
  tableDateText(businessLogin: $businessLogin, since: $since) {
    dateTexts
    uiDateTexts
    dateTextsWithAroundDay
    uiDateTextsWithAroundDay
  }
}
    `;

/**
 * __useTableDateTextQuery__
 *
 * To run a query within a React component, call `useTableDateTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableDateTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableDateTextQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *   },
 * });
 */
export function useTableDateTextQuery(baseOptions: Apollo.QueryHookOptions<TableDateTextQuery, TableDateTextQueryVariables> & ({ variables: TableDateTextQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableDateTextQuery, TableDateTextQueryVariables>(TableDateTextDocument, options);
      }
export function useTableDateTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableDateTextQuery, TableDateTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableDateTextQuery, TableDateTextQueryVariables>(TableDateTextDocument, options);
        }
export function useTableDateTextSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TableDateTextQuery, TableDateTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TableDateTextQuery, TableDateTextQueryVariables>(TableDateTextDocument, options);
        }
export type TableDateTextQueryHookResult = ReturnType<typeof useTableDateTextQuery>;
export type TableDateTextLazyQueryHookResult = ReturnType<typeof useTableDateTextLazyQuery>;
export type TableDateTextSuspenseQueryHookResult = ReturnType<typeof useTableDateTextSuspenseQuery>;
export type TableDateTextQueryResult = Apollo.QueryResult<TableDateTextQuery, TableDateTextQueryVariables>;
export const TableDatesDocument = gql`
    query TableDates($businessLogin: String!, $since: String!) {
  tableDates(businessLogin: $businessLogin, since: $since) {
    text
    uiText
    tableText
    year
    month
    day
    timezone
  }
}
    `;

/**
 * __useTableDatesQuery__
 *
 * To run a query within a React component, call `useTableDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableDatesQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *   },
 * });
 */
export function useTableDatesQuery(baseOptions: Apollo.QueryHookOptions<TableDatesQuery, TableDatesQueryVariables> & ({ variables: TableDatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableDatesQuery, TableDatesQueryVariables>(TableDatesDocument, options);
      }
export function useTableDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableDatesQuery, TableDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableDatesQuery, TableDatesQueryVariables>(TableDatesDocument, options);
        }
export function useTableDatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TableDatesQuery, TableDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TableDatesQuery, TableDatesQueryVariables>(TableDatesDocument, options);
        }
export type TableDatesQueryHookResult = ReturnType<typeof useTableDatesQuery>;
export type TableDatesLazyQueryHookResult = ReturnType<typeof useTableDatesLazyQuery>;
export type TableDatesSuspenseQueryHookResult = ReturnType<typeof useTableDatesSuspenseQuery>;
export type TableDatesQueryResult = Apollo.QueryResult<TableDatesQuery, TableDatesQueryVariables>;
export const TableWeeksDocument = gql`
    query TableWeeks {
  tableWeeks {
    offset
    since
    until
    weekText
  }
}
    `;

/**
 * __useTableWeeksQuery__
 *
 * To run a query within a React component, call `useTableWeeksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableWeeksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableWeeksQuery({
 *   variables: {
 *   },
 * });
 */
export function useTableWeeksQuery(baseOptions?: Apollo.QueryHookOptions<TableWeeksQuery, TableWeeksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableWeeksQuery, TableWeeksQueryVariables>(TableWeeksDocument, options);
      }
export function useTableWeeksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableWeeksQuery, TableWeeksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableWeeksQuery, TableWeeksQueryVariables>(TableWeeksDocument, options);
        }
export function useTableWeeksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TableWeeksQuery, TableWeeksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TableWeeksQuery, TableWeeksQueryVariables>(TableWeeksDocument, options);
        }
export type TableWeeksQueryHookResult = ReturnType<typeof useTableWeeksQuery>;
export type TableWeeksLazyQueryHookResult = ReturnType<typeof useTableWeeksLazyQuery>;
export type TableWeeksSuspenseQueryHookResult = ReturnType<typeof useTableWeeksSuspenseQuery>;
export type TableWeeksQueryResult = Apollo.QueryResult<TableWeeksQuery, TableWeeksQueryVariables>;
export const ClockInDocument = gql`
    mutation ClockIn($input: ClockInInput!) {
  clockIn(input: $input)
}
    `;
export type ClockInMutationFn = Apollo.MutationFunction<ClockInMutation, ClockInMutationVariables>;

/**
 * __useClockInMutation__
 *
 * To run a mutation, you first call `useClockInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockInMutation, { data, loading, error }] = useClockInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClockInMutation(baseOptions?: Apollo.MutationHookOptions<ClockInMutation, ClockInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClockInMutation, ClockInMutationVariables>(ClockInDocument, options);
      }
export type ClockInMutationHookResult = ReturnType<typeof useClockInMutation>;
export type ClockInMutationResult = Apollo.MutationResult<ClockInMutation>;
export type ClockInMutationOptions = Apollo.BaseMutationOptions<ClockInMutation, ClockInMutationVariables>;
export const CreateTimeRecordDocument = gql`
    mutation CreateTimeRecord($input: CreateTimeRecordInput!) {
  createTimeRecord(input: $input) {
    id
  }
}
    `;
export type CreateTimeRecordMutationFn = Apollo.MutationFunction<CreateTimeRecordMutation, CreateTimeRecordMutationVariables>;

/**
 * __useCreateTimeRecordMutation__
 *
 * To run a mutation, you first call `useCreateTimeRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeRecordMutation, { data, loading, error }] = useCreateTimeRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeRecordMutation, CreateTimeRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeRecordMutation, CreateTimeRecordMutationVariables>(CreateTimeRecordDocument, options);
      }
export type CreateTimeRecordMutationHookResult = ReturnType<typeof useCreateTimeRecordMutation>;
export type CreateTimeRecordMutationResult = Apollo.MutationResult<CreateTimeRecordMutation>;
export type CreateTimeRecordMutationOptions = Apollo.BaseMutationOptions<CreateTimeRecordMutation, CreateTimeRecordMutationVariables>;
export const DeleteTimeRecordDocument = gql`
    mutation DeleteTimeRecord($input: DeleteTimeRecordInput!) {
  deleteTimeRecord(input: $input) {
    id
  }
}
    `;
export type DeleteTimeRecordMutationFn = Apollo.MutationFunction<DeleteTimeRecordMutation, DeleteTimeRecordMutationVariables>;

/**
 * __useDeleteTimeRecordMutation__
 *
 * To run a mutation, you first call `useDeleteTimeRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeRecordMutation, { data, loading, error }] = useDeleteTimeRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTimeRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeRecordMutation, DeleteTimeRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeRecordMutation, DeleteTimeRecordMutationVariables>(DeleteTimeRecordDocument, options);
      }
export type DeleteTimeRecordMutationHookResult = ReturnType<typeof useDeleteTimeRecordMutation>;
export type DeleteTimeRecordMutationResult = Apollo.MutationResult<DeleteTimeRecordMutation>;
export type DeleteTimeRecordMutationOptions = Apollo.BaseMutationOptions<DeleteTimeRecordMutation, DeleteTimeRecordMutationVariables>;
export const UpdateTimeRecordDocument = gql`
    mutation UpdateTimeRecord($input: UpdateTimeRecordInput!) {
  updateTimeRecord(input: $input) {
    id
  }
}
    `;
export type UpdateTimeRecordMutationFn = Apollo.MutationFunction<UpdateTimeRecordMutation, UpdateTimeRecordMutationVariables>;

/**
 * __useUpdateTimeRecordMutation__
 *
 * To run a mutation, you first call `useUpdateTimeRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeRecordMutation, { data, loading, error }] = useUpdateTimeRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeRecordMutation, UpdateTimeRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimeRecordMutation, UpdateTimeRecordMutationVariables>(UpdateTimeRecordDocument, options);
      }
export type UpdateTimeRecordMutationHookResult = ReturnType<typeof useUpdateTimeRecordMutation>;
export type UpdateTimeRecordMutationResult = Apollo.MutationResult<UpdateTimeRecordMutation>;
export type UpdateTimeRecordMutationOptions = Apollo.BaseMutationOptions<UpdateTimeRecordMutation, UpdateTimeRecordMutationVariables>;
export const CreateTimeSlotDocument = gql`
    mutation CreateTimeSlot($input: CreateTimeSlotInput!) {
  createTimeSlot(input: $input) {
    id
  }
}
    `;
export type CreateTimeSlotMutationFn = Apollo.MutationFunction<CreateTimeSlotMutation, CreateTimeSlotMutationVariables>;

/**
 * __useCreateTimeSlotMutation__
 *
 * To run a mutation, you first call `useCreateTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeSlotMutation, { data, loading, error }] = useCreateTimeSlotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeSlotMutation, CreateTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeSlotMutation, CreateTimeSlotMutationVariables>(CreateTimeSlotDocument, options);
      }
export type CreateTimeSlotMutationHookResult = ReturnType<typeof useCreateTimeSlotMutation>;
export type CreateTimeSlotMutationResult = Apollo.MutationResult<CreateTimeSlotMutation>;
export type CreateTimeSlotMutationOptions = Apollo.BaseMutationOptions<CreateTimeSlotMutation, CreateTimeSlotMutationVariables>;
export const DeleteTimeSlotDocument = gql`
    mutation DeleteTimeSlot($input: DeleteTimeSlotInput!) {
  deleteTimeSlot(input: $input) {
    id
  }
}
    `;
export type DeleteTimeSlotMutationFn = Apollo.MutationFunction<DeleteTimeSlotMutation, DeleteTimeSlotMutationVariables>;

/**
 * __useDeleteTimeSlotMutation__
 *
 * To run a mutation, you first call `useDeleteTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeSlotMutation, { data, loading, error }] = useDeleteTimeSlotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeSlotMutation, DeleteTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeSlotMutation, DeleteTimeSlotMutationVariables>(DeleteTimeSlotDocument, options);
      }
export type DeleteTimeSlotMutationHookResult = ReturnType<typeof useDeleteTimeSlotMutation>;
export type DeleteTimeSlotMutationResult = Apollo.MutationResult<DeleteTimeSlotMutation>;
export type DeleteTimeSlotMutationOptions = Apollo.BaseMutationOptions<DeleteTimeSlotMutation, DeleteTimeSlotMutationVariables>;
export const RestoreTimeSlotDocument = gql`
    mutation RestoreTimeSlot($input: RestoreTimeSlotInput!) {
  restoreTimeSlot(input: $input) {
    id
  }
}
    `;
export type RestoreTimeSlotMutationFn = Apollo.MutationFunction<RestoreTimeSlotMutation, RestoreTimeSlotMutationVariables>;

/**
 * __useRestoreTimeSlotMutation__
 *
 * To run a mutation, you first call `useRestoreTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreTimeSlotMutation, { data, loading, error }] = useRestoreTimeSlotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<RestoreTimeSlotMutation, RestoreTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreTimeSlotMutation, RestoreTimeSlotMutationVariables>(RestoreTimeSlotDocument, options);
      }
export type RestoreTimeSlotMutationHookResult = ReturnType<typeof useRestoreTimeSlotMutation>;
export type RestoreTimeSlotMutationResult = Apollo.MutationResult<RestoreTimeSlotMutation>;
export type RestoreTimeSlotMutationOptions = Apollo.BaseMutationOptions<RestoreTimeSlotMutation, RestoreTimeSlotMutationVariables>;
export const UpdateTimeSlotDocument = gql`
    mutation UpdateTimeSlot($input: UpdateTimeSlotInput!) {
  updateTimeSlot(input: $input) {
    id
  }
}
    `;
export type UpdateTimeSlotMutationFn = Apollo.MutationFunction<UpdateTimeSlotMutation, UpdateTimeSlotMutationVariables>;

/**
 * __useUpdateTimeSlotMutation__
 *
 * To run a mutation, you first call `useUpdateTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeSlotMutation, { data, loading, error }] = useUpdateTimeSlotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeSlotMutation, UpdateTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimeSlotMutation, UpdateTimeSlotMutationVariables>(UpdateTimeSlotDocument, options);
      }
export type UpdateTimeSlotMutationHookResult = ReturnType<typeof useUpdateTimeSlotMutation>;
export type UpdateTimeSlotMutationResult = Apollo.MutationResult<UpdateTimeSlotMutation>;
export type UpdateTimeSlotMutationOptions = Apollo.BaseMutationOptions<UpdateTimeSlotMutation, UpdateTimeSlotMutationVariables>;
export const CreateUserWithEmailDocument = gql`
    mutation CreateUserWithEmail($input: CreateUserWithEmailInput!) {
  createUserWithEmail(input: $input) {
    id
  }
}
    `;
export type CreateUserWithEmailMutationFn = Apollo.MutationFunction<CreateUserWithEmailMutation, CreateUserWithEmailMutationVariables>;

/**
 * __useCreateUserWithEmailMutation__
 *
 * To run a mutation, you first call `useCreateUserWithEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWithEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWithEmailMutation, { data, loading, error }] = useCreateUserWithEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserWithEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserWithEmailMutation, CreateUserWithEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserWithEmailMutation, CreateUserWithEmailMutationVariables>(CreateUserWithEmailDocument, options);
      }
export type CreateUserWithEmailMutationHookResult = ReturnType<typeof useCreateUserWithEmailMutation>;
export type CreateUserWithEmailMutationResult = Apollo.MutationResult<CreateUserWithEmailMutation>;
export type CreateUserWithEmailMutationOptions = Apollo.BaseMutationOptions<CreateUserWithEmailMutation, CreateUserWithEmailMutationVariables>;
export const CreateUserWithGoogleDocument = gql`
    mutation CreateUserWithGoogle($input: CreateUserWithGoogleInput!) {
  createUserWithGoogle(input: $input) {
    id
  }
}
    `;
export type CreateUserWithGoogleMutationFn = Apollo.MutationFunction<CreateUserWithGoogleMutation, CreateUserWithGoogleMutationVariables>;

/**
 * __useCreateUserWithGoogleMutation__
 *
 * To run a mutation, you first call `useCreateUserWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWithGoogleMutation, { data, loading, error }] = useCreateUserWithGoogleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserWithGoogleMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserWithGoogleMutation, CreateUserWithGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserWithGoogleMutation, CreateUserWithGoogleMutationVariables>(CreateUserWithGoogleDocument, options);
      }
export type CreateUserWithGoogleMutationHookResult = ReturnType<typeof useCreateUserWithGoogleMutation>;
export type CreateUserWithGoogleMutationResult = Apollo.MutationResult<CreateUserWithGoogleMutation>;
export type CreateUserWithGoogleMutationOptions = Apollo.BaseMutationOptions<CreateUserWithGoogleMutation, CreateUserWithGoogleMutationVariables>;
export const UserExistsDocument = gql`
    query userExists($email: String!) {
  userExists(email: $email) {
    email
  }
}
    `;

/**
 * __useUserExistsQuery__
 *
 * To run a query within a React component, call `useUserExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExistsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserExistsQuery(baseOptions: Apollo.QueryHookOptions<UserExistsQuery, UserExistsQueryVariables> & ({ variables: UserExistsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserExistsQuery, UserExistsQueryVariables>(UserExistsDocument, options);
      }
export function useUserExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserExistsQuery, UserExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserExistsQuery, UserExistsQueryVariables>(UserExistsDocument, options);
        }
export function useUserExistsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserExistsQuery, UserExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserExistsQuery, UserExistsQueryVariables>(UserExistsDocument, options);
        }
export type UserExistsQueryHookResult = ReturnType<typeof useUserExistsQuery>;
export type UserExistsLazyQueryHookResult = ReturnType<typeof useUserExistsLazyQuery>;
export type UserExistsSuspenseQueryHookResult = ReturnType<typeof useUserExistsSuspenseQuery>;
export type UserExistsQueryResult = Apollo.QueryResult<UserExistsQuery, UserExistsQueryVariables>;
export const LoginWithTokenDocument = gql`
    mutation LoginWithToken($input: LoginWithTokenInput!) {
  loginWithToken(input: $input) {
    id
  }
}
    `;
export type LoginWithTokenMutationFn = Apollo.MutationFunction<LoginWithTokenMutation, LoginWithTokenMutationVariables>;

/**
 * __useLoginWithTokenMutation__
 *
 * To run a mutation, you first call `useLoginWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithTokenMutation, { data, loading, error }] = useLoginWithTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithTokenMutation, LoginWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithTokenMutation, LoginWithTokenMutationVariables>(LoginWithTokenDocument, options);
      }
export type LoginWithTokenMutationHookResult = ReturnType<typeof useLoginWithTokenMutation>;
export type LoginWithTokenMutationResult = Apollo.MutationResult<LoginWithTokenMutation>;
export type LoginWithTokenMutationOptions = Apollo.BaseMutationOptions<LoginWithTokenMutation, LoginWithTokenMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const UpdateViewerMemberDocument = gql`
    mutation UpdateViewerMember($input: UpdateViewerMemberInput!) {
  updateViewerMember(input: $input) {
    id
  }
}
    `;
export type UpdateViewerMemberMutationFn = Apollo.MutationFunction<UpdateViewerMemberMutation, UpdateViewerMemberMutationVariables>;

/**
 * __useUpdateViewerMemberMutation__
 *
 * To run a mutation, you first call `useUpdateViewerMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewerMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewerMemberMutation, { data, loading, error }] = useUpdateViewerMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateViewerMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewerMemberMutation, UpdateViewerMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateViewerMemberMutation, UpdateViewerMemberMutationVariables>(UpdateViewerMemberDocument, options);
      }
export type UpdateViewerMemberMutationHookResult = ReturnType<typeof useUpdateViewerMemberMutation>;
export type UpdateViewerMemberMutationResult = Apollo.MutationResult<UpdateViewerMemberMutation>;
export type UpdateViewerMemberMutationOptions = Apollo.BaseMutationOptions<UpdateViewerMemberMutation, UpdateViewerMemberMutationVariables>;
export const UpdateViewerUserDocument = gql`
    mutation UpdateViewerUser($input: UpdateViewerUserInput!) {
  updateViewerUser(input: $input) {
    id
  }
}
    `;
export type UpdateViewerUserMutationFn = Apollo.MutationFunction<UpdateViewerUserMutation, UpdateViewerUserMutationVariables>;

/**
 * __useUpdateViewerUserMutation__
 *
 * To run a mutation, you first call `useUpdateViewerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewerUserMutation, { data, loading, error }] = useUpdateViewerUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateViewerUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewerUserMutation, UpdateViewerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateViewerUserMutation, UpdateViewerUserMutationVariables>(UpdateViewerUserDocument, options);
      }
export type UpdateViewerUserMutationHookResult = ReturnType<typeof useUpdateViewerUserMutation>;
export type UpdateViewerUserMutationResult = Apollo.MutationResult<UpdateViewerUserMutation>;
export type UpdateViewerUserMutationOptions = Apollo.BaseMutationOptions<UpdateViewerUserMutation, UpdateViewerUserMutationVariables>;
export const UpdateViewerUserEmailDocument = gql`
    mutation UpdateViewerUserEmail($input: UpdateViewerUserEmailInput!) {
  updateViewerUserEmail(input: $input) {
    id
  }
}
    `;
export type UpdateViewerUserEmailMutationFn = Apollo.MutationFunction<UpdateViewerUserEmailMutation, UpdateViewerUserEmailMutationVariables>;

/**
 * __useUpdateViewerUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateViewerUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewerUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewerUserEmailMutation, { data, loading, error }] = useUpdateViewerUserEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateViewerUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewerUserEmailMutation, UpdateViewerUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateViewerUserEmailMutation, UpdateViewerUserEmailMutationVariables>(UpdateViewerUserEmailDocument, options);
      }
export type UpdateViewerUserEmailMutationHookResult = ReturnType<typeof useUpdateViewerUserEmailMutation>;
export type UpdateViewerUserEmailMutationResult = Apollo.MutationResult<UpdateViewerUserEmailMutation>;
export type UpdateViewerUserEmailMutationOptions = Apollo.BaseMutationOptions<UpdateViewerUserEmailMutation, UpdateViewerUserEmailMutationVariables>;
export const UpdateViewerUserPasswordDocument = gql`
    mutation UpdateViewerUserPassword($input: UpdateViewerUserPasswordInput!) {
  updateViewerUserPassword(input: $input) {
    id
  }
}
    `;
export type UpdateViewerUserPasswordMutationFn = Apollo.MutationFunction<UpdateViewerUserPasswordMutation, UpdateViewerUserPasswordMutationVariables>;

/**
 * __useUpdateViewerUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateViewerUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewerUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewerUserPasswordMutation, { data, loading, error }] = useUpdateViewerUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateViewerUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewerUserPasswordMutation, UpdateViewerUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateViewerUserPasswordMutation, UpdateViewerUserPasswordMutationVariables>(UpdateViewerUserPasswordDocument, options);
      }
export type UpdateViewerUserPasswordMutationHookResult = ReturnType<typeof useUpdateViewerUserPasswordMutation>;
export type UpdateViewerUserPasswordMutationResult = Apollo.MutationResult<UpdateViewerUserPasswordMutation>;
export type UpdateViewerUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateViewerUserPasswordMutation, UpdateViewerUserPasswordMutationVariables>;
export const ViewerBusinessAttendancesDocument = gql`
    query ViewerBusinessAttendances($businessLogin: String!, $since: String!, $until: String!) {
  viewer {
    attendanceSummary(businessLogin: $businessLogin, since: $since, until: $until) {
      id
      totalWorkingTime
      totalWorkingTimePerMember
      totalWorkingTimePerDay
      totalWorkingTimeText
      totalWorkingTimePerMemberText
      totalWorkingTimePerDayText
      attendances {
        ...AttendanceFields
      }
    }
  }
}
    ${AttendanceFieldsFragmentDoc}`;

/**
 * __useViewerBusinessAttendancesQuery__
 *
 * To run a query within a React component, call `useViewerBusinessAttendancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerBusinessAttendancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerBusinessAttendancesQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useViewerBusinessAttendancesQuery(baseOptions: Apollo.QueryHookOptions<ViewerBusinessAttendancesQuery, ViewerBusinessAttendancesQueryVariables> & ({ variables: ViewerBusinessAttendancesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerBusinessAttendancesQuery, ViewerBusinessAttendancesQueryVariables>(ViewerBusinessAttendancesDocument, options);
      }
export function useViewerBusinessAttendancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerBusinessAttendancesQuery, ViewerBusinessAttendancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerBusinessAttendancesQuery, ViewerBusinessAttendancesQueryVariables>(ViewerBusinessAttendancesDocument, options);
        }
export function useViewerBusinessAttendancesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerBusinessAttendancesQuery, ViewerBusinessAttendancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewerBusinessAttendancesQuery, ViewerBusinessAttendancesQueryVariables>(ViewerBusinessAttendancesDocument, options);
        }
export type ViewerBusinessAttendancesQueryHookResult = ReturnType<typeof useViewerBusinessAttendancesQuery>;
export type ViewerBusinessAttendancesLazyQueryHookResult = ReturnType<typeof useViewerBusinessAttendancesLazyQuery>;
export type ViewerBusinessAttendancesSuspenseQueryHookResult = ReturnType<typeof useViewerBusinessAttendancesSuspenseQuery>;
export type ViewerBusinessAttendancesQueryResult = Apollo.QueryResult<ViewerBusinessAttendancesQuery, ViewerBusinessAttendancesQueryVariables>;
export const ViewerBusinessDefaultShiftsDocument = gql`
    query ViewerBusinessDefaultShifts($businessLogin: String!) {
  viewer {
    businessDefaultShifts(businessLogin: $businessLogin) {
      ...DefaultShiftFields
    }
  }
}
    ${DefaultShiftFieldsFragmentDoc}`;

/**
 * __useViewerBusinessDefaultShiftsQuery__
 *
 * To run a query within a React component, call `useViewerBusinessDefaultShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerBusinessDefaultShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerBusinessDefaultShiftsQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *   },
 * });
 */
export function useViewerBusinessDefaultShiftsQuery(baseOptions: Apollo.QueryHookOptions<ViewerBusinessDefaultShiftsQuery, ViewerBusinessDefaultShiftsQueryVariables> & ({ variables: ViewerBusinessDefaultShiftsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerBusinessDefaultShiftsQuery, ViewerBusinessDefaultShiftsQueryVariables>(ViewerBusinessDefaultShiftsDocument, options);
      }
export function useViewerBusinessDefaultShiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerBusinessDefaultShiftsQuery, ViewerBusinessDefaultShiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerBusinessDefaultShiftsQuery, ViewerBusinessDefaultShiftsQueryVariables>(ViewerBusinessDefaultShiftsDocument, options);
        }
export function useViewerBusinessDefaultShiftsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerBusinessDefaultShiftsQuery, ViewerBusinessDefaultShiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewerBusinessDefaultShiftsQuery, ViewerBusinessDefaultShiftsQueryVariables>(ViewerBusinessDefaultShiftsDocument, options);
        }
export type ViewerBusinessDefaultShiftsQueryHookResult = ReturnType<typeof useViewerBusinessDefaultShiftsQuery>;
export type ViewerBusinessDefaultShiftsLazyQueryHookResult = ReturnType<typeof useViewerBusinessDefaultShiftsLazyQuery>;
export type ViewerBusinessDefaultShiftsSuspenseQueryHookResult = ReturnType<typeof useViewerBusinessDefaultShiftsSuspenseQuery>;
export type ViewerBusinessDefaultShiftsQueryResult = Apollo.QueryResult<ViewerBusinessDefaultShiftsQuery, ViewerBusinessDefaultShiftsQueryVariables>;
export const ViewerBusinessShiftRequestsDocument = gql`
    query ViewerBusinessShiftRequests($businessLogin: String!, $since: String!, $until: String!) {
  viewer {
    businessShiftRequests(
      businessLogin: $businessLogin
      since: $since
      until: $until
    ) {
      ...ShiftRequestFields
    }
  }
}
    ${ShiftRequestFieldsFragmentDoc}`;

/**
 * __useViewerBusinessShiftRequestsQuery__
 *
 * To run a query within a React component, call `useViewerBusinessShiftRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerBusinessShiftRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerBusinessShiftRequestsQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useViewerBusinessShiftRequestsQuery(baseOptions: Apollo.QueryHookOptions<ViewerBusinessShiftRequestsQuery, ViewerBusinessShiftRequestsQueryVariables> & ({ variables: ViewerBusinessShiftRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerBusinessShiftRequestsQuery, ViewerBusinessShiftRequestsQueryVariables>(ViewerBusinessShiftRequestsDocument, options);
      }
export function useViewerBusinessShiftRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerBusinessShiftRequestsQuery, ViewerBusinessShiftRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerBusinessShiftRequestsQuery, ViewerBusinessShiftRequestsQueryVariables>(ViewerBusinessShiftRequestsDocument, options);
        }
export function useViewerBusinessShiftRequestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerBusinessShiftRequestsQuery, ViewerBusinessShiftRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewerBusinessShiftRequestsQuery, ViewerBusinessShiftRequestsQueryVariables>(ViewerBusinessShiftRequestsDocument, options);
        }
export type ViewerBusinessShiftRequestsQueryHookResult = ReturnType<typeof useViewerBusinessShiftRequestsQuery>;
export type ViewerBusinessShiftRequestsLazyQueryHookResult = ReturnType<typeof useViewerBusinessShiftRequestsLazyQuery>;
export type ViewerBusinessShiftRequestsSuspenseQueryHookResult = ReturnType<typeof useViewerBusinessShiftRequestsSuspenseQuery>;
export type ViewerBusinessShiftRequestsQueryResult = Apollo.QueryResult<ViewerBusinessShiftRequestsQuery, ViewerBusinessShiftRequestsQueryVariables>;
export const ViewerBusinessTimeRecordsDocument = gql`
    query ViewerBusinessTimeRecords($businessLogin: String!, $since: String!, $until: String!) {
  viewer {
    businessTimeRecords(businessLogin: $businessLogin, since: $since, until: $until) {
      ...TimeRecordFields
    }
  }
}
    ${TimeRecordFieldsFragmentDoc}`;

/**
 * __useViewerBusinessTimeRecordsQuery__
 *
 * To run a query within a React component, call `useViewerBusinessTimeRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerBusinessTimeRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerBusinessTimeRecordsQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useViewerBusinessTimeRecordsQuery(baseOptions: Apollo.QueryHookOptions<ViewerBusinessTimeRecordsQuery, ViewerBusinessTimeRecordsQueryVariables> & ({ variables: ViewerBusinessTimeRecordsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerBusinessTimeRecordsQuery, ViewerBusinessTimeRecordsQueryVariables>(ViewerBusinessTimeRecordsDocument, options);
      }
export function useViewerBusinessTimeRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerBusinessTimeRecordsQuery, ViewerBusinessTimeRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerBusinessTimeRecordsQuery, ViewerBusinessTimeRecordsQueryVariables>(ViewerBusinessTimeRecordsDocument, options);
        }
export function useViewerBusinessTimeRecordsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerBusinessTimeRecordsQuery, ViewerBusinessTimeRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewerBusinessTimeRecordsQuery, ViewerBusinessTimeRecordsQueryVariables>(ViewerBusinessTimeRecordsDocument, options);
        }
export type ViewerBusinessTimeRecordsQueryHookResult = ReturnType<typeof useViewerBusinessTimeRecordsQuery>;
export type ViewerBusinessTimeRecordsLazyQueryHookResult = ReturnType<typeof useViewerBusinessTimeRecordsLazyQuery>;
export type ViewerBusinessTimeRecordsSuspenseQueryHookResult = ReturnType<typeof useViewerBusinessTimeRecordsSuspenseQuery>;
export type ViewerBusinessTimeRecordsQueryResult = Apollo.QueryResult<ViewerBusinessTimeRecordsQuery, ViewerBusinessTimeRecordsQueryVariables>;
export const ViewerBusinessesDocument = gql`
    query ViewerBusinesses($withDetails: Boolean = false) {
  viewer {
    businesses @skip(if: $withDetails) {
      id
      login
      name
      companyName
    }
    businesses @include(if: $withDetails) {
      ...BusinessFields
    }
    inviteLinkRequests {
      id
      business {
        id
        name
        companyName
        login
      }
    }
  }
}
    ${BusinessFieldsFragmentDoc}`;

/**
 * __useViewerBusinessesQuery__
 *
 * To run a query within a React component, call `useViewerBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerBusinessesQuery({
 *   variables: {
 *      withDetails: // value for 'withDetails'
 *   },
 * });
 */
export function useViewerBusinessesQuery(baseOptions?: Apollo.QueryHookOptions<ViewerBusinessesQuery, ViewerBusinessesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerBusinessesQuery, ViewerBusinessesQueryVariables>(ViewerBusinessesDocument, options);
      }
export function useViewerBusinessesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerBusinessesQuery, ViewerBusinessesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerBusinessesQuery, ViewerBusinessesQueryVariables>(ViewerBusinessesDocument, options);
        }
export function useViewerBusinessesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerBusinessesQuery, ViewerBusinessesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewerBusinessesQuery, ViewerBusinessesQueryVariables>(ViewerBusinessesDocument, options);
        }
export type ViewerBusinessesQueryHookResult = ReturnType<typeof useViewerBusinessesQuery>;
export type ViewerBusinessesLazyQueryHookResult = ReturnType<typeof useViewerBusinessesLazyQuery>;
export type ViewerBusinessesSuspenseQueryHookResult = ReturnType<typeof useViewerBusinessesSuspenseQuery>;
export type ViewerBusinessesQueryResult = Apollo.QueryResult<ViewerBusinessesQuery, ViewerBusinessesQueryVariables>;
export const ViewerMemberDocument = gql`
    query ViewerMember($businessLogin: String!) {
  viewer {
    member(businessLogin: $businessLogin) {
      ...MemberFields
      timeRecords {
        ...TimeRecordFields
      }
      shiftRoles {
        ...ShiftRoleFields
      }
      user {
        id
      }
    }
  }
}
    ${MemberFieldsFragmentDoc}
${TimeRecordFieldsFragmentDoc}
${ShiftRoleFieldsFragmentDoc}`;

/**
 * __useViewerMemberQuery__
 *
 * To run a query within a React component, call `useViewerMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerMemberQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *   },
 * });
 */
export function useViewerMemberQuery(baseOptions: Apollo.QueryHookOptions<ViewerMemberQuery, ViewerMemberQueryVariables> & ({ variables: ViewerMemberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerMemberQuery, ViewerMemberQueryVariables>(ViewerMemberDocument, options);
      }
export function useViewerMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerMemberQuery, ViewerMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerMemberQuery, ViewerMemberQueryVariables>(ViewerMemberDocument, options);
        }
export function useViewerMemberSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerMemberQuery, ViewerMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewerMemberQuery, ViewerMemberQueryVariables>(ViewerMemberDocument, options);
        }
export type ViewerMemberQueryHookResult = ReturnType<typeof useViewerMemberQuery>;
export type ViewerMemberLazyQueryHookResult = ReturnType<typeof useViewerMemberLazyQuery>;
export type ViewerMemberSuspenseQueryHookResult = ReturnType<typeof useViewerMemberSuspenseQuery>;
export type ViewerMemberQueryResult = Apollo.QueryResult<ViewerMemberQuery, ViewerMemberQueryVariables>;
export const ViewerShiftScheduleRequestDocument = gql`
    query ViewerShiftScheduleRequest($businessLogin: String!, $since: String!, $until: String!) {
  viewer {
    businessShiftScheduleRequest(
      businessLogin: $businessLogin
      since: $since
      until: $until
    ) {
      ...ShiftScheduleRequestFields
    }
  }
}
    ${ShiftScheduleRequestFieldsFragmentDoc}`;

/**
 * __useViewerShiftScheduleRequestQuery__
 *
 * To run a query within a React component, call `useViewerShiftScheduleRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerShiftScheduleRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerShiftScheduleRequestQuery({
 *   variables: {
 *      businessLogin: // value for 'businessLogin'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useViewerShiftScheduleRequestQuery(baseOptions: Apollo.QueryHookOptions<ViewerShiftScheduleRequestQuery, ViewerShiftScheduleRequestQueryVariables> & ({ variables: ViewerShiftScheduleRequestQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerShiftScheduleRequestQuery, ViewerShiftScheduleRequestQueryVariables>(ViewerShiftScheduleRequestDocument, options);
      }
export function useViewerShiftScheduleRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerShiftScheduleRequestQuery, ViewerShiftScheduleRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerShiftScheduleRequestQuery, ViewerShiftScheduleRequestQueryVariables>(ViewerShiftScheduleRequestDocument, options);
        }
export function useViewerShiftScheduleRequestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerShiftScheduleRequestQuery, ViewerShiftScheduleRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewerShiftScheduleRequestQuery, ViewerShiftScheduleRequestQueryVariables>(ViewerShiftScheduleRequestDocument, options);
        }
export type ViewerShiftScheduleRequestQueryHookResult = ReturnType<typeof useViewerShiftScheduleRequestQuery>;
export type ViewerShiftScheduleRequestLazyQueryHookResult = ReturnType<typeof useViewerShiftScheduleRequestLazyQuery>;
export type ViewerShiftScheduleRequestSuspenseQueryHookResult = ReturnType<typeof useViewerShiftScheduleRequestSuspenseQuery>;
export type ViewerShiftScheduleRequestQueryResult = Apollo.QueryResult<ViewerShiftScheduleRequestQuery, ViewerShiftScheduleRequestQueryVariables>;
export const ViewerUserDocument = gql`
    query ViewerUser {
  viewer {
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useViewerUserQuery__
 *
 * To run a query within a React component, call `useViewerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerUserQuery(baseOptions?: Apollo.QueryHookOptions<ViewerUserQuery, ViewerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerUserQuery, ViewerUserQueryVariables>(ViewerUserDocument, options);
      }
export function useViewerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerUserQuery, ViewerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerUserQuery, ViewerUserQueryVariables>(ViewerUserDocument, options);
        }
export function useViewerUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerUserQuery, ViewerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewerUserQuery, ViewerUserQueryVariables>(ViewerUserDocument, options);
        }
export type ViewerUserQueryHookResult = ReturnType<typeof useViewerUserQuery>;
export type ViewerUserLazyQueryHookResult = ReturnType<typeof useViewerUserLazyQuery>;
export type ViewerUserSuspenseQueryHookResult = ReturnType<typeof useViewerUserSuspenseQuery>;
export type ViewerUserQueryResult = Apollo.QueryResult<ViewerUserQuery, ViewerUserQueryVariables>;