import { ApolloError } from "@apollo/client"
import { Button, Stack } from "@mui/material"
import Error from "next/error"
import type { FC } from "react"
import { HomeLogin } from "app/components/HomeLogin"

type Props = {
  error: unknown
  resetError(): void
}

const RootError: FC<Props> = (props) => {
  if (props.error instanceof ApolloError) {
    for (const error of props.error.graphQLErrors) {
      if (error.extensions.code === "UNAUTHORIZED") {
        return <HomeLogin resetError={props.resetError} />
      }
      if (error.extensions.code === "FORBIDDEN") {
        return <HomeLogin resetError={props.resetError} />
      }
      if (
        error.extensions.code === "BUSINESS_NOT_FOUND" ||
        error.extensions.code === "MEMBER_NOT_FOUND"
      ) {
        return (
          <Stack>
            <Error
              statusCode={404}
              title={"Oops! The page you are looking for could not be found."}
            />
            <Button variant="contained" color="primary">
              Back to Home
            </Button>
          </Stack>
        )
      }
    }
  }

  return <Error statusCode={500} title={"ERROR"} />
}

export default RootError
