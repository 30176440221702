import type { PaletteMode } from "@mui/material"
import { createTheme } from "@mui/material"

type Props = {
  paletteMode: PaletteMode
}

/**
 * ダークモードの動作確認
 * @param props
 * @returns
 */
export const createAppThemeDark = (props: Props) => {
  props

  const defaultTheme = createTheme({
    palette: {
      mode: "dark", // props.paletteMode,
    },
  })

  const isLightMode = false // props.paletteMode === "light"

  return createTheme({
    palette: {
      mode: "dark", // props.paletteMode,
      background: {
        default: isLightMode
          ? "#FAFBFB"
          : defaultTheme.palette.background.default,
      },
    },
    shape: { borderRadius: 8 },
    shadows: [
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "done",
      "done",
      "done",
      "done",
      "done",
      "done",
      "none",
      "none",
      "done",
      "done",
      "done",
      "done",
      "done",
      "done",
    ],
    components: {
      /**
       * https://m3.material.io/components/cards/overview
       */
      MuiPaper: {
        styleOverrides: {
          // Filledの代替
          outlined: {
            borderWidth: 0,
          },
          elevation: 1,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 6,
            fontWeight: 600,
            textTransform: "none",
          },
          contained: {
            boxShadow: "none",
          },
          containedSizeSmall: {
            // デフォルトだとテキストが中心にならない
            lineHeight: 1,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "2.2rem",
          },
        },
      },
      /**
       * https://m3.material.io/components/navigation-drawer/overview
       */
      MuiDrawer: {
        styleOverrides: {
          paper: {
            borderWidth: 0,
            background: isLightMode ? "#FAFBFB" : undefined,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: isLightMode ? "white" : "transparent",
          },
        },
      },
    },
  })
}
