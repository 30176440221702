import { LoadingButton } from "@mui/lab"
import { Button, Link, Stack, TextField, Typography } from "@mui/material"
import { memo, useState } from "react"
import { z } from "zod"
import type { OnLoginWithEmail } from "interface/types/onLoginWithEmail"

const zForm = z.object({
  email: z.string(),
  password: z.string(),
})

type Props = {
  isLoading: boolean
  onLogin: OnLoginWithEmail
}

export const HomeLoginForm = memo<Props>((props) => {
  const [email, setEmail] = useState("")

  const [password, setPassword] = useState("")

  const onSubmit = () => {
    console.log("form")
    const form = zForm.parse({ email, password })
    props.onLogin(form)
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        onSubmit()
      }}
    >
      <Stack spacing={2}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <TextField
              size={"small"}
              label={"Email"}
              placeholder={"Email"}
              autoFocus
              autoComplete={"email"}
              disabled={props.isLoading}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value)
              }}
            />
            <TextField
              type="password"
              label={"Password"}
              size={"small"}
              placeholder={"Password"}
              autoComplete="password"
              disabled={props.isLoading}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value)
              }}
            />
          </Stack>
          <LoadingButton
            type={"submit"}
            variant="contained"
            loading={props.isLoading}
          >
            {"Sign in"}
          </LoadingButton>
        </Stack>
        <Stack alignItems="flex-start">
          <Link href={"/reset"}>
            <Button type="button" variant="text" color="primary">
              Forgot your password?
            </Button>
          </Link>
          <Typography variant="body2" ml={1}>
            {`Don't have an account?`}
            <Link href={"/register"} ml={1} underline="none">
              Sign Up
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </form>
  )
})
